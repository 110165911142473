import { CONFIG } from './config'

export const WORDS = [
  'تمبلن',
  'مرمڤا',
  'تاجين',
  'منامو',
  'چنورم',
  'معبود',
  'سيوات',
  'ڤارسڽ',
  'براݢن',
  'کليسا',
  'ݢالا٢',
  'تربوس',
  'ݢراون',
  'کيچيڠ',
  'کاڤل٢',
  'کرڤاي',
  'کليدڠ',
  'برسلڠ',
  'ليتنن',
  'ججواق',
  'کرتوڤ',
  'داوڤه',
  'دمامه',
  'مراوڠ',
  'روڤوڠ',
  'برسني',
  'ديويق',
  'جمرلڠ',
  'سمڤير',
  'بنيان',
  'ڤرچول',
  'مڠدوت',
  'ترلڤڤ',
  'شيليک',
  'کاندي',
  'ريتما',
  'دݢريل',
  'ترڤدس',
  'ݢيسيڠ',
  'کراکل',
  'موندو',
  'توڠکه',
  'چڤواس',
  'عقتاب',
  'رادين',
  'ڤرتام',
  'ڤمڤوق',
  'کرکهن',
  'رمڤاي',
  'ݢنجرن',
  'مڠالس',
  'ذيکير',
  'چيبوق',
  'هفتون',
  'ترسدق',
  'ڤناهڤ',
  'دڤينڠ',
  'دودور',
  'بابيل',
  'ݢندول',
  'سوبور',
  'سوليڤ',
  'دبليت',
  'جياوڠ',
  'کمڤرس',
  'بهالن',
  'چڠکيه',
  'ايليه',
  'فکولا',
  'خليقه',
  'براکو',
  'رودوق',
  'برڤتا',
  'بتوڠڽ',
  'ملعنة',
  'دتڠکڤ',
  'لايرن',
  'ڤاميت',
  'ميڠوق',
  'ظاليم',
  'برتݢق',
  'مندسه',
  'ملاسق',
  'دڤڤات',
  'کليسي',
  'مڠوچق',
  'دراڤه',
  'دادوڠ',
  'جوجوق',
  'دسوݢي',
  'ڤراوت',
  'تمبول',
  'بداري',
  'مڠالي',
  'ݢابوڠ',
  'رامسن',
  'تعليق',
  'ݢنديق',
  'ويڠيت',
  'کتوها',
  'سلوکا',
  'انچوق',
  'بوتير',
  'کلابو',
  'ݢرياق',
  'تاکوڠ',
  'مڠيوي',
  'اطباق',
  'بوالن',
  'رڠکيت',
  'دلايڠ',
  'لوڤوڠ',
  'کݢنڠن',
  'راناي',
  'انوتن',
  'ڤيسيت',
  'دتڤتي',
  'دترنق',
  'دبواه',
  'ليچون',
  'تيݢيل',
  'تريݢو',
  'ݢرليڤ',
  'ڤليبس',
  'ݢميرڠ',
  'ڤمرسن',
  'اٴوتا',
  'سيڤيڠ',
  'سکڤيت',
  'امڤوڠ',
  'کأمڤت',
  'ڠيريڠ',
  'تراره',
  'دݢرمڽ',
  'مراڤي',
  'مڠݢمق',
  'ترلمس',
  'سنادا',
  'توموڠ',
  'چوکور',
  'بوبوت',
  'ڤرسݢي',
  'مڽکوت',
  'کاجاي',
  'دبنته',
  'مڠݢتر',
  'دتکور',
  'ترماس',
  'دأوڤر',
  'کوڤور',
  'نݢريڽ',
  'ميتان',
  'ڤڠتوس',
  'جواله',
  'توتول',
  'کسسلن',
  'برݢيل',
  'امرنڽ',
  'ڤڠلسن',
  'لاݢقڽ',
  'دراقم',
  'کلوار',
  'چڤومس',
  'درايو',
  'دساله',
  'ڠوموڠ',
  'ݢاموه',
  'ڤوليه',
  'جراهق',
  'دکيڤق',
  'سچڤتڽ',
  'دتيڤو',
  'اڠلوڠ',
  'دايمن',
  'منونس',
  'لاروڠ',
  'ساٴيت',
  'بلوکر',
  'ستاهڤ',
  'جاڠن٢',
  'مالس٢',
  'سنتوڠ',
  'ڤداتي',
  'دݢتير',
  'ڤيچون',
  'ملاڠه',
  'مرڠݢه',
  'ڤنمڤڠ',
  'ڤماهت',
  'کتنتن',
  'تيڠکڤ',
  'ݢريتق',
  'دبلاي',
  'سݢوني',
  'سکاچق',
  'هيبهن',
  'کڤدرن',
  'کويوق',
  'اخيار',
  'فيمور',
  'دچيوم',
  'دمبين',
  'دراما',
  'دأتور',
  'دهنتق',
  'مڠتوق',
  'کدمڤڠ',
  'ڤوتول',
  'مڽڠݢت',
  'ميديا',
  'کوتقڽ',
  'لکوقڽ',
  'ڤلاهڤ',
  'سميلڠ',
  'کماني',
  'برومل',
  'کيفية',
  'سمدرا',
  'ترسڠݢ',
  'موموت',
  'ݢاجين',
  'برسلڠ',
  'سروتن',
  'منرجه',
  'ݢاليڠ',
  'سکندڠ',
  'دڠکيس',
  'روجوع',
  'برابو',
  'کاتوڠ',
  'ڤيڽوق',
  'ياهوم',
  'متقدم',
  'لڽڤله',
  'کليئن',
  'رمبوس',
  'مياڠڽ',
  'سچوڤق',
  'اوليڠ',
  'برݢلڤ',
  'ليڠکه',
  'معاهد',
  'کمودي',
  'اوسوس',
  'مرديه',
  'ڤڽلڠن',
  'موڠسڠ',
  'نجاسة',
  'دڤادو',
  'هاتيڽ',
  'شورکن',
  'ڤنچݢه',
  'ماجوه',
  'بردرم',
  'اٴودا',
  'کاتمو',
  'اومبڠ',
  'ڤاياو',
  'سکدوق',
  'دڤيجق',
  'دهاݢڽ',
  'کولوڤ',
  'سلواڠ',
  'کيترن',
  'منچيت',
  'رڠݢوت',
  'ريتوق',
  'منچکه',
  'کاچوڠ',
  'لامين',
  'روٴيت',
  'بيلسن',
  'ممنتق',
  'ڤورتا',
  'سمبوه',
  'باجيت',
  'ودانا',
  'لودوه',
  'توجو٢',
  'کمڤون',
  'لولي٢',
  'لاتيڠ',
  'ݢماون',
  'ݢلومت',
  'کعزمن',
  'ڤاتيڠ',
  'اکومن',
  'کلمهڽ',
  'ليڠسا',
  'ملائک',
  'دتالڠ',
  'برسرت',
  'جيجيت',
  'کبجتن',
  'دسڤوق',
  'سدرجه',
  'ڤکالي',
  'دتيبر',
  'سمبوڠ',
  'ڤڽسڤن',
  'قافلة',
  'لمبوڠ',
  'دلامر',
  'بيکو٢',
  'لامبڠ',
  'سڤراي',
  'چنرول',
  'بسکوم',
  'الملک',
  'کوسو٢',
  'چوريت',
  'واتقڽ',
  'دسڠݢه',
  'چمواس',
  'سقوات',
  'اوڤق٢',
  'ڤدوسي',
  'راچيت',
  'اخيرن',
  'ݢودري',
  'تتابس',
  'باويل',
  'دڤالا',
  'مڠڠݢر',
  'ايبول',
  'خيالي',
  'چڠيس٢',
  'برالس',
  'برچکڤ',
  'بليسه',
  'سسيکت',
  'چاڤيت',
  'ملوار',
  'چلاکڽ',
  'سيلڠن',
  'تڠکوڤ',
  'روبين',
  'مريکن',
  'دارو٢',
  'لوهور',
  'مريدڠ',
  'ليڠهن',
  'لاکوم',
  'دهمڤڤ',
  'سوسوه',
  'کيقوا',
  'جراݢت',
  'سيميس',
  'کداي٢',
  'جوهوڠ',
  'اوڤين',
  'بواين',
  'ساکوڽ',
  'دباهم',
  'تايوم',
  'جاتوه',
  'سودرا',
  'براکر',
  'ڤمايڠ',
  'ڤناکر',
  'مڽينر',
  'ڤمانه',
  'توبةڽ',
  'دانچم',
  'کڤيلن',
  'کبيسة',
  'دترجه',
  'هياسن',
  'تروکي',
  'دلاره',
  'توليس',
  'ݢلرنڽ',
  'منچور',
  'کوٴيه',
  'ترچمر',
  'توکوس',
  'ايدهڽ',
  'ناٴيف',
  'ڤينده',
  'کوندڠ',
  'دکليڤ',
  'براڠݢ',
  'رودوم',
  'ترببڠ',
  'دودوڠ',
  'دسيکڽ',
  'هاريڠ',
  'ڤيکوق',
  'افسون',
  'ڤڠڠݢر',
  'مجنون',
  'داڤيت',
  'کتوهر',
  'ڤنولر',
  'سيڠکر',
  'ڤيݢمن',
  'براون',
  'ستريس',
  'تراݢق',
  'مرياه',
  'لويا٢',
  'برارڠ',
  'چڠکرم',
  'نيراي',
  'مڽيڠا',
  'تومڤڠ',
  'ملمڤڠ',
  'کللسا',
  'چميقن',
  'بوبوس',
  'دبنته',
  'ترواڠ',
  'مڠنجق',
  'کاديم',
  'ڤسيرن',
  'ساڠاو',
  'مڠيرڤ',
  'ککتوس',
  'چالق٢',
  'ڽوڽيه',
  'مڽونت',
  'ڤاسڠ٢',
  'چڠکلت',
  'سلاڤن',
  'کندڠ٢',
  'کوجور',
  'ڤريک٢',
  'روتوڤ',
  'لاڠير',
  'سوليت',
  'ساݢوڽ',
  'ليڤور',
  'مينوڠ',
  'اسباب',
  'ڤمالر',
  'براسڽ',
  'لمڤدل',
  'کسکول',
  'سچينا',
  'علامة',
  'کروني',
  'تعلوق',
  'سڤاݢي',
  'کليبت',
  'مورني',
  'دأوڤق',
  'ميتار',
  'بوکوڠ',
  'ڠياڠ٢',
  'ددکوت',
  'اسمى٢',
  'ڤيجام',
  'تردڤڠ',
  'کتݢسن',
  'دسامن',
  'برقسا',
  'مڠميس',
  'چياه٢',
  'ݢربوڠ',
  'برساب',
  'ملڠݢق',
  'فونيک',
  'مناڠڤ',
  'ݢراهم',
  'تيکوس',
  'مرچيق',
  'مڽڠوق',
  'تنداڽ',
  'دکنجي',
  'اندور',
  'مڠݢبو',
  'چڤولن',
  'رندهن',
  'سوکاي',
  'محتشم',
  'برايس',
  'دتمڤه',
  'لونچس',
  'سرڠسڠ',
  'لنتوق',
  'جاݢلن',
  'لوديه',
  'برخبر',
  'ڤچوتن',
  'کليان',
  'ڤهالا',
  'منچوک',
  'ڤيليت',
  'اوفوق',
  'سوسوق',
  'ستيلن',
  'ڤنوهي',
  'تورون',
  'سلتوق',
  'کاروت',
  'مڽڤره',
  'ڤنچلا',
  'مڽيلڠ',
  'ݢروڤس',
  'کݢرمن',
  'اينده',
  'عمومڽ',
  'کمنتو',
  'ݢومبا',
  'ڤنکنن',
  'دچاچه',
  'دداکي',
  'دبيلڠ',
  'کانتڠ',
  'ݢوسور',
  'مڽمبل',
  'ماچمڽ',
  'ڤنمبت',
  'ڤندوه',
  'ازليا',
  'کرمين',
  'چاتور',
  'مڽيسي',
  'برلچه',
  'سوٴون',
  'دأليه',
  'فيلم٢',
  'تبوقن',
  'ريڠيه',
  'چاوي٢',
  'بردرق',
  'اليه٢',
  'فکتا٢',
  'ملادي',
  'ولاده',
  'چنڠکس',
  'مورهن',
  'بواه٢',
  'منترا',
  'واريث',
  'ترسدو',
  'لستيق',
  'سرڤوه',
  'ݢالاي',
  'تتاڤن',
  'چوري٢',
  'ترکدڠ',
  'مڠواڤ',
  'کويق٢',
  'کوديل',
  'دلاڽق',
  'اعراف',
  'چولوڠ',
  'کليکت',
  'برواس',
  'ڤاداڽ',
  'دأوجي',
  'ڤرنتق',
  'چيريت',
  'ڤنشره',
  'جوالن',
  'کاسير',
  'کوتيس',
  'فوڠسي',
  'ݢاجه٢',
  'براوس',
  'سدرجت',
  'کسدڤن',
  'دتبوس',
  'جلواڠ',
  'دڤربل',
  'راتوس',
  'مڽارو',
  'ترکمن',
  'بيڽيق',
  'مرباو',
  'سمودل',
  'ڤڽاݢت',
  'براݢم',
  'منانڠ',
  'برقمر',
  'لودهڽ',
  'باروڠ',
  'ملڤور',
  'بورچي',
  'داماي',
  'کچوڠي',
  'جزوء٢',
  'سبوتن',
  'بردکم',
  'ريڤوق',
  'ڤرنتڠ',
  'دکويق',
  'کياني',
  'برباک',
  'ݢولير',
  'کانق٢',
  'ݢوچيق',
  'مڽاور',
  'امڤيق',
  'تينور',
  'سکاسر',
  'مرڠکڤ',
  'تبارک',
  'روڽوق',
  'منندس',
  'داراڽ',
  'لواسڽ',
  'تاريخ',
  'سوڠول',
  'بولتن',
  'بولور',
  'جماعه',
  'موکون',
  'سيحير',
  'ليڠکر',
  'موبيل',
  'زيبرا',
  'بندير',
  'بوکيت',
  'اوڤشن',
  'برتکن',
  'اٴور٢',
  'سيتيڠ',
  'براوس',
  'درتور',
  'داولي',
  'تاڤيس',
  'ليترن',
  'چوچين',
  'سهيڠݢ',
  'تعزير',
  'کيلوڠ',
  'ڤوترن',
  'ݢروبق',
  'تلوقڽ',
  'ڤکارم',
  'برلاݢ',
  'لوڠکڠ',
  'دبياق',
  'کاسيڤ',
  'ݢمبوه',
  'دايدس',
  'برونڠ',
  'سکيلو',
  'امڤيڠ',
  'منمني',
  'حرفيه',
  'مڠکوڠ',
  'راين٢',
  'دسيار',
  'سوروق',
  'بردرڤ',
  'بابوق',
  'ججمبت',
  'ݢوريت',
  'اوبهن',
  'رڠکيق',
  'ڤڠصحن',
  'ڤروتق',
  'دسيرا',
  'اکتيف',
  'سودوڠ',
  'بلاين',
  'اتوم٢',
  'دچاري',
  'چقتاي',
  'هوجن٢',
  'ڤڠبلن',
  'کردوس',
  'تومڤر',
  'لينچم',
  'روڠوڠ',
  'حکومڽ',
  'ڤڠتوا',
  'دماسق',
  'هندقڽ',
  'ليڤيت',
  'ڤيکول',
  'برکوس',
  'سيستم',
  'راکوس',
  'سڤالا',
  'کلاون',
  'امڤلس',
  'نڤکين',
  'تݢقکن',
  'بيسيل',
  'راکون',
  'ملاݢس',
  'برياڠ',
  'ڤانيک',
  'کتنڠن',
  'دکاتي',
  'روندس',
  'ڤوسر٢',
  'ڤلنتر',
  'برسدو',
  'دينيم',
  'مندرم',
  'ݢينجڠ',
  'خليفه',
  'ڤلاکت',
  'ݢيتيق',
  'دبليت',
  'ڤيسڠ٢',
  'ڤڽالڠ',
  'رنتسن',
  'تالين',
  'تربله',
  'سديڤا',
  'داکڤن',
  'چلوڤن',
  'تردڤن',
  'جڠکوس',
  'بنديک',
  'مڽاݢڠ',
  'سوندڠ',
  'سريني',
  'کلايڠ',
  'ڤنجاݢ',
  'ݢيليڠ',
  'ڤندرس',
  'جاري٢',
  'موداڽ',
  'بقدول',
  'ݢلتيڠ',
  'کولي٢',
  'کمنقن',
  'تنجقن',
  'مڠڤوس',
  'باتوق',
  'ملڤيه',
  'اسڤرو',
  'برڤدڠ',
  'کاريم',
  'لوتيڠ',
  'ڤڠتول',
  'مڠرجڠ',
  'چاچتن',
  'جڤونڽ',
  'تامڤا',
  'چاڠيڤ',
  'ڤردوم',
  'ݢرابڠ',
  'دڤيکت',
  'دفوسي',
  'منيرا',
  'سبارڠ',
  'جڠکوڠ',
  'اومت٢',
  'دتاون',
  'تݢسکن',
  'جوجاي',
  'تاندس',
  'سزمان',
  'سيوار',
  'وقتو٢',
  'چوڠيت',
  'واجب٢',
  'کيکيس',
  'باليق',
  'ترنچت',
  'سوراي',
  'ڤلسير',
  'اندڤ٢',
  'منڤور',
  'تريکو',
  'ڤرجمن',
  'کومين',
  'سيليه',
  'کاياي',
  'سراول',
  'مانيس',
  'تاتڠن',
  'دکمڤا',
  'لݢوجا',
  'دجڤيت',
  'جمڤول',
  'اجناس',
  'ڤرنجت',
  'باري٢',
  'بدوري',
  'بربلڠ',
  'ڤينوه',
  'ڤدادا',
  'سکاتي',
  'بوبور',
  'دستيل',
  'بليکو',
  'برجوڠ',
  'ترسڠت',
  'ريجيل',
  'داجوق',
  'طربوس',
  'سامڤل',
  'سادڤن',
  'کوريو',
  'کککلن',
  'کرکون',
  'برسڠݢ',
  'انتلس',
  'مڠوچڤ',
  'سباتڠ',
  'هنتي٢',
  'ممالڠ',
  'ريمڤي',
  'جالا٢',
  'ݢراده',
  'امبين',
  'دسليت',
  'انتيڠ',
  'سيولن',
  'مرڠکق',
  'مڽيکو',
  'بيدلن',
  'ڤيسکل',
  'ڤراجي',
  'سڤيدا',
  'بلوکڤ',
  'بيبن٢',
  'ݢهارو',
  'ديلتا',
  'ايڠکو',
  'دداري',
  'رندوق',
  'مغربي',
  'ڤاليه',
  'تڠݢلڽ',
  'ڤڠايق',
  'دبياق',
  'برموک',
  'ايلون',
  'سولجر',
  'بونچه',
  'سلادڠ',
  'ڤرلوڽ',
  'کفهمن',
  'رباني',
  'ملمڤڤ',
  'برسوت',
  'بندرڠ',
  'برعقل',
  'کرڤيد',
  'ڤميره',
  'فهمن٢',
  'بيکير',
  'چيليڠ',
  'کليبڠ',
  'ککڽڠن',
  'توارک',
  'چوچور',
  'ڤمجلن',
  'سجولي',
  'عليکم',
  'تيمبا',
  'قبورن',
  'ببالڠ',
  'خيزيت',
  'مسبوق',
  'ۏولوم',
  'ڤݢاوم',
  'ڤيجيت',
  'دسروت',
  'سرڠنڽ',
  'هاروس',
  'هنچيڠ',
  'کردوس',
  'دتݢور',
  'مختصر',
  'سيڠکل',
  'توانڽ',
  'ارهنڽ',
  'ڤمکتن',
  'کليڤق',
  'راتکن',
  'بروتي',
  'تيڠکه',
  'جاکون',
  'مرڠوت',
  'بوٴيه',
  'ڤݢاݢن',
  'ڤادوڠ',
  'اوڠݢق',
  'ملامن',
  'سبنتل',
  'سياکن',
  'ڤڤارا',
  'ۏرسوس',
  'بواس٢',
  'ݢلورا',
  'چيڤوه',
  'برتݢر',
  'ککيلي',
  'مراڽه',
  'اليبي',
  'منسوخ',
  'کيويه',
  'ممڤلو',
  'سجڠکل',
  'مڠندڤ',
  'ريڽاي',
  'اڤوڠن',
  'کاڤلڽ',
  'سهينا',
  'برچلا',
  'هڠݢاو',
  'دتندڠ',
  'امبوس',
  'امانه',
  'لامت٢',
  'عافيت',
  'بردمي',
  'مندوب',
  'قدرتي',
  'ڤڠچمن',
  'برترا',
  'اݢيهن',
  'لالقي',
  'مڠياس',
  'دهارا',
  'کللهن',
  'حاجتڽ',
  'تورسو',
  'تيمو٢',
  'سلينڤ',
  'قيصه٢',
  'چکاتي',
  'ايکتن',
  'چراوت',
  'چاکتن',
  'لوجور',
  'جڠݢول',
  'ڤمباچ',
  'مميجت',
  'ترکبت',
  'ابتله',
  'کباوه',
  'دتاجا',
  'منوبا',
  'برنذر',
  'باروت',
  'کيڤيڠ',
  'رومين',
  'هوبوڠ',
  'اميل٢',
  'لمڤوه',
  'انونا',
  'ڤڽاجي',
  'بڤتيس',
  'دسروت',
  'سکواد',
  'اڠکوڤ',
  'بکياق',
  'جوݢيڠ',
  'برندي',
  'دأوسي',
  'بلاله',
  'ڤلوبي',
  'ݢانل٢',
  'ݢوليق',
  'ݢاروڠ',
  'دچنتس',
  'سيداي',
  'سلنجر',
  'لوسوه',
  'سرانم',
  'دترکڤ',
  'بکسوت',
  'ڤبيان',
  'ڤداسن',
  'کتيڤ٢',
  'ڤڠکلن',
  'مڠاتم',
  'لاٴوق',
  'ڤباهو',
  'ڤميره',
  'تواضع',
  'ايثار',
  'بليکس',
  'تلنله',
  'اعربڽ',
  'جولور',
  'بويور',
  'دسودو',
  'تمبهن',
  'مڽيلت',
  'ڤيلوڠ',
  'ميندي',
  'اوديه',
  'اوساي',
  'علموڽ',
  'دراهڤ',
  'کديدي',
  'بربحث',
  'کاتکو',
  'ݢموق٢',
  'کڤجلن',
  'ڤاميو',
  'سرمڤو',
  'تکقکو',
  'ڤڽرڠڽ',
  'کمبلي',
  'مندکم',
  'کاچيق',
  'سسيکو',
  'بليوت',
  'کيروڠ',
  'برسيل',
  'اوڠوه',
  'بسيکن',
  'ترجرت',
  'ڤوجين',
  'سودو٢',
  'روٴية',
  'ستيکن',
  'کلارا',
  'واجيق',
  'کراچق',
  'طبيعي',
  'توالڠ',
  'رينچي',
  'منايڠ',
  'هيتم٢',
  'بلندا',
  'بوکوه',
  'رواڠڽ',
  'توهور',
  'بليݢو',
  'هيراو',
  'سجابت',
  'ڤريدي',
  'ڤاناو',
  'اوڤرا',
  'برليق',
  'اوکور',
  'سڤقسي',
  'بردسر',
  'ترعلى',
  'اساسڽ',
  'چڠکوڠ',
  'اينتي',
  'سهستا',
  'لندسن',
  'برقول',
  'هيجاو',
  'توسوق',
  'ڤيکون',
  'تربطل',
  'لنجوڠ',
  'تاور٢',
  'لونتا',
  'سوڠوڠ',
  'ڤڽايو',
  'چچيبر',
  'برواڠ',
  'زبرجد',
  'لوروت',
  'لوباڽ',
  'دساڤو',
  'اتسنڽ',
  'نصيحت',
  'ستروک',
  'ݢوندڠ',
  'ليسوت',
  'فوسيل',
  'سوليڠ',
  'سݢونا',
  'منوبق',
  'منچيس',
  'بيذاڽ',
  'کوبيق',
  'نصيبڽ',
  'دمڤول',
  'منولر',
  'کوݢنت',
  'ݢولان',
  'ݢيمبل',
  'کورڠڽ',
  'کوستا',
  'موريس',
  'اڠکوق',
  'بولوس',
  'بريڠ٢',
  'ڤموره',
  'سممڤت',
  'مرنڠي',
  'ڤينيس',
  'دولوڽ',
  'برجاس',
  'منکوق',
  'سڠݢاو',
  'چاتوس',
  'رمبيه',
  'برسرو',
  'مندڤن',
  'ڤرليڠ',
  'دسيرم',
  'اوکير',
  'معصية',
  'راڤيت',
  'ولندا',
  'هاروم',
  'دناوا',
  'سرکاي',
  'بردڠک',
  'کاجڠن',
  'بربوت',
  'کيلڠ٢',
  'منوڤڠ',
  'سورنا',
  'دمڤيل',
  'مڠݢتڠ',
  'لنتور',
  'کمبوت',
  'موسنه',
  'اولسن',
  'الوصي',
  'ماکن٢',
  'اومڤت',
  'توکڠ٢',
  'باروک',
  'ددڠري',
  'سمويڠ',
  'والاو',
  'سيواڠ',
  'چمڤلڠ',
  'کبنچي',
  'ڤالون',
  'ممڠکو',
  'لوتوڠ',
  'اياله',
  'مڠريڠ',
  'کارا٢',
  'دوا٢ڽ',
  'قلبوڽ',
  'ککوما',
  'برݢرق',
  'سومبو',
  'ڤماڤر',
  'ݢاٴيت',
  'مڽڤيت',
  'ڤردات',
  'اومنة',
  'ڤنجلس',
  'ليبسن',
  'لايڠن',
  'ڤڠرتن',
  'نمڤوڠ',
  'کدکيڠ',
  'دبنوم',
  'کاسين',
  'سوٴيس',
  'سلمڤه',
  'نفسو٢',
  'چاروم',
  'تيکمن',
  'کريسي',
  'مڠنچم',
  'ݢاسي٢',
  'دسالق',
  'چراي٢',
  'مڠابت',
  'سايکت',
  'جانور',
  'باليس',
  'ليکور',
  'ڤڽڤين',
  'سمالو',
  'سارڤن',
  'مرڠکه',
  'تابير',
  'مندبه',
  'منديڠ',
  'تلابت',
  'مابوڠ',
  'ڤوڤول',
  'چراچڤ',
  'نݢري٢',
  'جوليڠ',
  'جيدور',
  'ڤرندا',
  'ڤڠيلق',
  'ݢيواڠ',
  'چوڤوت',
  'ممالو',
  'فيرما',
  'چاکڤ٢',
  'سلاݢي',
  'ڤرانن',
  'مڽمبم',
  'دلعنة',
  'ݢلساي',
  'دتنوڠ',
  'ڤولون',
  'بوسمن',
  'سڤديه',
  'کچيوت',
  'تامکن',
  'دڤڠکس',
  'برادو',
  'دسيڤق',
  'کوالا',
  'سکڽڠ٢',
  'برليق',
  'ساهوت',
  'منيبق',
  'مامهن',
  'ݢليتا',
  'دتيمڠ',
  'مڽيݢق',
  'ترڠݢڠ',
  'کيلتن',
  'کمکرن',
  'اجنبي',
  'مريبق',
  'ڤڠچڤن',
  'توبجي',
  'اوبيڽ',
  'ملکتي',
  'ڤڠاله',
  'الهية',
  'ددوݢڽ',
  'سراني',
  'مڽوجي',
  'ڤولدر',
  'بلادا',
  'مانقن',
  'اولڠن',
  'بياقن',
  'دهليا',
  'بيڠکه',
  'دڤيوه',
  'ليليه',
  'لنجور',
  'مڠبوه',
  'ڤوٴوه',
  'کاراو',
  'ممبده',
  'بهينا',
  'ڤمندڠ',
  'ديريس',
  'اڠسام',
  'براله',
  'سمڤره',
  'ݢولا٢',
  'جاهيد',
  'بوروڠ',
  'الهام',
  'فضيلة',
  'ملڤوه',
  'بيلوق',
  'تڠݢلم',
  'برندڠ',
  'هقائق',
  'ڤڠورق',
  'دورين',
  'برسيڤ',
  'تباکڠ',
  'چوچوڽ',
  'ايدرن',
  'جمهور',
  'ديلمن',
  'ليمڤڠ',
  'نام٢ڽ',
  'فيروم',
  'چڠکير',
  'ممباچ',
  'بريڠس',
  'فتنهن',
  'ممچوت',
  'تايسو',
  'توتور',
  'برسبب',
  'ڤرميت',
  'ترڤجم',
  'سيبار',
  'منندو',
  'براکه',
  'ڤرݢات',
  'دتواي',
  'هيلڠڽ',
  'بروݢي',
  'لارين',
  'جوبور',
  'کاسور',
  'تاترا',
  'راياو',
  'کلڤيق',
  'قدرةڽ',
  'صدقه٢',
  'برورد',
  'مڽالڠ',
  'اولنڽ',
  'ݢدبوق',
  'ملايو',
  'رڠݢوي',
  'ڤڠرهن',
  'دبياس',
  'بردکڤ',
  'ڤايا٢',
  'دکجمي',
  'ميديت',
  'سجارڠ',
  'برمبڠ',
  'امسون',
  'انتول',
  'بنچوه',
  'چوبوڠ',
  'سڤيتم',
  'کانچڠ',
  'بنيتن',
  'ڠوتوت',
  'بلوتي',
  'ݢنچتن',
  'چنچوت',
  'کاکيق',
  'کومور',
  'تروفي',
  'ڽاڽين',
  'لمبوڽ',
  'توتول',
  'سرنده',
  'براله',
  'چيکوق',
  'لوريق',
  'تمولڠ',
  'ݢروتو',
  'برچرت',
  'دتڤوق',
  'لوارڽ',
  'منچمر',
  'دڤتيق',
  'دبڠکڠ',
  'لاڤڠن',
  'بيسيت',
  'سوجود',
  'برلڠن',
  'باکين',
  'روکوق',
  'ڤڠوڽه',
  'تلنتڠ',
  'ڤناون',
  'ڤايوڠ',
  'کيراو',
  'کوليق',
  'اوسڠ٢',
  'ترسسر',
  'منجبق',
  'مافلا',
  'ݢمبر٢',
  'تمڤق٢',
  'ڤراوس',
  'ڤاچيه',
  'هيسڤن',
  'للاتو',
  'مڠاڽڠ',
  'لنيرد',
  'تومبو',
  'ڤرچاي',
  'تکسيس',
  'اجرن٢',
  'سڤنوه',
  'ملچوڤ',
  'دکچمي',
  'تڠکوت',
  'انداک',
  'کرجاي',
  'لوجيڠ',
  'ترجمه',
  'رنده٢',
  'ݢارڠن',
  'ترکنل',
  'جناوي',
  'ليڤوت',
  'تفيتا',
  'ݢروت٢',
  'سڤنوه',
  'اوڠکڠ',
  'ڤمنجڠ',
  'بربنم',
  'هوموس',
  'مالي٢',
  'ڤڠويق',
  'تمڤلس',
  'دجنڠي',
  'ترتکا',
  'چاکوق',
  'برومه',
  'برامس',
  'منوݢس',
  'برنتس',
  'کالوڠ',
  'ڤرتلن',
  'ݢاميس',
  'سيمڤن',
  'دچموه',
  'بورون',
  'مڽارق',
  'اوليس',
  'برکجي',
  'ميڠݢت',
  'کحرمت',
  'ڤرابو',
  'ڤڤويو',
  'ڤنچوت',
  'ممڤلس',
  'لومبه',
  'ݢالري',
  'باسوڠ',
  'بنتاي',
  'توميت',
  'سومين',
  'توانن',
  'منݢوق',
  'بليتن',
  'داوچڤ',
  'دچوبا',
  'کلڤسن',
  'کمباو',
  'سلامي',
  'ݢليتا',
  'ڤڠيصه',
  'ݢايوت',
  'تڠݢوق',
  'بنتهن',
  'شمالي',
  'مڠيڤق',
  'دسبوق',
  'کدڠسا',
  'اوبين',
  'سندرا',
  'جمڤي٢',
  'مڽونو',
  'برݢݢس',
  'چمڤاک',
  'کهوين',
  'لاٴون',
  'ڤڠحجه',
  'ترسمو',
  'مناسق',
  'ايندن',
  'مڠيرق',
  'ججاري',
  'ڤينتا',
  'سالوڠ',
  'لادوڠ',
  'ڤاليت',
  'جريتڽ',
  'مريکڽ',
  'واروڠ',
  'ݢريند',
  'ڤيوتڠ',
  'جلڠکه',
  'دسوجي',
  'ايݢلو',
  'مڠريه',
  'مڠجري',
  'برنظم',
  'چريڠ٢',
  'دتڠݢق',
  'روسوت',
  'بيرسم',
  'نودوس',
  'ساتوڽ',
  'چومڤڠ',
  'تالون',
  'دأولق',
  'توجين',
  'ڤيسيه',
  'مراجه',
  'جندرا',
  'کسنقن',
  'کرتيل',
  'چرکاو',
  'بونتت',
  'توڠݢق',
  'درجتڽ',
  'باٴيق',
  'بتول٢',
  'هيڠݢن',
  'فلورا',
  'سنتنڽ',
  'دنتڠن',
  'چرڤين',
  'برکجي',
  'ترياڠ',
  'ڤوستڽ',
  'کللسن',
  'سݢامت',
  'داڤتن',
  'بيوکو',
  'ممادت',
  'مڠابو',
  'مسراڽ',
  'ڤڽعير',
  'دبوسر',
  'منيکد',
  'مڠتيس',
  'ݢاڽوڠ',
  'دأتڤي',
  'تلهنڽ',
  'دبدوڠ',
  'بݢيني',
  'نوتيس',
  'برزنا',
  'ݢميلن',
  'ڤڽاجق',
  'سيدا٢',
  'کڽيتن',
  'تليتي',
  'ڤڠچتن',
  'سکيجڠ',
  'ترخبر',
  'اوفتي',
  'ممولس',
  'ڤنوبل',
  'دچلوڤ',
  'سيسوي',
  'بندلا',
  'سنيون',
  'ڤولمه',
  'منمڤا',
  'ستيلا',
  'مڠاکس',
  'بيسيق',
  'سيݢي٢',
  'ممتير',
  'تحکيم',
  'سلاٴن',
  'بالسڽ',
  'بلندر',
  'ڤسوݢي',
  'مروجق',
  'ݢجولق',
  'تويشن',
  'تابور',
  'کاچيڤ',
  'کرتيڠ',
  'اکوان',
  'اوکيق',
  'چيکيه',
  'دريڠو',
  'دکندر',
  'اسين٢',
  'للابي',
  'برتݢس',
  'ڤونتڠ',
  'دݢنتي',
  'ڤوتوت',
  'دوکوڠ',
  'ڤڠوڤم',
  'کوبيت',
  'سينتل',
  'دتوڤڠ',
  'ساروق',
  'دسبيت',
  'دڤالو',
  'منمڤق',
  'ݢݢادن',
  'کبوڠن',
  'دڤالڠ',
  'بوکنڽ',
  'چڤچاي',
  'بربڠݢ',
  'لوکيو',
  'کواري',
  'ڤانسڽ',
  'بيندڠ',
  'جمبڠڽ',
  'شرݢله',
  'ترنام',
  'چوڠوق',
  'بلباو',
  'ممبڠݢ',
  'تيورم',
  'مراڤت',
  'سورقن',
  'مڠيتر',
  'توݢلن',
  'ڤلاون',
  'تڠارا',
  'توسله',
  'ناممو',
  'دمنڠي',
  'ساڤوڠ',
  'دڤارس',
  'کصبرن',
  'کليڤر',
  'مڠاوس',
  'دݢالس',
  'کيچاو',
  'ترجرڤ',
  'تهنيک',
  'راساي',
  'ڤيکوڠ',
  'سريرا',
  'بابون',
  'کنحسن',
  'ليوڠ٢',
  'درنوڠ',
  'علماء',
  'برتيݢ',
  'سارفڽ',
  'دبيار',
  'بباوڠ',
  'چيمير',
  'سميجا',
  'دڤاڤه',
  'للتوڤ',
  'تاڤوق',
  'ليمبه',
  'سکلڤل',
  'سڠککو',
  'وتيقه',
  'ݢريتڠ',
  'ديوات',
  'دبنتق',
  'چمڤلو',
  'برکوڤ',
  'خواطر',
  'رهمةڽ',
  'ليمبت',
  'لاڠوق',
  'بوچور',
  'سودور',
  'باڽول',
  'کريس٢',
  'دننتي',
  'کيکوق',
  'شهوةڽ',
  'کيالن',
  'لڠسيت',
  'سݢاني',
  'اݢول٢',
  'ڤنارم',
  'نسخهڽ',
  'تردوݢ',
  'ڤيدان',
  'ماتکو',
  'بونين',
  'راجين',
  'سسندل',
  'ڤڠݢلي',
  'تولوس',
  'ڤمبطل',
  'تنديل',
  'چلادڠ',
  'ڠاٴوم',
  'سڤاسن',
  'کوناڤ',
  'سکالت',
  'ويمان',
  'دلاله',
  'دلندا',
  'ڤڠاڤي',
  'کوتاي',
  'شکورڽ',
  'ننتيڽ',
  'دراوا',
  'برکوق',
  'ممڠݢل',
  'ݢاڤيس',
  'ممڤلس',
  'دکيرا',
  'بيداي',
  'منوبي',
  'لايور',
  'اوݢوه',
  'دنتوم',
  'ريݢات',
  'کلمسن',
  'براݢه',
  'موجود',
  'منيمڠ',
  'چڠکرڠ',
  'براݢن',
  'صالحة',
  'ريکور',
  'ترکمس',
  'حضاري',
  'ڤريمي',
  'ݢلنتر',
  'ڤارڠن',
  'موديل',
  'کاݢيت',
  'چيريق',
  'اندرق',
  'ڤڽماي',
  'راجاو',
  'ڤلتيڠ',
  'بوڠکس',
  'محرمڽ',
  'فيروس',
  'مندرس',
  'سلاسر',
  'ترݢرق',
  'دݢلرڽ',
  'سڤاݢي',
  'مڠاچو',
  'بارڠڽ',
  'جرواڠ',
  'ݢيريس',
  'باتور',
  'ريسين',
  'ملاله',
  'هاچور',
  'ݢلوڤق',
  'دڤڠݢل',
  'ايندو',
  'ججامڠ',
  'ڤوستر',
  'حلقوم',
  'باساو',
  'مندسق',
  'دݢاسق',
  'کيبيق',
  'شقدوف',
  'ڤنجڠ٢',
  'کلواي',
  'سجادي',
  'چوما٢',
  'دتيکن',
  'داديق',
  'ڤرامه',
  'چواتن',
  'ۏاکوم',
  'تياسا',
  'ݢيريق',
  'سرنتق',
  'چڠکلا',
  'ڤڠکڠن',
  'ديکاو',
  'مذکور',
  'کرچيڤ',
  'تاراڽ',
  'سکويه',
  'ڤڠلڤن',
  'مڠيلت',
  'انجيڠ',
  'باهنڽ',
  'بوڤيڠ',
  'ميسيت',
  'مڽڠکڽ',
  'بهوڠن',
  'ڤاکيت',
  'تاکوت',
  'کجوتن',
  'کتواي',
  'جيتوق',
  'جايکن',
  'برودو',
  'براڠس',
  'دبور٢',
  'باڠون',
  'ݢاجوس',
  'چنتيق',
  'چيکرق',
  'کتڤتن',
  'سرݢهن',
  'سهلاي',
  'بوݢيل',
  'رمبيق',
  'دݢيلڤ',
  'سليڠن',
  'منوان',
  'سوڠلڤ',
  'چاموق',
  'ڤيقڤا',
  'تنترا',
  'برعزم',
  'کاليڽ',
  'باݢاو',
  'برياق',
  'عداوة',
  'سکيرم',
  'انجره',
  'راڤوه',
  'بربوک',
  'رازيا',
  'سوسور',
  'کوبوڠ',
  'ترامت',
  'رادين',
  'لاڠيت',
  'دجابت',
  'بڠکوق',
  'مڽربو',
  'سريان',
  'دڤارس',
  'منوچڠ',
  'سوکوڽ',
  'ݢورور',
  'للمڤڠ',
  'باروڽ',
  'سيسيه',
  'دسواڤ',
  'کراچڠ',
  'دڤتري',
  'سيمبر',
  'نوتيس',
  'ماچوق',
  'ݢازيل',
  'سوڠکن',
  'تاکير',
  'بيريڠ',
  'فکتور',
  'ڤيرقن',
  'بيساي',
  'جالنن',
  'سيبوم',
  'اڤنيا',
  'اوڤهڽ',
  'چاٴوس',
  'دالمن',
  'برتوت',
  'دچيتا',
  'بردکم',
  'روڠوت',
  'کمنتم',
  'استاک',
  'چريتا',
  'تمارا',
  'بلوم٢',
  'سڤاسڠ',
  'سديهڽ',
  'جونتا',
  'راديو',
  'ترمبت',
  'ڤنتيل',
  'ڤنتقن',
  'ݢلينڠ',
  'دسالق',
  'جارڠ٢',
  'اورڠڽ',
  'ريݢيل',
  'دتمڤه',
  'سڤاسي',
  'کراۏت',
  'ڤڠانن',
  'ميترن',
  'سجوقڽ',
  'سرمڤق',
  'وادوه',
  'رنتڤن',
  'ڤوجوق',
  'تيلي٢',
  'ڤرلوڤ',
  'سراڤه',
  'ملوبڠ',
  'سڤورت',
  'ڤڠکور',
  'برختن',
  'سرنجق',
  'ممڤري',
  'ايݢول',
  'مڠنتت',
  'بنترس',
  'ساعت٢',
  'ستاڤق',
  'سبلهڽ',
  'انچوڠ',
  'برانم',
  'اريڠ٢',
  'ناداڽ',
  'سساري',
  'سبنتن',
  'دلاين',
  'تشهيد',
  'باطين',
  'ميريڤ',
  'کلسيت',
  'مونتو',
  'دکريق',
  'کيکوق',
  'موربا',
  'رينتس',
  'دتوجو',
  'مڠتيم',
  'کرسيڤ',
  'مڽاجق',
  'دراڠک',
  'ݢوݢوت',
  'الالا',
  'ڤيندا',
  'رکعةڽ',
  'مڽنتل',
  'ݢوتيس',
  'منوکڠ',
  'هارين',
  'ڤاهتن',
  'مڠريس',
  'دهادڤ',
  'تاموڽ',
  'سلماو',
  'برعقد',
  'ملايڠ',
  'داڠاو',
  'ايندڠ',
  'بيلوه',
  'کروسي',
  'سينيڽ',
  'ترچلا',
  'ݢيڤسي',
  'بردڠو',
  'سدينر',
  'سنتوه',
  'برسلق',
  'ڤوڠکي',
  'ݢارا٢',
  'راراو',
  'لمبه٢',
  'ايلم٢',
  'بنتل٢',
  'دکاڤق',
  'بڠکوت',
  'کوليت',
  'ملاکق',
  'کلاوق',
  'کچڤوڠ',
  'ايريس',
  'بردتس',
  'کوسوق',
  'روبوق',
  'القصه',
  'معتبر',
  'سلومر',
  'ساجق٢',
  'تيسيق',
  'ڤرماي',
  'اوريس',
  'ڤلارا',
  'ککتتن',
  'چليکي',
  'اوچق٢',
  'بردکو',
  'منداي',
  'ڤمارڠ',
  'ڤمتري',
  'سيکاي',
  'مينتل',
  'مننون',
  'مربوس',
  'کوکوق',
  'ڤڠکيڠ',
  'ستمڤن',
  'ݢنديس',
  'سولڤن',
  'بردسر',
  'نيلاو',
  'ڤڠولي',
  'کوٴيت',
  'چچکوڤ',
  'مقاله',
  'ڤڠساڽ',
  'سبارو',
  'کسامي',
  'ليرڠ٢',
  'ساديس',
  'چيبيق',
  'کيراو',
  'ݢالور',
  'سونور',
  'سوريق',
  'ڤمواس',
  'سلوبڠ',
  'ميانا',
  'کايلن',
  'تربڠ٢',
  'جاجرن',
  'قرابة',
  'ممڤوڠ',
  'لياڠ٢',
  'ڤيرون',
  'ريروت',
  'ليريت',
  'امين٢',
  'کينا٢',
  'رووات',
  'ڤاهوق',
  'تورسن',
  'مرݢوق',
  'دروجي',
  'سومڤل',
  'چننتن',
  'احوال',
  'سورين',
  'کيفية',
  'منوڠن',
  'وانتي',
  'برادس',
  'چچاوي',
  'چچکيق',
  'بربسر',
  'اوندن',
  'هاهو٢',
  'بهاسڽ',
  'ايرڤه',
  'تمبرم',
  'کاکي٢',
  'ملابڠ',
  'مڽمڤت',
  'لولوت',
  'مرڠݢڠ',
  'کوڠسي',
  'بولنڽ',
  'رهبان',
  'کڤيات',
  'لوسوڠ',
  'ڤمانس',
  'اجابة',
  'مورچا',
  'ݢوبيت',
  'دچروت',
  'بيرکو',
  'سعادة',
  'ݢݢرله',
  'مڠوڤق',
  'اورنا',
  'راسله',
  'ترلڠر',
  'جواق٢',
  'ساجين',
  'سباڽق',
  'مڠکوق',
  'کلبوت',
  'لمبيق',
  'چاباي',
  'کاندڠ',
  'دسيلق',
  'هنتيڽ',
  'ڤيڤيت',
  'توناي',
  'کوربل',
  'ڠاراي',
  'مولتي',
  'لواݢن',
  'مرعيت',
  'کنيکه',
  'ݢلبوق',
  'مڠينڠ',
  'ڤيݢون',
  'مرنتڤ',
  'کيساي',
  'بالسن',
  'بلودل',
  'اڠکوق',
  'ليدي٢',
  'کواسڽ',
  'راکوڠ',
  'دسکرو',
  'سکرول',
  'روکو٢',
  'ڤلاوا',
  'ايبوڠ',
  'دڠکيڠ',
  'لاوون',
  'لوڤا٢',
  'اديقڽ',
  'توڠکڠ',
  'برجرم',
  'تيسيس',
  'برسڠت',
  'ڤڠݢنڤ',
  'ڤيوه٢',
  'الأخر',
  'کروڤق',
  'سيروڠ',
  'ڤلڠکي',
  'دوسا٢',
  'ڤمالو',
  'سبيجق',
  'ڽمبيڠ',
  'سکاکر',
  'توڤاي',
  'سکاکي',
  'معصوم',
  'دتاکق',
  'لازيم',
  'ڤڠستو',
  'دجروق',
  'تمندڠ',
  'مظلوم',
  'مناون',
  'دبوات',
  'بنرله',
  'اجاره',
  'مڽيرا',
  'کاٴيڠ',
  'باتڠ٢',
  'مڠتوت',
  'دتݢوق',
  'چيچيڤ',
  'ممربن',
  'ݢولاي',
  'اوسور',
  'مسامه',
  'بوروس',
  'ڤنجوت',
  'حکايت',
  'ڤڠسيت',
  'سرواس',
  'کيڤوت',
  'تکافل',
  'دتوݢل',
  'کليار',
  'مميچو',
  'تبوسن',
  'ڤنامڽ',
  'تبرقن',
  'مڽرڠي',
  'لوڠݢق',
  'ساوي٢',
  'چاتوڠ',
  'سيفير',
  'منکور',
  'ڤاراو',
  'کميري',
  'دوريڽ',
  'ݢوموق',
  'ملونق',
  'ميثلن',
  'دوموڠ',
  'سوسون',
  'ڤاٴول',
  'سکريڤ',
  'مڠڠلڤ',
  'برتمن',
  'سکاون',
  'داڤتڽ',
  'دساهڤ',
  'مممبو',
  'مموست',
  'مڠيچو',
  'بوقسو',
  'بردبر',
  'مليڽر',
  'ناريس',
  'مماته',
  'چمڤاݢ',
  'کچمبه',
  'ڤالوي',
  'بسيطه',
  'ادڤيس',
  'بردکڤ',
  'منوجو',
  'سيدنا',
  'ارهکن',
  'ݢيڠݢڠ',
  'ڤناده',
  'مناڠس',
  'کيراي',
  'سݢانس',
  'ݢمرتق',
  'ݢيمنس',
  'برڠاو',
  'چوکرا',
  'جرومت',
  'باتوه',
  'منتڤڽ',
  'مرابق',
  'تراتو',
  'برڠسڠ',
  'ڤتيبن',
  'دوليڠ',
  'سماوڠ',
  'ݢوريڠ',
  'ڤينتس',
  'اوليت',
  'مرڠکم',
  'متراي',
  'کوروس',
  'ترلره',
  'ايڠيه',
  'برداي',
  'تولرن',
  'رضوان',
  'برنکس',
  'سنتيل',
  'جسديه',
  'مموات',
  'حوککا',
  'رونجم',
  'مرڠسڠ',
  'برڤسن',
  'مريچا',
  'توتيم',
  'ڤاروه',
  'دسرکڤ',
  'ريڤوه',
  'ملارت',
  'منڠݢم',
  'مابير',
  'لينيس',
  'برتݢس',
  'تعجوب',
  'سليات',
  'کڤودڠ',
  'ليريک',
  'تانوس',
  'اوريڽ',
  'لوليه',
  'بوتڠ٢',
  'ممروق',
  'جمبو٢',
  'کسموا',
  'دڤڠکل',
  'لوبڠڽ',
  'رينتا',
  'ناڤوه',
  'ڤڠبون',
  'هولور',
  'ݢلتيس',
  'چاچهن',
  'کوليج',
  'فونيم',
  'منالر',
  'ڤاواي',
  'تيڠݢر',
  'بربکس',
  'ترجرڠ',
  'لانتڠ',
  'اوندي',
  'مڠارو',
  'سولمن',
  'داوبت',
  'تجديد',
  'ڤينا٢',
  'تيلمڽ',
  'سرندڠ',
  'مڠبيل',
  'مينور',
  'ڤننوڠ',
  'تاهون',
  'تلاڤق',
  'کتوجو',
  'کطمعن',
  'ترترڤ',
  'ڤاتين',
  'اڠکين',
  'هيلڠ٢',
  'مونيب',
  'سلاير',
  'کريمڽ',
  'دسڠکق',
  'دباهم',
  'ناسيڽ',
  'سکوبا',
  'مڠݢلي',
  'مڽيوق',
  'کنتوڠ',
  'مڽبيت',
  'کڠسين',
  'بنتيڠ',
  'چلکوق',
  'برهان',
  'ݢابلݢ',
  'تربوي',
  'دوسين',
  'بارو٢',
  'ݢيريق',
  'سوڠيل',
  'دکيڤس',
  'مراڤه',
  'تومور',
  'ربوڠڽ',
  'ݢلوتق',
  'ۏيترن',
  'کاتير',
  'تينوق',
  'ريکنن',
  'ڤيچوق',
  'کاهين',
  'سڤارس',
  'سبرکس',
  'ترسڤق',
  'ملابل',
  'ڤڠوڤه',
  'داݢون',
  'اخلاس',
  'اڠکور',
  'ليچير',
  'ڤڠکيس',
  'باڽول',
  'محمود',
  'جابيل',
  'بينچو',
  'مبتدي',
  'سلڠکه',
  'ماتور',
  'ڤاٴير',
  'بتيڠ٢',
  'ݢوهوڠ',
  'ڤنچيس',
  'سعادة',
  'رنکيڠ',
  'ڤرلين',
  'کليکه',
  'دکاول',
  'بروعد',
  'کلودن',
  'چوکيڠ',
  'ترچکم',
  'مڠڤين',
  'بلاکڠ',
  'ڤڠاجر',
  'جاٴيز',
  'معتمد',
  'چڠکوي',
  'ڤڽارن',
  'چوووق',
  'ڤليڤت',
  'ساريت',
  'لوڤيق',
  'سڽوم٢',
  'ريڠݢي',
  'سکروم',
  'سݢاݢه',
  'کمبوڠ',
  'لوٴيڠ',
  'کندوڠ',
  'برتڤت',
  'ڤنداي',
  'ݢاݢسن',
  'بيڠيت',
  'سيڤاي',
  'دسيݢق',
  'هرتاڽ',
  'تعالى',
  'برݢوڠ',
  'هليجا',
  'دأڤيت',
  'ݢاݢڠڽ',
  'هديه٢',
  'کتيلن',
  'ڤنيبر',
  'هوراي',
  'نکارا',
  'منوجه',
  'تتاوق',
  'لالاي',
  'ترکرس',
  'ساتيه',
  'جوڠيت',
  'نخودا',
  'المذل',
  'کفورڽ',
  'هيبان',
  'ݢريمو',
  'دعاٴڽ',
  'اقامة',
  'ارݢوس',
  'بارکس',
  'مڠيرت',
  'دايوس',
  'دطلاق',
  'کونچو',
  'ويسيل',
  'سأيرس',
  'تمبيس',
  'رنجتن',
  'ݢراڤو',
  'منندڠ',
  'برهام',
  'ڤڠميس',
  'برسين',
  'دسيلا',
  'انچيت',
  'بريدا',
  'مڽندي',
  'مڽبيق',
  'کسيدڠ',
  'ميثل٢',
  'کراوڠ',
  'بنرکن',
  'ڽاريس',
  'ڤڠهضم',
  'ڤرواي',
  'جيبون',
  'سورت٢',
  'ڤوليق',
  'ڤلنچر',
  'مڠتيق',
  'موليه',
  'مويوت',
  'ڤڠيبس',
  'کاويق',
  'لمڤوه',
  'رادون',
  'راماي',
  'تاولن',
  'ڤاچڠن',
  'ݢندرڠ',
  'دݢودق',
  'بلهکن',
  'کرچيڠ',
  'برتکت',
  'تيمڤس',
  'اوݢوس',
  'سداتر',
  'لوڤوق',
  'ڤڠنجق',
  'بورون',
  'مرتور',
  'سيۏيک',
  'برکتم',
  'قلقله',
  'داڠکت',
  'برياڠ',
  'مڽمڤو',
  'سلايڠ',
  'برتݢڠ',
  'امينو',
  'سرڤوق',
  'کرکوم',
  'ڤيڠسن',
  'لمبيڠ',
  'ملسيڠ',
  'ڤڠچيل',
  'ݢجالا',
  'بولوه',
  'هيبوق',
  'ڤمڠݢل',
  'برنظم',
  'ريچيه',
  'برواڠ',
  'برکلس',
  'دتوتل',
  'اروسڽ',
  'مالاو',
  'ڤوجڠݢ',
  'اسوڠن',
  'لابرڠ',
  'کوروڠ',
  'کتياو',
  'ماچيس',
  'سوستر',
  'تراسي',
  'سووير',
  'مامقڽ',
  'ڤوجور',
  'دسالڤ',
  'ساݢون',
  'نورمل',
  'بيريت',
  'منندق',
  'يولکا',
  'مڠويق',
  'چاڤاو',
  'ڤورتل',
  'جنوبي',
  'ابليس',
  'بلاوا',
  'ديوان',
  'ڤنمڤن',
  'محصنه',
  'سورين',
  'مراوق',
  'سبولن',
  'دڤاچو',
  'سايڠي',
  'منته٢',
  'چاراي',
  'ميميق',
  'زياده',
  'برادڤ',
  'ݢدابڠ',
  'هيندل',
  'دوڠوق',
  'روبوت',
  'دبواڠ',
  'اڠکتن',
  'ممالر',
  'خبر٢ڽ',
  'ايڤوس',
  'اڠينڽ',
  'ساٴور',
  'ڤڠواڤ',
  'ڤتيلن',
  'مراسي',
  'باتسن',
  'ڤڠݢوق',
  'ڽالين',
  'کچندن',
  'ملايه',
  'سکرين',
  'باجيق',
  'ليمبن',
  'سلومو',
  'ترجڠک',
  'ڤموات',
  'روݢوق',
  'بنالو',
  'ڤنجڠن',
  'برلڤس',
  'سيا٢ڽ',
  'دڤالي',
  'تينيس',
  'ترتݢڠ',
  'کساسر',
  'داٴيق',
  'ددعوا',
  'ݢدايڠ',
  'برسيت',
  'منچڠڤ',
  'صلوات',
  'بڠکق٢',
  'مڠداو',
  'براٴي',
  'بونتل',
  'انسور',
  'کوموت',
  'برڤڠڤ',
  'لاڤيل',
  'ݢيميک',
  'للمبو',
  'کباين',
  'دوتا٢',
  'هالبن',
  'مڠچمي',
  'درنتس',
  'ڤيهيت',
  'سمباو',
  'اوروت',
  'قاعده',
  'افراد',
  'ڤلورو',
  'ݢوبير',
  'تتاڤي',
  'تونيل',
  'منسيت',
  'چاتيت',
  'ماچم٢',
  'ککبلن',
  'سناتو',
  'مڠاچڠ',
  'درنچه',
  'سمنته',
  'دلومس',
  'ݢرامڠ',
  'منجور',
  'لڠکيڠ',
  'بيلڠ٢',
  'ريبو٢',
  'کيلي٢',
  'کسڽڤن',
  'ستريڤ',
  'ڤماسق',
  'چاٴيق',
  'برکنل',
  'توڤوي',
  'تفاهة',
  'رنتوس',
  'کتياق',
  'ڤانچر',
  'رونتس',
  'مڠريت',
  'سخاوة',
  'کاتڠ٢',
  'ڤاکوس',
  'مڠانن',
  'ڤمتري',
  'مڽبله',
  'کمالا',
  'واليه',
  'جرنيه',
  'چوليڽ',
  'کوجوه',
  'ترݢلق',
  'کاليڠ',
  'دڤاڤس',
  'بنچيڽ',
  'اولير',
  'ݢلاده',
  'دالڠ٢',
  'ݢدݢوم',
  'سنياه',
  'برنتم',
  'راٴيس',
  'لڠݢان',
  'کنتوق',
  'کمڤوڠ',
  'دکادڠ',
  'سيکوت',
  'دسلا٢',
  'باکوق',
  'کتوقن',
  'سمڤيڠ',
  'بليرڠ',
  'ڤلاڠي',
  'ݢاٴيق',
  'مڠاني',
  'باجي٢',
  'کميلي',
  'بردݢم',
  'ڠيسيڠ',
  'مرنتڠ',
  'ڤموتن',
  'دسدوت',
  'سلوره',
  'ريچيڠ',
  'هرديق',
  'سلسلة',
  'برسام',
  'چلورن',
  'جيليد',
  'سوݢون',
  'دأرمي',
  'ملنجر',
  'سدولڠ',
  'چودوق',
  'مربوط',
  'سيڤدس',
  'ڤنيکم',
  'اوچيس',
  'ݢاݢاي',
  'ڠيجون',
  'سامبر',
  'دتوام',
  'موکمو',
  'مڠواڤ',
  'ڤاݢرڽ',
  'موزيک',
  'تاهو٢',
  'سجنام',
  'سمردو',
  'دڠدوت',
  'سراکن',
  'رومبا',
  'تامو٢',
  'داتين',
  'الامر',
  'برتݢڠ',
  'ممنچق',
  'ذوريت',
  'تبيڠ٢',
  'دحکوم',
  'بوکور',
  'ڤنتول',
  'سوديڤ',
  'برنکر',
  'رابوب',
  'رعايه',
  'ملاتت',
  'تڤوڠڽ',
  'توجهن',
  'هاروڠ',
  'جوڠوت',
  'لنتاي',
  'ڤاجڠن',
  'سڽمڤڠ',
  'سهالا',
  'بيلاو',
  'مڠرڽم',
  'ڤندسق',
  'تبوان',
  'دکارا',
  'تکوکق',
  'چڠکمن',
  'ڤونتل',
  'ۏينتج',
  'اجرکن',
  'ليندڤ',
  'هوڠݢر',
  'ڤمالو',
  'منياد',
  'کينجا',
  'داتوٴ',
  'دکبسڽ',
  'طلاقڽ',
  'هيبتڽ',
  'ماتيڽ',
  'ڤمݢڠڽ',
  'ماسم٢',
  'اندوه',
  'ليچيه',
  'برجين',
  'کاسيت',
  'رومڤڠ',
  'مڠادم',
  'برمات',
  'مڽڠݢم',
  'جمرلڠ',
  'نيمفا',
  'کولون',
  'منچݢه',
  'مسئله',
  'دڠکوڠ',
  'لاڤن٢',
  'سميره',
  'ددفتر',
  'کوڤيق',
  'سيوتن',
  'مننوڠ',
  'درجان',
  'برکسن',
  'راݢوم',
  'سکندر',
  'ݢريت٢',
  'ݢمبير',
  'مڠروه',
  'برنية',
  'چلاڠق',
  'ساولس',
  'ليوار',
  'ݢيݢيڤ',
  'دباوا',
  'ڤلاهڠ',
  'سربيت',
  'طوفان',
  'ترکلر',
  'موککو',
  'ڤلبور',
  'ڤورون',
  'اڠکوس',
  'ڤندرا',
  'سوديق',
  'ݢيواه',
  'دلاره',
  'ستوري',
  'بارڠ٢',
  'تمالي',
  'کاٴيل',
  'ڤڽديه',
  'مڠتمڽ',
  'مڠمسي',
  'ݢلاݢت',
  'کرݢڠن',
  'برقبة',
  'چڠݢيت',
  'مڠديه',
  'برچله',
  'دلوکو',
  'اوبهڽ',
  'ساڠيت',
  'مايوڠ',
  'ايڤيق',
  'تيدق٢',
  'چاديڠ',
  'چاروڤ',
  'بارون',
  'حسابڽ',
  'انسان',
  'ڤولڠ٢',
  'لنچيڠ',
  'دلاڤن',
  'سيديڠ',
  'مستمع',
  'ماٴوڠ',
  'ببيري',
  'بالوي',
  'چوندا',
  'لنداو',
  'کروتڽ',
  'سروين',
  'کتڤيل',
  'جنتنڽ',
  'ڤوݢاي',
  'واڠکو',
  'باتس٢',
  'تاڤقڽ',
  'جولاي',
  'لچوتن',
  'سهنتي',
  'معلوم',
  'سوندل',
  'سڤيت٢',
  'اخوان',
  'تلور٢',
  'چواني',
  'سکايا',
  'ݢمبوق',
  'ڤرتيݢ',
  'ستاهو',
  'ڤڠڤمن',
  'لطيفة',
  'مزمور',
  'ماکنن',
  'ڤمڠسا',
  'دتمبت',
  'صيفت٢',
  'ريواق',
  'دماکي',
  'دبواي',
  'لوڽاي',
  'راٴوم',
  'دبيوس',
  'دأدون',
  'لولوه',
  'دڤنجت',
  'دهالڠ',
  'هادڤي',
  'سراوق',
  'مرݢين',
  'داکون',
  'ڤلياس',
  'ساڠکر',
  'کتارا',
  'کاڤور',
  'سڠيلت',
  'سيرکم',
  'سليبر',
  'ڤولاو',
  'جمادت',
  'ايروڠ',
  'ڤنجول',
  'دکوبق',
  'کالوي',
  'باسوت',
  'مرياڠ',
  'ڤنمتڽ',
  'مڠݢلس',
  'دکتيس',
  'انچوا',
  'سلوکت',
  'مڠيسر',
  'جولڠن',
  'لوجيک',
  'ڤاکام',
  'سراما',
  'ليڤير',
  'سڤوکل',
  'کامبو',
  'تماهق',
  'جنجيڽ',
  'سيڠݢه',
  'رڠݢوڠ',
  'لونجق',
  'رواڠ٢',
  'امبوڠ',
  'دامڤق',
  'ݢلڤله',
  'چالاي',
  'روݢول',
  'منمڤي',
  'تناݢ٢',
  'لنترن',
  'مفسده',
  'چمرلڠ',
  'اصناف',
  'ساݢور',
  'سيواڽ',
  'ساداي',
  'سکوبڠ',
  'کاوسن',
  'ملبور',
  'ماهل٢',
  'تواکو',
  'ڤتونڠ',
  'چاچين',
  'تيروڠ',
  'بيبسڽ',
  'رادا٢',
  'برقدر',
  'کاکيس',
  'ڤمکڤن',
  'تڠکور',
  'ڤالوڠ',
  'ابدية',
  'براڽم',
  'کلواق',
  'سرمبه',
  'اوچڤن',
  'داناو',
  'نذرکن',
  'کرمڠن',
  'سسدوت',
  'تينجق',
  'مڠاڤن',
  'لامرن',
  'سالون',
  'بيڠکو',
  'منچيل',
  'الماس',
  'لارڠن',
  'دسڠک٢',
  'نݢارا',
  'منروک',
  'مڠيسق',
  'ڤرسڠݢ',
  'سدياڠ',
  'کيريڽ',
  'کاٴوس',
  'جمبول',
  'ڤيچيس',
  'برباڤ',
  'بوبوق',
  'لابوه',
  'ڤماچق',
  'ݢرودي',
  'ڤلاله',
  'مڠرتڤ',
  'رنجاو',
  'ݢيݢيت',
  'تيتير',
  'مماچو',
  'تواين',
  'ترسمت',
  'مريتم',
  'معزول',
  'مڠڠکت',
  'کچيچق',
  'برذات',
  'کوتوڠ',
  'جڠکهن',
  'ڤوکوق',
  'ݢودو٢',
  'بواتن',
  'تيڠکت',
  'کراما',
  'داولڠ',
  'دربوت',
  'ڤڠبوت',
  'مميال',
  'وازير',
  'لمبيݢ',
  'ڠرياڤ',
  'تيمڤا',
  'سمربق',
  'بوات٢',
  'اوڠوق',
  'مالڠڽ',
  'رباطة',
  'اسراء',
  'سمربق',
  'مسيره',
  'ݢاجهڽ',
  'برتکق',
  'دونچڠ',
  'کيسوت',
  'ملمبق',
  'لابون',
  'دکيبس',
  'جرمبه',
  'ديديس',
  'مندرا',
  'دبرن٢',
  'ايمڤي',
  'سلمون',
  'کدادق',
  'سيڠيت',
  'چاڤول',
  'سڤرتي',
  'نورما',
  'ڤينيس',
  'دوسين',
  'قرءان',
  'زيتون',
  'انياي',
  'باچکن',
  'ريميت',
  'مجله٢',
  'ڤڠکت٢',
  'ستاتر',
  'بينتن',
  'چيڠاو',
  'مليڽق',
  'ڤلوڤا',
  'نوۏيس',
  'ليڽاو',
  'ڤاليت',
  'بالوت',
  'توکيه',
  'ڤيلاو',
  'سيترت',
  'ڤنربڠ',
  'دکاچق',
  'تايکو',
  'ڤنچور',
  'مرامل',
  'دݢڠݢو',
  'اخلاق',
  'رمڤان',
  'بانچي',
  'ويسات',
  'کيتيڠ',
  'سايسي',
  'مڠچڤي',
  'مڠانق',
  'ملاون',
  'حياتي',
  'دسربو',
  'سيݢمن',
  'سنجوڠ',
  'کارڠ٢',
  'قبيله',
  'بردتس',
  'چادوق',
  'کراما',
  'سمبور',
  'ڤتران',
  'مالوڽ',
  'کدادا',
  'ايسي٢',
  'بيليق',
  'برتنڠ',
  'اندون',
  'ڤانس٢',
  'راݢوت',
  'کاڤول',
  'ڤوڠيا',
  'سکورس',
  'ڤنججق',
  'اعراب',
  'سمومه',
  'برانق',
  'تيڠݢڠ',
  'ڤڠارق',
  'سولور',
  'لاترل',
  'بردڠک',
  'ريمڤڠ',
  'ݢادوڠ',
  'برجاݢ',
  'دکمبڠ',
  'دتمني',
  'جوٴيق',
  'الوصي',
  'ݢولوق',
  'سدرکن',
  'کانين',
  'ملايڤ',
  'سبولو',
  'کسݢنن',
  'دساݢر',
  'روباي',
  'سڽومڽ',
  'اوبور',
  'انتيک',
  'ستڠݢي',
  'سندي٢',
  'جاريڽ',
  'ستوکر',
  'سچمچا',
  'برݢنڠ',
  'ڤناسق',
  'دتاتڠ',
  'داصول',
  'مڽاسڤ',
  'وصيت٢',
  'موميا',
  'سلايق',
  'کينجت',
  'نيونت',
  'ݢلودر',
  'دامڤڠ',
  'مڠاچر',
  'رمڤوق',
  'لابور',
  'روکون',
  'ڤڽمبه',
  'ۏولۏا',
  'داتوڠ',
  'ڤيسوڠ',
  'تݢورن',
  'ݢوريه',
  'ݢمبوس',
  'دعامي',
  'ڤملسو',
  'ݢاريس',
  'سلاڠا',
  'بمبرن',
  'ڤينتر',
  'دهارڤ',
  'کروسڠ',
  'برلاو',
  'سڤرما',
  'ماتڠڽ',
  'تذکرة',
  'بنيهڽ',
  'مڠادڠ',
  'ڤيلوݢ',
  'امتله',
  'سبورا',
  'سمودا',
  'ݢنتري',
  'مروال',
  'باݢاي',
  'چاتوه',
  'بڠکيه',
  'ايريڽ',
  'لوارن',
  'چاکڤڽ',
  'دسياه',
  'ڤيالا',
  'برحسد',
  'ريتيق',
  'رووين',
  'کنيني',
  'سرڠکڤ',
  'سيارة',
  'کلچڤن',
  'سيلو٢',
  'چيندا',
  'دڤريس',
  'سڤارو',
  'تابر٢',
  'اسناد',
  'منݢور',
  'سکندل',
  'بولت٢',
  'چڠݢيه',
  'ورونا',
  'برجاس',
  'براسڤ',
  'فرقان',
  'ڤوجوق',
  'دويلي',
  'مڽويق',
  'مڽوݢي',
  'ليتار',
  'يوݢيا',
  'کدرسن',
  'مڽدوت',
  'تيبا٢',
  'ݢرواي',
  'مدالي',
  'ڤليقن',
  'امڤيل',
  'سجورن',
  'چچيچق',
  'مڠهڽق',
  'سرنتا',
  'بوڠکر',
  'چانيس',
  'ممانه',
  'بيديق',
  'کارڠن',
  'ترسبب',
  'ۏودکا',
  'منلور',
  'کسرمن',
  'ݢليوڠ',
  'سڠکول',
  'بوندق',
  'دأنجق',
  'سݢاري',
  'کيچر٢',
  'استاذ',
  'کرتون',
  'معراج',
  'کلاور',
  'مرهوم',
  'ݢنجلن',
  'مخدوم',
  'سکرتڽ',
  'ملارت',
  'حروف٢',
  'عاٴيب',
  'سواڠي',
  'دسيتا',
  'چورهن',
  'ڤناکن',
  'فيشنڽ',
  'ممنجڠ',
  'ايهيم',
  'دأويت',
  'رابطة',
  'ڤرسݢي',
  'سچاون',
  'توبيس',
  'نوريه',
  'تملوڠ',
  'بونتق',
  'اوروق',
  'بياسن',
  'دبواي',
  'سکڤول',
  'ممڤرا',
  'کمڤڽى',
  'کليتي',
  'لولوڠ',
  'ڤلمهن',
  'ڤيروت',
  'بابون',
  'براکر',
  'چنتول',
  'ڤاسقڽ',
  'ترکدو',
  'مسجد٢',
  'اوراي',
  'هيدرت',
  'بالست',
  'سبياق',
  'ببوتا',
  'اڠݢوڤ',
  'دومکا',
  'بيلڠڽ',
  'ڤاکاي',
  'چاناي',
  'چيميق',
  'چنتوم',
  'مڠکيڠ',
  'مڠتيڤ',
  'ڤانير',
  'مماڤن',
  'روندو',
  'دتاڽي',
  'کاٴوق',
  'بلاچو',
  'مروام',
  'ستيمن',
  'مندڤن',
  'مرتبت',
  'برکيل',
  'ڤقسيڽ',
  'امڠکو',
  'مڽوݢر',
  'سلمڤڠ',
  'ڤاکتن',
  'الونن',
  'ساڤوت',
  'اسرار',
  'برندڠ',
  'دسلڠي',
  'حبيبي',
  'اونست',
  'سوتوڠ',
  'ڤرلوس',
  'مينتا',
  'روتوق',
  'سجوتا',
  'تاهڤڽ',
  'هاڽير',
  'هيڠݢڤ',
  'الوسي',
  'کجڠکڠ',
  'روجول',
  'سنسخه',
  'مرڠݢق',
  'روڤول',
  'دجروق',
  'جيڠݢڠ',
  'توريه',
  'کدوبڠ',
  'چڤوري',
  'ڤيچيق',
  'نايرا',
  'ميساي',
  'ݢراݢس',
  'هاميس',
  'سيناو',
  'ݢاندڠ',
  'قراءة',
  'ماليس',
  'چيبيت',
  'ڽيريه',
  'ملنتن',
  'مڠاچق',
  'مڠوݢه',
  'ڤيکيت',
  'ڤتوڤن',
  'ڤرنچه',
  'چياڤ٢',
  'مناڤق',
  'جلاڤڠ',
  'بوبول',
  'دندمڽ',
  'تياڠ٢',
  'سقسيڽ',
  'کرديڤ',
  'برڤݢڠ',
  'ڤنتوڠ',
  'ويلسن',
  'بروما',
  'ڤڠتوق',
  'مڠاچن',
  'ڤمڤڠن',
  'ڤستيل',
  'تالا٢',
  'رمڤلو',
  'جڠکيه',
  'ڤداݢڠ',
  'چرنچڠ',
  'اڠݢرک',
  'مليڤق',
  'دبيلڠ',
  'منروس',
  'ترهرݢ',
  'ڤڤوجو',
  'تاجم٢',
  'اسوه٢',
  'کليرو',
  'تيڠݢل',
  'چنتوڠ',
  'سونتي',
  'دمادو',
  'کدکوت',
  'دروتن',
  'ريندو',
  'سهاڠت',
  'کريوق',
  'مڽڤوق',
  'تاڤير',
  'برسيت',
  'ساندي',
  'لمباو',
  'ڤرومه',
  'کلنوڠ',
  'دسليا',
  'دبينا',
  'کلوهن',
  'ݢليبت',
  'عملمو',
  'کوجوت',
  'برترڤ',
  'سمبرڤ',
  'برختم',
  'ڤڠيسر',
  'رومڤي',
  'ميڤيس',
  'لاترڽ',
  'دسيمه',
  'ڤڽوچي',
  'اݢيڠ٢',
  'عمليه',
  'ڤڽيرم',
  'دايلق',
  'هياسي',
  'چندوڽ',
  'همڤوت',
  'درڠسڠ',
  'برحجه',
  'کچيوا',
  'مماسڤ',
  'جمالا',
  'ترڤݢن',
  'شوݢون',
  'ميتوس',
  'نسخه٢',
  'برسسق',
  'ديندڠ',
  'ريمڤت',
  'ݢيݢير',
  'هالاي',
  'ڤولوق',
  'مروبه',
  'تواها',
  'بلوتا',
  'سسوده',
  'امڤدل',
  'بݢاون',
  'ڤمينڠ',
  'ڤرمڤڠ',
  'مڽوکت',
  'داڤکن',
  'تتوان',
  'دتيوڤ',
  'ميريڠ',
  'ڤرڤڠن',
  'تروسي',
  'لونچت',
  'کلوق٢',
  'مڠݢلر',
  'ڤراڤي',
  'دݢنجر',
  'دسيڤت',
  'درسڤي',
  'کوتوڽ',
  'لاڠکڤ',
  'دتوڤڠ',
  'ڽاروم',
  'توکيق',
  'چيلين',
  'سکتول',
  'ڤنصبن',
  'دݢوݢت',
  'انجوت',
  'دسيرڠ',
  'قناعه',
  'ڤاتوت',
  'دتوکر',
  'ڤلامر',
  'کومبو',
  'کوطنن',
  'سکنچڠ',
  'دبريڽ',
  'ايݢرڠ',
  'ترجڠه',
  'ليسين',
  'مماݢس',
  'ڤيچيت',
  'سنتير',
  'برسکت',
  'کاودل',
  'ڤليڠه',
  'بنومن',
  'سراسي',
  'مسکنة',
  'رواڠن',
  'ستوجو',
  'دبيݢا',
  'مرڠسي',
  'رونتي',
  'هيلاو',
  'باتاو',
  'ماهير',
  'ترکجي',
  'بيلڠ٢',
  'ياکيس',
  'سنتيڠ',
  'کوتڠڽ',
  'دتبڠي',
  'ڤبيان',
  'تايرو',
  'کسڠسڠ',
  'برنحو',
  'بوريا',
  'هيدرا',
  'توݢسڽ',
  'ڤاچرڽ',
  'مثالي',
  'اناني',
  'دڠکوس',
  'ايدام',
  'برزيڤ',
  'دفهمي',
  'دکتيل',
  'چوتوق',
  'لوݢيک',
  'ڤڽينر',
  'جوجور',
  'جهارو',
  'چراچم',
  'استان',
  'کڤالا',
  'دکوکڠ',
  'جينيڠ',
  'دبورو',
  'کيال٢',
  'برابڠ',
  'کيدا٢',
  'ريسيق',
  'تمبوق',
  'ليبور',
  'تڠݢرن',
  'کومبر',
  'ڤسمين',
  'ڤندڠن',
  'تاٴيس',
  'ملالر',
  'لاريڽ',
  'ڤمکرن',
  'ڤرودي',
  'هامون',
  'رودا٢',
  'دسرݢه',
  'ڤوالم',
  'ݢينکو',
  'ڤلاله',
  'تتيره',
  'ڤرواه',
  'مڠمبڠ',
  'کلچهن',
  'ݢݢسيڠ',
  'برݢتر',
  'ڤناتو',
  'سليما',
  'ساݢاي',
  'اولوڠ',
  'ڤڠتيڤ',
  'ايمري',
  'دکاڤق',
  'دچرنا',
  'نيتيس',
  'قوات٢',
  'سيناي',
  'ستوجو',
  'کرياو',
  'مرجمڽ',
  'مڠاچو',
  'کورسي',
  'تربول',
  'منچکا',
  'سکيان',
  'کوتري',
  'خصوسڽ',
  'کمڤلڠ',
  'برتکن',
  'مرچيق',
  'بوجور',
  'انچمن',
  'لوتوڠ',
  'تومبق',
  'لمبور',
  'دتوجه',
  'مموته',
  'دتيق٢',
  'برببر',
  'ايکور',
  'کولوه',
  'سچياڤ',
  'ترادڠ',
  'برتول',
  'دويوڠ',
  'چاڤلق',
  'کاتيس',
  'منافق',
  'ڤنيوڤ',
  'کسڠسم',
  'مڽيات',
  'ڤنتيق',
  'تاݢير',
  'رنجيس',
  'بنقکو',
  'واهان',
  'ݢومبق',
  'دݢنڠي',
  'ڤڠجين',
  'ڤمديه',
  'برطرف',
  'برتکن',
  'ڤمالس',
  'مڠروق',
  'ستڠه٢',
  'مڠارق',
  'کوچيل',
  'جرمڤق',
  'دهندڤ',
  'چيلاڽ',
  'بوريڠ',
  'ڤيتيق',
  'ساتين',
  'امبوه',
  'توڤيک',
  'سجاجر',
  'فيتور',
  'چامور',
  'ݢندڠ٢',
  'اوسها',
  'برچکق',
  'بروسق',
  'درماݢ',
  'چيلوق',
  'ايريه',
  'ڤڠعمل',
  'ݢمبول',
  'کسوسو',
  'بوڽي٢',
  'انجوت',
  'منرجڠ',
  'دسيکو',
  'سڤاسو',
  'دبوسر',
  'سورچج',
  'لايرن',
  'امڠکو',
  'توکاي',
  'مڽمبه',
  'الضار',
  'سأمان',
  'رونوت',
  'تيکسي',
  'ممنجا',
  'اوڠسي',
  'کلوبي',
  'سيليڠ',
  'مڽروت',
  'ڽاموق',
  'ريواڠ',
  'ڤخدمت',
  'لاڤون',
  'کنچوت',
  'اشارت',
  'اونيڠ',
  'حواٴي',
  'مڽولڤ',
  'لبارن',
  'تيتير',
  'کاچڠ٢',
  'وکالة',
  'سقتيڽ',
  'شيليڠ',
  'باجاو',
  'ممولس',
  'ڤڠڤوڠ',
  'امبقن',
  'اوبون',
  'ونارا',
  'ليڠسڠ',
  'ݢݢنتي',
  'ݢندوڠ',
  'چڠکاو',
  'کيران',
  'تلوتق',
  'باتو٢',
  'جارية',
  'هاتي٢',
  'منجله',
  'ڤاوان',
  'چنچڠن',
  'ڤوريس',
  'سليرت',
  'تورسي',
  'ايسو٢',
  'توروس',
  'دأوجا',
  'برواڤ',
  'اوميل',
  'مڠوبر',
  'ڤدوکڠ',
  'تاٴول',
  'کرڤوق',
  'جيجيه',
  'تريبا',
  'مڠتيڠ',
  'ڤنايڠ',
  'ڤراهو',
  'تعزيه',
  'ڤرڠݢن',
  'بوڠکق',
  'تمبتن',
  'دتاجا',
  'ممڠيت',
  'ڤادڠڽ',
  'مليات',
  'دباݢي',
  'بيارڽ',
  'ملاهو',
  'باکوه',
  'سمبور',
  'مردوت',
  'دوبول',
  'چناکو',
  'جلواق',
  'جنترا',
  'رڤتير',
  'سڤاتي',
  'سݢنڤڽ',
  'ڤارقڽ',
  'سيروق',
  'ترانق',
  'تنوڠن',
  'مڽرکڤ',
  'محاجة',
  'مرويق',
  'کاريڠ',
  'سابوت',
  'لتوڤ٢',
  'سراوس',
  'تدبير',
  'بنتيق',
  'دچودق',
  'کوالي',
  'ساڠير',
  'کوڤيت',
  'دأوبه',
  'قريبڽ',
  'فولوس',
  'لاوق٢',
  'مڠمبن',
  'ݢمڤڠن',
  'ڤݢوام',
  'بيندم',
  'جالين',
  'مماهت',
  'کومڤق',
  'اصحاب',
  'ميريت',
  'شنتوڠ',
  'دکيبس',
  'ددارو',
  'منمڤت',
  'اينيڠ',
  'کاولن',
  'تاباي',
  'چابرن',
  'دکنلڽ',
  'مڠڽوت',
  'ممياڠ',
  'چيتيق',
  'سيڠکت',
  'برسڠک',
  'کمڤوه',
  'برسسل',
  'براد٢',
  'بهادي',
  'کوٴيل',
  'لولوه',
  'چلاٴن',
  'چوتيق',
  'ݢليتر',
  'رمڤسن',
  'برتݢه',
  'دبريک',
  'اوستا',
  'اينچر',
  'اساس٢',
  'ڤماجو',
  'کنديت',
  'برکتق',
  'چڠلوق',
  'مناکل',
  'جوروق',
  'تيندر',
  'برکوس',
  'ݢوڤوڠ',
  'چوبيق',
  'جليتي',
  'فاسيت',
  'بندلم',
  'ڤرکيت',
  'کتوڤ٢',
  'روݢيڽ',
  'ڤولڠن',
  'مملکة',
  'دلايڠ',
  'تيڤيه',
  'داورڤ',
  'ديديه',
  'چلاڠڤ',
  'ڤاسرن',
  'دڤتيق',
  'طائفة',
  'ڤيتيس',
  'ݢوڽيه',
  'روڠݢڠ',
  'ستادا',
  'مروڤا',
  'مڠاکو',
  'ڤيسقڽ',
  'بوجوق',
  'بتوتو',
  'لوداي',
  'ليموت',
  'بيجي٢',
  'بوچوڠ',
  'سوقما',
  'کوميس',
  'لومره',
  'ريحتڽ',
  'دبورن',
  'مرسڤي',
  'چبليق',
  'ڤرنچه',
  'ممرتل',
  'جوڠکر',
  'الامق',
  'کينچا',
  'لوموس',
  'سيريف',
  'تنوڠڽ',
  'باراو',
  'کرتيق',
  'منهاج',
  'براني',
  'ڤيهق٢',
  'تيارا',
  'کوساو',
  'لڠسوڠ',
  'سموره',
  'مڽابت',
  'ݢوروق',
  'دوݢير',
  'مڠمبق',
  'اينتي',
  'اچوان',
  'تلورڽ',
  'مڤاݢر',
  'اݢوت٢',
  'ڤاديڽ',
  'جيريڠ',
  'تيريس',
  'موهون',
  'ݢوڤوه',
  'سݢراڽ',
  'باداو',
  'کڤدکو',
  'دهوني',
  'شريکت',
  'برودي',
  'کيندو',
  'جيمبم',
  'دڤربن',
  'ڤڠجوت',
  'امڤوت',
  'منݢون',
  'ملواس',
  'نباتي',
  'ڤاتين',
  'لاڠاو',
  'ݢراسي',
  'ݢرابق',
  'کودڤ٢',
  'منلنڽ',
  'سداکڠ',
  'هکسان',
  'کرموت',
  'چاليت',
  'مينرل',
  'کألهن',
  'کميجا',
  'ترچڤت',
  'سيڤير',
  'راچاو',
  'درمڤا',
  'دڤوکت',
  'رياسن',
  'مڽلڤي',
  'دݢرتق',
  'مالمڽ',
  'ڤلمڤڤ',
  'دتمبق',
  'تيارڤ',
  'کيروه',
  'تيقڤو',
  'تيبنن',
  'اوروس',
  'ممفسخ',
  'کمبوق',
  'اجوقن',
  'ݢالاو',
  'دتاده',
  'تتمڤن',
  'لواهن',
  'برچڠڠ',
  'توتيت',
  'ساکيڠ',
  'سندوڠ',
  'مڠارڠ',
  'تيندس',
  'راسيس',
  'مڠاله',
  'بليبت',
  'اهليڽ',
  'کلمه٢',
  'کامبڠ',
  'مڠڤيل',
  'تراڤي',
  'سبليت',
  'ککرڤن',
  'هنتمن',
  'مغروم',
  'ݢݢابه',
  'النبي',
  'سنتلن',
  'مڽمبت',
  'ميجاڽ',
  'دچاچق',
  'مڠلوس',
  'ملاݢق',
  'ڤمورس',
  'چالوس',
  'ڤنورڤ',
  'جاموڠ',
  'کوٴيس',
  'مڠوبت',
  'جلسله',
  'امبيل',
  'امبون',
  'سکواڠ',
  'تليرڠ',
  'کواهڽ',
  'ڤوساک',
  'تونجم',
  'ڤونچي',
  'مريمن',
  'امبيق',
  'کواس٢',
  'توڠسن',
  'مڠواق',
  'ڤلمبڤ',
  'کولکس',
  'عالمڽ',
  'بلاون',
  'برادق',
  'سوارا',
  'تمبق٢',
  'بردسق',
  'تواسه',
  'دوڠسا',
  'ليݢيه',
  'ايمون',
  'ݢوبوق',
  'دأتسي',
  'موڠيل',
  'لاٴوڠ',
  'سيستا',
  'کاٴيت',
  'نمڤقڽ',
  'تينڠ٢',
  'کاياڽ',
  'برسست',
  'کولين',
  'بروتن',
  'دبورا',
  'رامين',
  'سيݢيل',
  'بوليه',
  'کومڤر',
  'جوروڠ',
  'مڠوبق',
  'برسرڠ',
  'لنجاي',
  'سيتير',
  'قطيفه',
  'سواڤ٢',
  'ڤرتاي',
  'مجيکن',
  'دکارڠ',
  'مڠکشف',
  'مڠݢتڤ',
  'سوميڠ',
  'ملچوس',
  'ادريس',
  'جيمڤس',
  'لڠوه٢',
  'ݢرنتم',
  'عبارت',
  'سوکو٢',
  'کلوتق',
  'سمبهڽ',
  'ججاوي',
  'کدکتن',
  'کبونڽ',
  'سڠيتڽ',
  'ڤرليڤ',
  'تاڤوس',
  'بربور',
  'مڽدوه',
  'ڤرݢول',
  'رلوڠن',
  'ترکول',
  'مرمڤڠ',
  'ساٴوڠ',
  'بيکين',
  'هاڽوت',
  'مايسن',
  'دهيسڤ',
  'سسيکو',
  'مليمن',
  'ڤلڤسن',
  'چادڠن',
  'چريچڤ',
  'ڤاݢوت',
  'بوچوق',
  'کوريس',
  'کوچيق',
  'سيفون',
  'ڤننتڠ',
  'دجمور',
  'سيڤقن',
  'عماره',
  'ݢساٴن',
  'چودوت',
  'دچروت',
  'دراوت',
  'ڤڽوسو',
  'ڤيچوڽ',
  'بيرو٢',
  'مربوق',
  'دبيلا',
  'ايمان',
  'تاهڤن',
  'مڠݢته',
  'کاريس',
  'لاکي٢',
  'ماسيه',
  'اهدية',
  'دهيلا',
  'انتر٢',
  'ترسلق',
  'امراء',
  'ليسول',
  'ڤينجم',
  'تخليق',
  'اورݢي',
  'ݢدوبڠ',
  'تاهني',
  'ڤڽاتو',
  'هيدڠن',
  'مشکيل',
  'درڠسڠ',
  'ݢاباي',
  'لاٴوه',
  'ڤوليس',
  'جموجو',
  'مڠتين',
  'دکموت',
  'مميهق',
  'رونجڠ',
  'سرودي',
  'تکبير',
  'ڤسيکو',
  'دتندا',
  'قبور٢',
  'امبيق',
  'موککو',
  'کالوس',
  'تاديڽ',
  'مڠدوق',
  'ليهير',
  'ممڤات',
  'برواج',
  'بياوس',
  'ملورو',
  'کينين',
  'هوبتن',
  'اوندا',
  'کڤسول',
  'مرمبڠ',
  'ݢمبوس',
  'ڤڠيرا',
  'دجرکه',
  'بروڠݢ',
  'رمبوق',
  'ڤنداو',
  'ڤمچوت',
  'مناري',
  'عملنڽ',
  'يميوه',
  'موالة',
  'بمبوت',
  'توقيع',
  'اطلاق',
  'چيموڠ',
  'سبولت',
  'ملاوس',
  'چنايڠ',
  'برمبڠ',
  'مناهن',
  'تونيم',
  'کستيل',
  'چورمڽ',
  'بهايڽ',
  'مڠݢاي',
  'دسڤوه',
  'برلڤس',
  'سلندڤ',
  'جاکيت',
  'دتلوس',
  'ݢلنتڠ',
  'تتڤله',
  'کولوت',
  'مراجڠ',
  'مڠيري',
  'برجڠک',
  'کلادي',
  'رسولي',
  'لولور',
  'ککاڤل',
  'برعزم',
  'ميدن٢',
  'دسولم',
  'ملنچر',
  'ترچرڤ',
  'ستافج',
  'ݢوستي',
  'مڽالق',
  'مثقال',
  'تارهن',
  'سورهي',
  'مڽسلي',
  'لراين',
  'اوڠݢل',
  'کلبوق',
  'کلندر',
  'ڤاجقن',
  'مڠودا',
  'مڽڤيل',
  'للايڠ',
  'باݢوس',
  'منتيک',
  'دکدکن',
  'مندرف',
  'کمنچر',
  'مڽرڤي',
  'تاريڽ',
  'ليندس',
  'اوزون',
  'سيندڤ',
  'بڠساي',
  'کومڤل',
  'سيڠسي',
  'اوويت',
  'کوتوق',
  'رڠݢيت',
  'اوتوه',
  'زيݢوت',
  'ݢوندر',
  'جمبتن',
  'چمتوق',
  'دجواق',
  'مڽاوه',
  'طبيعة',
  'ڤڠرمن',
  'مريام',
  'سمولا',
  'تندوس',
  'ڤکرما',
  'سورسق',
  'دقياس',
  'برزيڤ',
  'للانڠ',
  'کروڤس',
  'مرڤوه',
  'سامين',
  'تيکنن',
  'ڤادتن',
  'دڤادن',
  'کولون',
  'فيکشن',
  'دتوݢن',
  'دکريق',
  'بينتت',
  'کرڤوس',
  'کوريت',
  'دربوس',
  'ڤمنتق',
  'کرکوڤ',
  'باݢوه',
  'مردوق',
  'ڤاچيس',
  'سڠݢيت',
  'فيوس٢',
  'رڠکول',
  'مردڠݢ',
  'اووقن',
  'ڤڽالا',
  'چنتيڠ',
  'ڤمودي',
  'ڽاتوه',
  'سوهور',
  'دکويق',
  'ايچيق',
  'سفيرا',
  'چوريق',
  'کلڠکڠ',
  'براکل',
  'دتيرو',
  'دڠنکو',
  'بتارا',
  'ڤسيرو',
  'اوندي',
  'ککندا',
  'اڠهون',
  'تقديس',
  'مندکڤ',
  'دتاسق',
  'سساعت',
  'سکڤيڠ',
  'کيجاي',
  'بيسوق',
  'بيريک',
  'برنتا',
  'کوروق',
  'کفارة',
  'منتڤي',
  'ڤناڠس',
  'کتتڤن',
  'دسڤيت',
  'رومهڽ',
  'ملچور',
  'کوميت',
  'بناري',
  'ڤراوي',
  'ليکير',
  'بادوق',
  'اولڠ٢',
  'تمڤتڽ',
  'بيکير',
  'الور٢',
  'بلستر',
  'ليوق٢',
  'رنجان',
  'ݢڠݢمن',
  'ايمڤک',
  'داٴيڠ',
  'اڠساڠ',
  'مايور',
  'کوليه',
  'منچلت',
  'منڤوڠ',
  'معشوق',
  'ڤڠڤيت',
  'کايون',
  'مڠدور',
  'کرامس',
  'داناي',
  'مشومي',
  'تنديق',
  'ݢراها',
  'ککنلن',
  'ڤراڤت',
  'تتواڠ',
  'کوندي',
  'مڠاسم',
  'ڤرمان',
  'ڤرواق',
  'دݢاجي',
  'دسيوا',
  'ݢتيلن',
  'مبرور',
  'مماکو',
  'ديباج',
  'ڤنومه',
  'جوݢيت',
  'ڤوترا',
  'سايڤڽ',
  'مڽدري',
  'تلينق',
  'لوڠلڠ',
  'سريره',
  'باکوت',
  'اوجين',
  'اوچي٢',
  'تيراي',
  'مرڽوت',
  'سناڤڠ',
  'ستودي',
  'قبورڽ',
  'سجمبر',
  'ݢاٴيت',
  'برݢاي',
  'ݢومڤل',
  'باڤکو',
  'بودوڠ',
  'دسيرڤ',
  'ملاجق',
  'اسوهن',
  'ڤاتيه',
  'اڤريل',
  'سامير',
  'دتمڤڠ',
  'بڠبوڠ',
  'ڤوميس',
  'لنچر٢',
  'مڽکرو',
  'ليکو٢',
  'ملاوا',
  'برفهم',
  'بياسا',
  'ممبلن',
  'سڠلور',
  'انديڠ',
  'چڤاتو',
  'درامس',
  'هالڠن',
  'باݢوق',
  'توکسن',
  'مناجي',
  'ساٴين',
  'مماݢڠ',
  'هجرهڽ',
  'ملکيت',
  'مليرڠ',
  'حکوم٢',
  'دبالس',
  'ݢندرن',
  'فاريڠ',
  'ڤلوقڽ',
  'اليڠن',
  'هيناڽ',
  'دݢنجل',
  'خلاسي',
  'کتڠݢه',
  'مملڤه',
  'کوروڤ',
  'چيچيڠ',
  'مابور',
  'سولاي',
  'برهاب',
  'دجليڠ',
  'سلاما',
  'مڽوار',
  'کاسرڽ',
  'چاراڽ',
  'جمودي',
  'سلسما',
  'بمبڠن',
  'دجاوت',
  'برلاو',
  'ترکلو',
  'سيواي',
  'مرندا',
  'سادور',
  'بلوهن',
  'دبديل',
  'کلاوت',
  'ڤنجرڤ',
  'اولوق',
  'نوتاڽ',
  'تيريڠ',
  'ايبوس',
  'باݢيڽ',
  'سبيجي',
  'باچله',
  'ستݢوق',
  'برسيل',
  'ڤڠريه',
  'باروس',
  'واداي',
  'سيسيڤ',
  'دڤيکت',
  'ڤراڽق',
  'مڠاچر',
  'کنتلن',
  'تريکو',
  'مڽرڤي',
  'برماي',
  'تراکو',
  'تيريڽ',
  'دليڤت',
  'تريتي',
  'تونجڠ',
  'ݢولبي',
  'برتيه',
  'ملڠوڠ',
  'بيري٢',
  'ڤوڤوس',
  'سليرا',
  'جمڤول',
  'سارڠن',
  'ماسير',
  'مراݢم',
  'لاڠي٢',
  'اختاج',
  'ليديڽ',
  'براڠن',
  'نيندا',
  'چاروه',
  'چرليه',
  'سڽومن',
  'رنتاو',
  'ݢلوره',
  'ڤنارا',
  'مڠودق',
  'ملبير',
  'ترولق',
  'دسڤيت',
  'تتولڠ',
  'مڠارن',
  'مماکم',
  'مڠووق',
  'اينجق',
  'جليڠن',
  'ڤڤورا',
  'ڤينتق',
  'تݢادس',
  'سلارس',
  'ڤريان',
  'امبين',
  'ترسکڠ',
  'خلافة',
  'درندڠ',
  'ستڠکس',
  'چلومڠ',
  'سينجڠ',
  'اولق٢',
  'توبوه',
  'کرنچڠ',
  'دنسيڠ',
  'اوڠکو',
  'چرماي',
  'کمبڠن',
  'لاناو',
  'کوالت',
  'سستله',
  'اوندق',
  'سوفيس',
  'اولسڽ',
  'مڠچوت',
  'دکاسي',
  'سڤريڠ',
  'باديق',
  'هيريڠ',
  'مڽاڠت',
  'کرامت',
  'املݢم',
  'اورين',
  'برجرم',
  'سکيلڤ',
  'ݢيسيڠ',
  'دوتاڽ',
  'راتڤن',
  'کرڠکڠ',
  'ساڠکق',
  'ريکتو',
  'توتور',
  'درمڤت',
  'مناته',
  'تمڤتڽ',
  'ڤڠريڤ',
  'چيڤاي',
  'سأيبو',
  'ڤنڠݢه',
  'راݢين',
  'ݢالون',
  'تبراو',
  'سکيلت',
  'چاٴوڠ',
  'بسارن',
  'کلادن',
  'بادنڽ',
  'سريڠن',
  'ڤمودا',
  'سرنتق',
  'کتݢڤن',
  'برسني',
  'دچوره',
  'تيکاي',
  'ڤاڤول',
  'بريتا',
  'حيران',
  'رنچوڠ',
  'چلوتق',
  'چوچوق',
  'باقيڽ',
  'مناتڠ',
  'ڤناتڤ',
  'اوڠکڤ',
  'چڤچوڤ',
  'تامڤق',
  'دکاجي',
  'ايمبس',
  'برسوا',
  'رامبڠ',
  'باجول',
  'مڠاڠا',
  'موليا',
  'نونيت',
  'دڤاجڠ',
  'واڠين',
  'ترکنن',
  'تراجو',
  'ڤندية',
  'شعيري',
  'رڤيهن',
  'لومبو',
  'فلکون',
  'لاڤيق',
  'مڽوڽي',
  'انرکي',
  'سرمڤق',
  'ڤوڠکس',
  'سماين',
  'بلوکو',
  'ارهمو',
  'دجاجه',
  'برلله',
  'تجاوز',
  'مڠورق',
  'برقطب',
  'ڤرتوا',
  'ݢليوت',
  'ليبر٢',
  'سيالڠ',
  'مڠوله',
  'امڤدو',
  'ماواي',
  'بندوڠ',
  'ڤاروڠ',
  'باروق',
  'اوله٢',
  'کوبرن',
  'ايڠکق',
  'باکيم',
  'سکاسڤ',
  'ڤڠالس',
  'کوروس',
  'کسومت',
  'ڤلکتن',
  'کجلسن',
  'مڽليت',
  'بوفيت',
  'چايوس',
  'بوکوڽ',
  'سڠݢوق',
  'دريسر',
  'بيليت',
  'کوچيڠ',
  'محفوز',
  'ڤڠيلڠ',
  'ݢاواي',
  'ڤروسي',
  'رايون',
  'سݢندڠ',
  'سيريق',
  'دڤنکو',
  'مڠکرڠ',
  'اومڤن',
  'مودا٢',
  'دسيات',
  'ڤنمڤا',
  'سڠکرن',
  'ڤاچاو',
  'کابو٢',
  'ممنڠي',
  'لنتين',
  'ڽڽينڽ',
  'بيولا',
  'جيراي',
  'کوکاي',
  'برتکن',
  'درڠکڤ',
  'ليناو',
  'داتڠي',
  'سريات',
  'مجتمع',
  'ڤلتوس',
  'تنترم',
  'ترخاس',
  'سستڠه',
  'کداره',
  'تمدون',
  'برطرف',
  'براݢق',
  'دچسکن',
  'رنچيق',
  'کرچاو',
  'جرامي',
  'نسچاي',
  'کوڠکڠ',
  'تاميڠ',
  'سناݢو',
  'صنوبر',
  'تاوکي',
  'ددامق',
  'برتاڽ',
  'محبوب',
  'ليمبق',
  'سرورس',
  'سداڤت',
  'دتبوق',
  'بليبس',
  'مماکي',
  'اچيتا',
  'سليݢي',
  'حوالة',
  'باوهڽ',
  'چلانت',
  'لايوه',
  'کوريت',
  'بريبو',
  'ترڤڠڤ',
  'ماکين',
  'سدارا',
  'جيڤلق',
  'يوڠيت',
  'دجاݢي',
  'هيناڽ',
  'لوبوق',
  'ماکاو',
  'ليسير',
  'ݢمرسق',
  'چڠکاو',
  'کوتور',
  'سداره',
  'چيکير',
  'اکسيڤ',
  'بنتقن',
  'ديريس',
  'الباي',
  'تلاتڤ',
  'اورين',
  'کوتيڤ',
  'اڠکوه',
  'امبلو',
  'برکمل',
  'کاترر',
  'منطقة',
  'دسسلي',
  'اکوان',
  'تيڤير',
  'ڤرنوڠ',
  'دتاجق',
  'منوکس',
  'مڽامر',
  'دڤانه',
  'بوتيک',
  'جامبق',
  'لاچور',
  'ڤڠادڠ',
  'برنفس',
  'ترلفظ',
  'دکوتي',
  'ميواه',
  'ڽيڽير',
  'نيوتر',
  'راݢو٢',
  'کيدمت',
  'تڠلوڠ',
  'سانتق',
  'تولقن',
  'بردتق',
  'دبنتو',
  'ڤوڠکڠ',
  'هارڤن',
  'ݢوݢوه',
  'برڠݢڠ',
  'سڤڠݢل',
  'ماݢڠن',
  'نيڤيس',
  'چمرکڤ',
  'دتيقن',
  'دسرݢڤ',
  'مڠسيت',
  'سوڠلڤ',
  'کلولو',
  'دبوٴي',
  'ڤراقم',
  'سمڤلت',
  'مڠهون',
  'ديݢرف',
  'توهوق',
  'ۏينير',
  'ريجيه',
  'ݢموقڽ',
  'الصمد',
  'تومڤڠ',
  'اوريا',
  'هبلوق',
  'ناليه',
  'لوتوت',
  'سنݢري',
  'داوله',
  'هيدوڤ',
  'اکرام',
  'بواهڽ',
  'دکتوق',
  'دالو٢',
  'سکليڤ',
  'سکدوڤ',
  'موڠسي',
  'کوماي',
  'سکچيل',
  'هيريس',
  'دچنچڠ',
  'ڤنڤتن',
  'ݢادوق',
  'الحرب',
  'ملعون',
  'دجالا',
  'دلاون',
  'هداية',
  'دتروک',
  'ڤماره',
  'چڠکاي',
  'ݢرايو',
  'کليبق',
  'براڠا',
  'ترکڤل',
  'چالوڠ',
  'دتاکي',
  'سيريڠ',
  'روديو',
  'ݢلومق',
  'باوڠ٢',
  'برلمق',
  'ڤنچيڠ',
  'تابوڠ',
  'چيتوق',
  'منرڤا',
  'جوروس',
  'جابتن',
  'ڤاٴوڠ',
  'مڠݢرڠ',
  'ساٴوس',
  'دوزين',
  'ممنتڠ',
  'ملالق',
  'ڤڽڠسي',
  'کالوڠ',
  'برجاي',
  'باٴيه',
  'اوموق',
  'تانتڠ',
  'سروال',
  'چيکيل',
  'لابه٢',
  'راڤون',
  'راٴوڠ',
  'چوڠکق',
  'اوڽوق',
  'دوساڽ',
  'مندقن',
  'منزله',
  'بالون',
  'ڤنروس',
  'حکومن',
  'تندون',
  'هيمڤڠ',
  'برججق',
  'کمسيا',
  'ويجيل',
  'بيلون',
  'بابق٢',
  'بورو٢',
  'لنتول',
  'ݢاسيڠ',
  'کاوهڽ',
  'اورقن',
  'ترتتق',
  'تولول',
  'مڽڤوه',
  'دڤالر',
  'منداݢ',
  'چاچيڠ',
  'بنتيت',
  'کلونا',
  'ريندا',
  'انتوڠ',
  'مڠياو',
  'سمڠݢي',
  'ݢلاݢق',
  'سوڠيه',
  'رهمةڽ',
  'کانجر',
  'لاذتڽ',
  'مڠورڠ',
  'برترس',
  'توهوس',
  'ترتبس',
  'ڤاريت',
  'عادتڽ',
  'ريمبس',
  'همبوس',
  'تاݢيه',
  'منيتي',
  'دلنتس',
  'امامة',
  'منچرڤ',
  'دکليم',
  'ايدما',
  'فضيحة',
  'ڤساوه',
  'کمندڠ',
  'دچڤکن',
  'داواي',
  'ڤڠياه',
  'اڠݢيس',
  'چاميق',
  'تريڤس',
  'ساووه',
  'کفارة',
  'مڽروڽ',
  'اٴيرڽ',
  'سيبور',
  'ترنفي',
  'دتاݢن',
  'ڠيبيڠ',
  'سدوتن',
  'روبوڠ',
  'چورڠڽ',
  'ݢلماچ',
  'لاتوق',
  'چابوه',
  'تنداڽ',
  'ݢوݢور',
  'دتݢوق',
  'اٴوتو',
  'بيريڠ',
  'دکاکس',
  'سجرڠن',
  'بربنت',
  'ترݢݢس',
  'مڽران',
  'براوا',
  'دکسلي',
  'اوڽاي',
  'اريسن',
  'چوڤاي',
  'ڤيتيه',
  'لوتيق',
  'ݢواتق',
  'ڤاکاو',
  'سداهن',
  'مڠيجو',
  'کديرا',
  'کرتاو',
  'دلڤوق',
  'برلفظ',
  'دسيڤه',
  'ڤڠندم',
  'بيريڽ',
  'دچوچي',
  'بمبيڠ',
  'چامبڠ',
  'تاهنن',
  'دتنتڠ',
  'برلجي',
  'ڤندرف',
  'تمنوڠ',
  'لمبهڽ',
  'سمڤنڽ',
  'مڠݢرو',
  'بلورو',
  'تومڤس',
  'ايميج',
  'ڤمنوڠ',
  'سمڠݢه',
  'دلاڽه',
  'مرنتق',
  'ليچيت',
  'ليڤا٢',
  'ڤڠاڽم',
  'چاٴول',
  'قناعة',
  'ليوقن',
  'بليات',
  'مامس٢',
  'ڤيليق',
  'مڽڠاج',
  'دسباي',
  'هندلڽ',
  'مڠاتي',
  'کيتيق',
  'دبسله',
  'بلاکي',
  'کريتن',
  'مرماج',
  'وارون',
  'برچمر',
  'ڤنرنن',
  'دکيسر',
  'بويوت',
  'سجارق',
  'دساکت',
  'ليريق',
  'جلالة',
  'کرڽيڠ',
  'راملن',
  'سروکو',
  'ڤدولي',
  'سلودو',
  'دڤندڠ',
  'اڤلوس',
  'بربلا',
  'زياره',
  'ريريت',
  'اوندڠ',
  'مڠسوڤ',
  'دليما',
  'منڠݢڤ',
  'مرادڠ',
  'دسوکت',
  'دڤرلي',
  'چاچير',
  'تارم٢',
  'سوامي',
  'ڤيريق',
  'برجاي',
  'ڤلدقن',
  'برججل',
  'کتمبق',
  'دݢروس',
  'مڠولق',
  'چاروت',
  'سوريه',
  'ايسيه',
  'دراٴڽ',
  'برابت',
  'لليڤن',
  'مماتر',
  'هنتوڽ',
  'مميوه',
  'روڤوه',
  'اکسيس',
  'رواتن',
  'کالڠن',
  'ڤسيتا',
  'رامي٢',
  'اڠسور',
  'بالقڽ',
  'بوکوت',
  'سݢرا٢',
  'اسينن',
  'ڤنتري',
  'ايناي',
  'کاڤيس',
  'ڤيݢمي',
  'مڽڠير',
  'بݢيان',
  'ساساو',
  'کورسي',
  'چرملا',
  'مرڠوس',
  'ڤسونا',
  'ليڽيق',
  'اونچڠ',
  'سبيلڠ',
  'مموتر',
  'برلوق',
  'مقدمه',
  'چاڤوق',
  'کليسه',
  'کرمڠک',
  'ڤوکيڠ',
  'کندلم',
  'کنداو',
  'مندبو',
  'ترݢمڤ',
  'درايه',
  'مڽدوت',
  'طاعةڽ',
  'کتݢڠن',
  'کينيڠ',
  'يوندا',
  'توکوڤ',
  'تيڤوق',
  'ڤوسوت',
  'برايا',
  'مڽرڠڽ',
  'لاوي٢',
  'وصيتڽ',
  'تتمڤن',
  'کتايڤ',
  'مليان',
  'دلواڠ',
  'چيچير',
  'ڤيڤيق',
  'سودهڽ',
  'ڤننتو',
  'سليوه',
  'اوڠه٢',
  'سجاده',
  'سبنتر',
  'تيڠکڤ',
  'ڤروست',
  'سايکد',
  'ماٴين',
  'ݢوراو',
  'ڤلاوق',
  'ڤاتوڠ',
  'ڤودور',
  'سلنچڠ',
  'ڤمڠکو',
  'سواڠ٢',
  'بيسول',
  'دݢالي',
  'کيچير',
  'کڽڽقن',
  'بينتڠ',
  'ترچڠڠ',
  'سوتان',
  'منذره',
  'مڠوبر',
  'ڤڽيکو',
  'بدامن',
  'سرڤيه',
  'بادوڠ',
  'توندن',
  'داويت',
  'ڤرسيه',
  'ترياق',
  'ايواق',
  'مڠمڤت',
  'دادوه',
  'ݢيرين',
  'برادو',
  'کڤيڠ٢',
  'مڽتوم',
  'باڠور',
  'بردرڤ',
  'کيچيق',
  'عقائد',
  'ڤڠکلن',
  'امڤون',
  'ݢلابه',
  'جاروم',
  'ݢابوس',
  'بيدنڽ',
  'الأول',
  'ڤيدلڽ',
  'چيتاڽ',
  'ايومن',
  'کتيال',
  'تنديڠ',
  'دسايڠ',
  'روجي٢',
  'ڤنندڠ',
  'دڠوڠن',
  'ترلݢا',
  'ديسرت',
  'بيبير',
  'مکتوب',
  'دسوکت',
  'اووار',
  'مناجم',
  'رجمن٢',
  'اندوڠ',
  'مليڠه',
  'جرمبق',
  'چينچو',
  'ترنجت',
  'سووير',
  'بارت٢',
  'مونتج',
  'موليق',
  'رنجيڠ',
  'بليݢو',
  'سامڤق',
  'ڤرڠݢڠ',
  'ليڤيس',
  'کوڤيڠ',
  'سوڠݢق',
  'دجامو',
  'ڤڽڤوڽ',
  'هيلير',
  'سمنتن',
  'سموکل',
  'منرکڤ',
  'خمسين',
  'برسعي',
  'کلولا',
  'برجاݢ',
  'هرتا٢',
  'ݢرياڤ',
  'تلکوم',
  'دڤربل',
  'ڤرݢڠن',
  'لڠسير',
  'لوکوم',
  'منتهي',
  'بولوت',
  'دمباي',
  'تاڤاو',
  'اوباي',
  'چلبور',
  'دوبلي',
  'برکڤق',
  'مماده',
  'ددريا',
  'لاڠيس',
  'اهوال',
  'برعدة',
  'اڽمنڽ',
  'برلکڠ',
  'چاٴوق',
  'کسنڠن',
  'منونڠ',
  'لمڤير',
  'ملوڽه',
  'ممادو',
  'ساٴيس',
  'رڤوتن',
  'کابيل',
  'دکاول',
  'دڤالو',
  'ميجا٢',
  'ڤونوق',
  'ترسرت',
  'کامير',
  'راڠين',
  'چکارا',
  'حرامڽ',
  'مڠاتق',
  'کاريل',
  'سکتيل',
  'اسماء',
  'ݢليݢي',
  'همڤوق',
  'انچيق',
  'دڤاجڠ',
  'چوڠڤن',
  'باريڠ',
  'ترهڽق',
  'اولي٢',
  'سيتݢڠ',
  'نکارا',
  'جوڠݢڠ',
  'تيڠوق',
  'لڠساي',
  'ڤلندس',
  'کندور',
  'دݢڠݢم',
  'دندمن',
  'سباٴو',
  'حاجيب',
  'دامق٢',
  'برليم',
  'تاڤقن',
  'ڤورنا',
  'ݢاراو',
  'کمنتن',
  'سڤاڤن',
  'ڤمادم',
  'ڤلاته',
  'کرنتم',
  'چوڠيق',
  'اودول',
  'امرن٢',
  'لمڤني',
  'ابڠمو',
  'بولوس',
  'باڠکل',
  'کيڠکا',
  'تتيبر',
  'توڠول',
  'ليمبڠ',
  'لمڤدو',
  'کرتوس',
  'بلهکن',
  'ويکيت',
  'تيمڤق',
  'برباج',
  'چمبوڠ',
  'دڤادن',
  'ڤڽندل',
  'چرتيس',
  'اوريڠ',
  'مسلين',
  'عمل٢ڽ',
  'ڤيجقن',
  'چرݢسڽ',
  'کوتوق',
  'کاروڠ',
  'سورجن',
  'ڤلراي',
  'ممندڠ',
  'ڤوتيق',
  'مراتڤ',
  'بيله٢',
  'جنتاک',
  'سيقسا',
  'دماکن',
  'مروان',
  'برنذر',
  'اونتق',
  'سلالو',
  'تالين',
  'رنتاي',
  'منچݢت',
  'سمبيل',
  'چاديق',
  'ممڠݢڠ',
  'ڤڽادڤ',
  'هلاي٢',
  'چيچيق',
  'ڤنابس',
  'تسامح',
  'ڤڠڠکت',
  'ڤلاڠا',
  'کدکاي',
  'کيروڠ',
  'تراٴن',
  'چمڤين',
  'کنچيه',
  'ڤاݢون',
  'تڠݢيق',
  'اوۏوم',
  'ججنتس',
  'ݢلاݢه',
  'ݢونتا',
  'مريسق',
  'داندم',
  'کالاي',
  'ميسين',
  'مڽيار',
  'کيتيڠ',
  'ݢتوان',
  'بردوݢ',
  'کموت٢',
  'سيکيت',
  'لاڤڠڽ',
  'لنتول',
  'ڤوست٢',
  'چوڠکڠ',
  'کرڤوس',
  'مننتو',
  'کوچاي',
  'لينتڠ',
  'ارينا',
  'کفليڠ',
  'ڤڽوده',
  'واوسن',
  'ڤيلن٢',
  'رامبس',
  'ڤادهڽ',
  'ڤڽلوق',
  'دبوال',
  'ڤريسا',
  'ايجين',
  'سالوت',
  'امانت',
  'ليبرل',
  'بلابت',
  'ممڤدل',
  'ريڽيه',
  'تمبهي',
  'تلنجڠ',
  'سمڤوڠ',
  'مهوبي',
  'کوڤيڠ',
  'منچڤق',
  'برݢݢر',
  'دبالق',
  'کوڠاݢ',
  'مراڤو',
  'لنسيڠ',
  'تمڤين',
  'روڠݢو',
  'سراتي',
  'ترسلم',
  'نيچيس',
  'ڤنڠݢه',
  'جاݢله',
  'دجاوت',
  'ديڤوه',
  'چچارو',
  'چيچيه',
  'کاتيه',
  'کعکسن',
  'مڽيبق',
  'چاڠوق',
  'ݢيليس',
  'رڤوهن',
  'کبولي',
  'دݢارڤ',
  'بلواي',
  'کمليڠ',
  'مڽريت',
  'ڤريݢي',
  'سوبوق',
  'هيڤوت',
  'کونچا',
  'ڤرادڠ',
  'سمنتڠ',
  'رومبا',
  'ڤڠݢمڠ',
  'کاروم',
  'برقدر',
  'بهارو',
  'سرمڤت',
  'مڠتوڤ',
  'دݢالڠ',
  'دتاجم',
  'دلاون',
  'ارباب',
  'رعيتڽ',
  'مڽتوڤ',
  'منچلق',
  'للاکي',
  'چلتوق',
  'نفسي٢',
  'اهليڽ',
  'منمبڠ',
  'دديوا',
  'مربوڠ',
  'دکاتڽ',
  'لوچوق',
  'مناسک',
  'کيروڤ',
  'ݢانڠڽ',
  'جومبڠ',
  'براجر',
  'کردوت',
  'کربين',
  'سيمبر',
  'بهاري',
  'توارڠ',
  'تروسن',
  'انتيل',
  'ݢولوڠ',
  'باتڠڽ',
  'اوريت',
  'چيڠݢي',
  'جلاجه',
  'بلاسه',
  'سالور',
  'اچوهن',
  'راکنڽ',
  'کوڽهن',
  'للادا',
  'کوکون',
  'ريڠکت',
  'بيتيس',
  'سيڽور',
  'راڤاي',
  'کودا٢',
  'ڤانتڠ',
  'اينجا',
  'کچمبه',
  'بردوک',
  'لابوت',
  'درنچڤ',
  'مڽيرم',
  'اولکن',
  'ڤڠنلن',
  'ڤرينڠ',
  'سيکسي',
  'ماسيڠ',
  'ککيسي',
  'ساليڠ',
  'چمڤلق',
  'تربلا',
  'سکجڤ٢',
  'لمڤري',
  'فيکير',
  'سکالي',
  'دچيوم',
  'ترهيم',
  'برکلس',
  'جوکوت',
  'ستاکر',
  'انتري',
  'ستوکل',
  'ڤڠريڠ',
  'مڠديق',
  'مراتي',
  'تيمڠن',
  'فلانل',
  'ڤومڤا',
  'مانور',
  'ملبوق',
  'تقدير',
  'ڤولوڠ',
  'چيتيڠ',
  'منچرڤ',
  'ݢوهوق',
  'ݢونا٢',
  'مڽاݢو',
  'بنقمو',
  'لوکيق',
  'تيوري',
  'ڤيڠݢڠ',
  'برسسل',
  'بليبر',
  'سورين',
  'ايچير',
  'ڤرڠکڤ',
  'چونوت',
  'کرڤاي',
  'کديري',
  'اوسله',
  'ڤاريس',
  'ڤنمبه',
  'ڤڽدوت',
  'دأيکت',
  'لمباي',
  'کلواڠ',
  'داسون',
  'اڠݢاو',
  'نعمتي',
  'منجرڠ',
  'ڤادهن',
  'برݢمر',
  'جيواڽ',
  'لاذت٢',
  'چاماو',
  'فيلوم',
  'راݢمڽ',
  'مڠليق',
  'لوندر',
  'کادوق',
  'کمومو',
  'ڤاٴيس',
  'رڠݢو٢',
  'تکسير',
  'راٴوڤ',
  'الجبر',
  'برتبس',
  'اڤيت٢',
  'ڤراسڤ',
  'دکرون',
  'مڠولر',
  'کالين',
  'اقليم',
  'لاکوڽ',
  'ابدال',
  'انتلس',
  'کرسيق',
  'درابي',
  'کوروب',
  'بوݢيم',
  'ڤيساو',
  'ݢاتل٢',
  'القوى',
  'مندݢم',
  'وسواس',
  'بولاي',
  'دداکڤ',
  'بردرو',
  'چانيت',
  'ارݢوت',
  'دتاکر',
  'دباݢي',
  'دڤيات',
  'مندتق',
  'دڤادم',
  'مراهڤ',
  'تروسا',
  'ݢندوه',
  'دبروس',
  'ليچاو',
  'سربوق',
  'چوچوت',
  'ڤاڤرن',
  'ڤڽڠکڠ',
  'کتکلن',
  'راتيڠ',
  'ساورت',
  'مروݢي',
  'فيورد',
  'مڠيدس',
  'رياقڽ',
  'ڤڽوکت',
  'بردݢڤ',
  'دمڤوڠ',
  'اجوجه',
  'مڠوڤه',
  'منددس',
  'باجڠ٢',
  'ديبيت',
  'دسدوه',
  'ݢليبس',
  'اتسمو',
  'ڤوليس',
  'دستور',
  'سڤاکت',
  'ڤنوه٢',
  'مادوڽ',
  'اروهن',
  'ݢاجيڽ',
  'هنداي',
  'تاوفو',
  'ڤڠنتر',
  'سمبوت',
  'کبايڠ',
  'دتڠݢڤ',
  'ڤجالن',
  'سمندا',
  'لمڤوق',
  'سوڠکل',
  'کوداڽ',
  'ستورو',
  'ڤماته',
  'بسرله',
  'کريۏس',
  'اولاي',
  'ترتکڤ',
  'کرياڤ',
  'اوردو',
  'باداي',
  'المني',
  'ڤرݢيڽ',
  'ݢݢلما',
  'تيسوڽ',
  'ݢالوه',
  'ستالا',
  'ڤڠکلڽ',
  'ليمڤر',
  'سواجر',
  'کمودو',
  'ڤکوکو',
  'دأچوم',
  'بڠکقڽ',
  'مڠيجا',
  'دبواڠ',
  'مشارک',
  'لاتوڠ',
  'سکيون',
  'ڤرمڤس',
  'مجذوب',
  'ترلکڤ',
  'امبون',
  'کلادق',
  'ۏيديو',
  'ڤرڽوق',
  'مراڠه',
  'ڤللسن',
  'ڤڤويو',
  'مڽيکت',
  'مڠݢلڤ',
  'کاتاي',
  'فهمکو',
  'جڤيتن',
  'راواي',
  'ڤيوت٢',
  'فرݢمن',
  'بندڠڽ',
  'ڤمينت',
  'چڠکور',
  'کلڤاي',
  'ݢامڤر',
  'دوکيت',
  'دتتڤڽ',
  'شيطان',
  'مڠنڠڽ',
  'ميميق',
  'ناناي',
  'ڤلازا',
  'ݢاڽوت',
  'هالوس',
  'لامڤم',
  'بوتوه',
  'سنصيب',
  'نجاسة',
  'مڠسول',
  'کوڤيڽ',
  'ملتوڤ',
  'بڠکاي',
  'ڤڽتير',
  'اوجود',
  'تودوڠ',
  'چليچي',
  'اقلاب',
  'چلڤوڠ',
  'ملمڤن',
  'مڠسلي',
  'بالغة',
  'ايروت',
  'فيزيک',
  'چينتڠ',
  'تيکرڽ',
  'ڤوسکد',
  'خرافة',
  'دايسي',
  'رواس٢',
  'کاونن',
  'رسولڽ',
  'ڤنمڤه',
  'دکتيق',
  'لايتر',
  'دهنتق',
  'ايتيل',
  'کيسي٢',
  'بنتيڠ',
  'هالاڽ',
  'ڤريمن',
  'سمڤوه',
  'سسڤيت',
  'تيڠکس',
  'ݢوموق',
  'سهاتي',
  'مليڤو',
  'لمڤين',
  'سورود',
  'تيراو',
  'تيما٢',
  'موروڠ',
  'ايريت',
  'کيريم',
  'راڽوق',
  'کاٴوت',
  'معجون',
  'ترݢته',
  'چچيبڠ',
  'جمورن',
  'جليتا',
  'بارن٢',
  'کريڠ٢',
  'برومت',
  'مالر٢',
  'داتيڠ',
  'برݢلر',
  'برتاڤ',
  'چوندڠ',
  'ݢجوجو',
  'کويوڤ',
  'رومبه',
  'سڠݢام',
  'اڠين٢',
  'ڤسيره',
  'بالوق',
  'داديه',
  'برسوا',
  'سراوا',
  'تالون',
  'ڤڠندق',
  'ڤلکوه',
  'ݢيڠسي',
  'ممڤرڠ',
  'ݢوردن',
  'منڤوق',
  'ݢدݢون',
  'کرسوق',
  'تترڤن',
  'قيصهڽ',
  'کاونڽ',
  'ستيبا',
  'لوڠکه',
  'مجتهد',
  'ڤڠميق',
  'منيرو',
  'اوڤاي',
  'چيدوڠ',
  'سيليق',
  'ريموه',
  'الڠکه',
  'ڤمادت',
  'بيدسن',
  'مراول',
  'منسيو',
  'ڤنيڠ٢',
  'لولوق',
  'ڤمرهن',
  'هفبيک',
  'ريبيق',
  'ݢيݢيس',
  'توݢس٢',
  'مڠاݢه',
  'ڤرمين',
  'ببراڤ',
  'جمجمه',
  'رنوڠن',
  'مومور',
  'سواري',
  'دريکو',
  'داٴيل',
  'ڤڠياس',
  'بايور',
  'ݢاره٢',
  'کرڽاو',
  'کاتيب',
  'لوکهڽ',
  'سناين',
  'جالوي',
  'دڤاجق',
  'کݢلڤن',
  'ݢامقڽ',
  'مويڠڽ',
  'سامبڠ',
  'ۏوتوم',
  'ساوه٢',
  'سبرت٢',
  'مڠوڤم',
  'کنتور',
  'راچوت',
  'ڤاريق',
  'لولوم',
  'جنتيق',
  'اوبنن',
  'چمباي',
  'کلارس',
  'تمڤوڠ',
  'ݢرڽوت',
  'ڤڠڤقن',
  'ڤݢاري',
  'حکمتڽ',
  'ڤمڠکس',
  'سيلوق',
  'مڠحجه',
  'باکاو',
  'برکرق',
  'ݢڠݢوت',
  'لمورو',
  'ݢندون',
  'سساڤت',
  'ديبيق',
  'ساجين',
  'اولتڽ',
  'مڽاڽي',
  'مماڤق',
  'ڤوسوڠ',
  'جيڤرق',
  'سيدڠ٢',
  'اليسڽ',
  'منتري',
  'تيريت',
  'ممببت',
  'بلاچن',
  'بوڠسو',
  'لوتيک',
  'عالم٢',
  'بنتلن',
  'دريسي',
  'سرنتا',
  'ڤڠڽهن',
  'بربلس',
  'کوڤرل',
  'جناهر',
  'مليݢس',
  'روٴيڠ',
  'عزازل',
  'ممندڤ',
  'دسلوت',
  'کولير',
  'تاکول',
  'مرنده',
  'اوڠکل',
  'دارهڽ',
  'ستوٴي',
  'جماعه',
  'تيتيس',
  'بمبون',
  'ڤنݢوه',
  'جيلتن',
  'دجيلت',
  'چروما',
  'کڽالڠ',
  'سنتاو',
  'تمڤت٢',
  'والي٢',
  'بوقتي',
  'ڤناجا',
  'دکوڤس',
  'ݢاجاي',
  'معجزة',
  'چنتڠن',
  'ميمڠڽ',
  'ڤيچاي',
  'براسه',
  'ترنتق',
  'ڤرنکو',
  'ڤراون',
  'چلورڠ',
  'ݢرسيق',
  'يهودي',
  'سألور',
  'لاکرن',
  'کتوال',
  'کندرن',
  'بولتڽ',
  'مڠيرا',
  'ساڤاو',
  'تنجور',
  'ڤماچو',
  'ريڤيس',
  'باتوڽ',
  'تيکسڽ',
  'ڤماسق',
  'مموکه',
  'ڤڠساي',
  'دوبور',
  'ݢدابو',
  'ڤڠݢرق',
  'جيواڠ',
  'معناڽ',
  'اونيک',
  'سوڠوت',
  'اولق٢',
  'ترڤري',
  'ݢينري',
  'مناور',
  'سيارن',
  'چوبا٢',
  'ديڠين',
  'مراون',
  'لاکون',
  'ليڠݢي',
  'دلبور',
  'ݢميرڠ',
  'روميا',
  'کبنرن',
  'الامس',
  'ڤاسيف',
  'بورسا',
  'انتوڠ',
  'دسونو',
  'تانهڽ',
  'مڠساي',
  'بوڠکه',
  'سکندي',
  'دڠکول',
  'ڤردوا',
  'ويندو',
  'داچيڠ',
  'لوبڠ٢',
  'ڤرليم',
  'بيار٢',
  'ݢاڠݢڠ',
  'رڠکوق',
  'ملکري',
  'تاهيڽ',
  'چاݢون',
  'هالاو',
  'اٴيتم',
  'دباوا',
  'بالڤن',
  'ڤڽربو',
  'منبيڠ',
  'ڤندير',
  'دسامق',
  'ميريس',
  'مسارا',
  'تردڠر',
  'سکوتر',
  'کيتاي',
  'سسوکو',
  'سجامڠ',
  'همبوڠ',
  'دسيسن',
  'ڤرکتن',
  'اوۏول',
  'کاٴوڠ',
  'مغفرة',
  'کوتيل',
  'ݢمبور',
  'بݢاهق',
  'کونچه',
  'رينيت',
  'کيکوت',
  'ڤيليه',
  'فوکوس',
  'برسڠت',
  'تتڠݢڽ',
  'برلچه',
  'مليما',
  'چمبير',
  'ملنتر',
  'ڤيهقڽ',
  'ڤڠرچا',
  'ببوات',
  'مڽولڠ',
  'هاوية',
  'تاتيه',
  'انچڠ٢',
  'برابڠ',
  'ڤڠوچڤ',
  'ملاݢو',
  'لمنتا',
  'چاداي',
  'مڠادو',
  'سروجا',
  'دسوݢر',
  'بݢاسي',
  'تسليم',
  'سريما',
  'ساڠاي',
  'سينتا',
  'جرڠکڠ',
  'بوليس',
  'ايڤوه',
  'ڤڠجرن',
  'بلمبڠ',
  'کنچڠڽ',
  'ساندل',
  'سلڤسڽ',
  'سڤيکر',
  'ليڠݢر',
  'بونيت',
  'اومبن',
  'مڠنجو',
  'اکارة',
  'لاکور',
  'داولس',
  'هارو٢',
  'بربلي',
  'ڠاليه',
  'برهام',
  'براڠڤ',
  'تلمڤڤ',
  'لاڤوق',
  'فوليو',
  'دساݢڠ',
  'سيݢوڠ',
  'مڠنچڠ',
  'نيدرا',
  'داڠوق',
  'دتيڤو',
  'برلوق',
  'ڤڠکاي',
  'دهارو',
  'ڤاٴين',
  'مرمڤت',
  'علامة',
  'کنتوت',
  'ميليق',
  'ڤممڤت',
  'ميݢرن',
  'ريتوس',
  'برلڠس',
  'دهمڤر',
  'متکلم',
  'ڤناڠن',
  'کوتيس',
  'هاکيس',
  'منالو',
  'قناعه',
  'تابوت',
  'سممڤت',
  'مماسق',
  'بلاچق',
  'کراتڠ',
  'دأيجا',
  'تراجڠ',
  'دتڠݢر',
  'ڤݢودا',
  'ترجان',
  'چيڤتا',
  'نيلور',
  'چرليڠ',
  'خيزيد',
  'کتريڠ',
  'سجريت',
  'مڠودق',
  'اوديم',
  'انيمر',
  'ترهضم',
  'سورم٢',
  'سويڤر',
  'مڠندر',
  'هامڤس',
  'باهن٢',
  'ݢاݢوق',
  'باکير',
  'ملوکڤ',
  'ڤادا٢',
  'روتور',
  'دانوه',
  'الينن',
  'ممکوق',
  'ݢنجيل',
  'سڠݢوت',
  'سروله',
  'مڽڠݢق',
  'ڤمندق',
  'تيڤين',
  'تمڤيل',
  'ڤنديڠ',
  'ڤيسير',
  'ݢڤليق',
  'کاريه',
  'برسنڠ',
  'ليلوڠ',
  'ليڤوت',
  'ݢنداڽ',
  'مرندم',
  'مراکن',
  'ستاور',
  'ديندا',
  'سنتول',
  'چوڠول',
  'دݢرتق',
  'مڠستڽ',
  'مروجه',
  'دبنتو',
  'کرنتو',
  'مڽڠيه',
  'موتور',
  'ڤرݢول',
  'دسدري',
  'سديام',
  'کلبتن',
  'رمڤيس',
  'کوسوم',
  'مقصود',
  'ڤنالا',
  'بليدا',
  'شريعة',
  'سوقتو',
  'ݢݢنچو',
  'بررقن',
  'ݢمبوق',
  'منيڤو',
  'دأڠکت',
  'دعوتڽ',
  'دچکوت',
  'کچمسن',
  'بݢيتو',
  'دراڤت',
  'کابوت',
  'چڤروق',
  'چوريڠ',
  'دبرکس',
  'باريس',
  'سجاتي',
  'مڠنجق',
  'روتين',
  'مراݢڠ',
  'تعريف',
  'مڠيݢه',
  'انداي',
  'دسارق',
  'لاينن',
  'برڤݢڠ',
  'بابوت',
  'اجمال',
  'ڤيڠت٢',
  'مڽکتڽ',
  'ڤاتيق',
  'ڤوڠکه',
  'کچکلن',
  'ݢيسير',
  'چاڤيق',
  'ݢمرکن',
  'برهرݢ',
  'مڽروک',
  'ڤواوڠ',
  'دودوت',
  'برسبب',
  'سجاره',
  'کوڤسن',
  'اندڠ٢',
  'ڤلاون',
  'ريوان',
  'ڤيڠيت',
  'هرڤيس',
  'مڠيݢل',
  'ڤريڠت',
  'لوبور',
  'براݢن',
  'مولاي',
  'نراڤي',
  'برترڤ',
  'ريڠيس',
  'منورڤ',
  'کوسوت',
  'مڠلتي',
  'سڤوي٢',
  'سيواک',
  'ڤينتل',
  'مڠارڠ',
  'سکاوه',
  'ملڠکه',
  'دبوٴي',
  'دوکان',
  'برتڠه',
  'مراݢي',
  'دڤندم',
  'لايوڠ',
  'ممندم',
  'دچاچق',
  'ممبلر',
  'ميريه',
  'دبوات',
  'دليما',
  'دمڤرت',
  'سينوس',
  'بکوکو',
  'ڤلسما',
  'سياڠ٢',
  'سکليه',
  'داڠير',
  'ساڤيه',
  'فراسة',
  'دسدوه',
  'ڤدوتا',
  'توبوه',
  'نيڠرت',
  'الڤيه',
  'دلڤکن',
  'کيندڤ',
  'ليندڠ',
  'برسلم',
  'بردوک',
  'خيالن',
  'بيچيق',
  'بيقسو',
  'ڤڤاکو',
  'دسالڠ',
  'درندم',
  'کرچوت',
  'دساسڤ',
  'برلمه',
  'تربتي',
  'ساهمڽ',
  'نيبيڠ',
  'ڤلتقن',
  'تولڠ٢',
  'السنڽ',
  'بربنم',
  'سچبير',
  'ڤڽسلن',
  'تتاوق',
  'توموس',
  'يقيوڠ',
  'اونچق',
  'لوروس',
  'سنڠله',
  'توڠݢو',
  'کياٴي',
  'ربانر',
  'خيانت',
  'سڤوست',
  'ملمڤم',
  'ساڠت٢',
  'مڠچيل',
  'دلوتو',
  'ممبلا',
  'تميلڠ',
  'تريتي',
  'لاٴيس',
  'بربدن',
  'سڤاݢر',
  'دأولڠ',
  'منقما',
  'لڤوه٢',
  'وهدية',
  'کچيلڽ',
  'سيمبڠ',
  'تربلم',
  'عزيمة',
  'مماڤس',
  'مڠولس',
  'بوروق',
  'برکمس',
  'لاݢوت',
  'ايڠوس',
  'کچمنڽ',
  'سدڠکن',
  'سکوته',
  'داٴيم',
  'ڤولڤا',
  'صحابت',
  'لوتوق',
  'راڠوم',
  'مفسير',
  'ڤلاير',
  'توفيق',
  'کامبر',
  'مايور',
  'مڠرنا',
  'سدوهن',
  'ڤڠينڤ',
  'ڠاياو',
  'کتابڽ',
  'ݢالو٢',
  'سݢيني',
  'سأيکر',
  'لاݢي٢',
  'مانيا',
  'هڠݢوڠ',
  'اوروق',
  'درهمڽ',
  'معتبر',
  'توجوه',
  'مرودو',
  'سلڠکڤ',
  'برسين',
  'دلاڽق',
  'بيندو',
  'بردڤڠ',
  'سلوڤت',
  'لکوقن',
  'ݢانتر',
  'تيتيس',
  'باکتڽ',
  'رڠکون',
  'ڤنݢڠن',
  'موارا',
  'امڤول',
  'مڠوڤق',
  'بيچاڽ',
  'بوندر',
  'دراين',
  'وليمة',
  'ترسجت',
  'کروڠن',
  'چيچوق',
  'سرايا',
  'سامڤي',
  'باميه',
  'لونسڽ',
  'مڽيڤت',
  'کتواڽ',
  'مماجل',
  'قربان',
  'چاڠيت',
  'ممبکس',
  'دورية',
  'مڠيبس',
  'دتمين',
  'تريڤڠ',
  'سبابق',
  'کتاوا',
  'دمڤيت',
  'جمبيا',
  'مافيا',
  'ݢوريق',
  'باوهن',
  'لمبوت',
  'ڤنتيق',
  'دتمبت',
  'تيبيڠ',
  'ڤايه٢',
  'ممبلت',
  'ڤرجاک',
  'کونيت',
  'دچاکر',
  'کوٴير',
  'سليڠر',
  'ڠالاو',
  'ݢالاڽ',
  'برکره',
  'سوݢوه',
  'چدراڽ',
  'کوڤوي',
  'سڤوکل',
  'ڤڽيڤق',
  'ڤرواڠ',
  'سنديڽ',
  'ڤڽاڠق',
  'سکالا',
  'تاروق',
  'ڤندوق',
  'موتوڠ',
  'لوڤق٢',
  'ککروه',
  'سڠياڠ',
  'علامت',
  'حرارة',
  'سدڠکل',
  'سڤوسر',
  'ستيار',
  'ڤروتن',
  'دچمکن',
  'بکارت',
  'براکو',
  'ڤلندا',
  'ماهلڽ',
  'فيريت',
  'ککرسن',
  'تاکول',
  'لينسا',
  'کاچوق',
  'برڠتا',
  'ملڠکن',
  'کرليق',
  'دسواق',
  'روڤاڽ',
  'کليدر',
  'جلباب',
  'ݢيمڤل',
  'باروڠ',
  'بخيلڽ',
  'مننچڤ',
  'ݢلومڠ',
  'ترکرق',
  'جمڤوق',
  'ڤماجق',
  'مروبي',
  'سبلاي',
  'دانجق',
  'چلڤوت',
  'چڠکدي',
  'مڽيݢي',
  'اڠݢيت',
  'سوراݢ',
  'هبوبن',
  'ݢيڽيه',
  'تعذور',
  'مڽارا',
  'ڤستيل',
  'تولوق',
  'بارتڽ',
  'ڤڠجتن',
  'کماتو',
  'العفو',
  'دۏيسي',
  'ريستن',
  'ݢوسيڤ',
  'ڤڽرتا',
  'موضوع',
  'تاندڠ',
  'توافق',
  'چراين',
  'ڤامير',
  'ݢمرلڤ',
  'سيݢيه',
  'سداڤت',
  'دراقم',
  'سکاڤر',
  'تمارڠ',
  'باکرڽ',
  'ڤيتيڠ',
  'جوجوت',
  'ݢايون',
  'اونيت',
  'چراڤڠ',
  'سکبون',
  'ديڠݢو',
  'توتوق',
  'مرتوا',
  'ڤوڠوت',
  'ملوچق',
  'مرايڠ',
  'کوريا',
  'دلاڤه',
  'ستامن',
  'برارق',
  'کاکقڽ',
  'دسليت',
  'سيروم',
  'ساڠکل',
  'دڤاکو',
  'سمبلن',
  'کوتيڠ',
  'کڤدکو',
  'ددامق',
  'کوله٢',
  'راݢيڽ',
  'ترڠسڠ',
  'ڤنچوڠ',
  'ڤاڠوق',
  'بلسيت',
  'ڤنچيل',
  'تيمڤل',
  'تاوݢي',
  'بيناس',
  'سبنتر',
  'تيوڤن',
  'ݢالڠن',
  'منڠݢڠ',
  'کابين',
  'بوتوڠ',
  'علمون',
  'راکوق',
  'کجلين',
  'بليان',
  'ساوتس',
  'ويرون',
  'چوريݢ',
  'مستري',
  'فيروس',
  'صالح٢',
  'توکوه',
  'جرمبا',
  'دنتوڠ',
  'تابيق',
  'ڤلڠݢر',
  'مصلحت',
  'تيتيت',
  'ملديڠ',
  'تعارف',
  'اخيرڽ',
  'کدڠکڠ',
  'چادر٢',
  'نندوڠ',
  'بڠکوڠ',
  'تيباڽ',
  'ݢارم٢',
  'کرنچت',
  'کريوت',
  'برکنن',
  'برومن',
  'سنالو',
  'همبا٢',
  'ڤرسڠن',
  'کسستن',
  'اوليو',
  'تمڤيق',
  'الون٢',
  'سکيما',
  'بوريت',
  'ڤيريڠ',
  'سيتيه',
  'تڠاده',
  'بوندا',
  'دهشتڽ',
  'سابون',
  'ڤرلوڠ',
  'مستيک',
  'کوبوق',
  'دبيلس',
  'دڤولس',
  'نوۏلت',
  'اوديق',
  'تربيل',
  'سسالڠ',
  'بليݢت',
  'کونوڽ',
  'محجور',
  'سڤادي',
  'مڠرسي',
  'بردڠر',
  'کيڠکڠ',
  'روموڠ',
  'ريمبو',
  'ياٴيس',
  'دسسهڽ',
  'واهاي',
  'اولنا',
  'ستندن',
  'بينتر',
  'ڤوروت',
  'سيوڠک',
  'دتوکق',
  'سمارم',
  'ݢندوت',
  'زنديق',
  'خلايق',
  'سراڤت',
  'برݢته',
  'مڠليم',
  'جوڠکت',
  'ڤکرجا',
  'ممودر',
  'لاليم',
  'برکمل',
  'ڤڽڠتن',
  'بريبا',
  'لاجوڽ',
  'دسوار',
  'دڽهکن',
  'حاجتڽ',
  'کرابڠ',
  'باڠاي',
  'ڤاٴيڤ',
  'دهينا',
  'چاکوي',
  'مڠلوه',
  'برواج',
  'مڽانق',
  'سلکسڽ',
  'مماجق',
  'لمهله',
  'توروق',
  'مروين',
  'کاريت',
  'عقليه',
  'تردرا',
  'سيݢون',
  'ڤڠلان',
  'للنتر',
  'ماماي',
  'ڤيڤير',
  'ڤاکنن',
  'سدنيڠ',
  'بوبوق',
  'مڠاجن',
  'ڤڤينڠ',
  'ڤاساي',
  'مڽواق',
  'سأورڠ',
  'ديريت',
  'بربنق',
  'تيادڽ',
  'تاتهن',
  'ݢامق٢',
  'کسبلن',
  'حکماء',
  'سباکڠ',
  'سيبيڠ',
  'کوتاڽ',
  'ڤڽبتن',
  'بوکور',
  'ڤڠيلت',
  'کليمڽ',
  'فلسفة',
  'ملياو',
  'سيلقڽ',
  'توڠکڤ',
  'سابور',
  'کرڽيت',
  'ترووت',
  'دلوڽه',
  'ڤمݢتن',
  'مڠاوڠ',
  'بوتڠڽ',
  'ݢرنتڠ',
  'کاٴين',
  'راکن٢',
  'هيبور',
  'چتوسن',
  'برلاݢ',
  'بربحث',
  'چنيلا',
  'توريق',
  'حاجيم',
  'منيرا',
  'کالي٢',
  'مميسه',
  'لوڤوت',
  'دڤارڠ',
  'ترکجر',
  'ڤنمڤن',
  'کرکوه',
  'بروعد',
  'ݢلڤور',
  'چوڤيت',
  'مڠݢمل',
  'جلاڠق',
  'سيکرس',
  'ݢانجق',
  'بربکس',
  'کروه٢',
  'چرلوڠ',
  'لادڠ٢',
  'ڽاتکن',
  'ڠاڤون',
  'ليتوڤ',
  'ڤمودا',
  'ڤلاوا',
  'کسڤين',
  'مريبو',
  'اوسير',
  'سکروڽ',
  'ڽيور٢',
  'جيڠݢو',
  'قاضي٢',
  'شرطمو',
  'ليڽيه',
  'ڤيندر',
  'روروت',
  'مڽتير',
  'ڤڠستن',
  'کجوجو',
  'ترکتت',
  'سڤنوه',
  'منچبق',
  'هرتاڽ',
  'ستاڤق',
  'توڽوه',
  'تينجا',
  'برلاب',
  'مڠکين',
  'بليوڠ',
  'فوءاد',
  'تومڤت',
  'دايجا',
  'کرکوڤ',
  'دݢاري',
  'کماون',
  'ݢادوه',
  'سوديت',
  'سسوات',
  'تينجو',
  'ڤنمڤي',
  'ديکري',
  'روڠوس',
  'ݢرتيق',
  'ڤڠݢتر',
  'کوڽيت',
  'تيقڤي',
  'رينيق',
  'کرديل',
  'بادوس',
  'متنجس',
  'موڽوس',
  'باياو',
  'چلڤيق',
  'کريدس',
  'برچلق',
  'مڠکلي',
  'ممروم',
  'ترسرڤ',
  'دامڤو',
  'فويا٢',
  'ݢندوس',
  'لولوق',
  'دتندو',
  'ڤڽارا',
  'مناجا',
  'ليڠيڠ',
  'تقديم',
  'خاصيت',
  'دهشتڽ',
  'بلاتي',
  'ڤمالڤ',
  'ڤوليت',
  'ستݢوه',
  'دچاتو',
  'بلارق',
  'تريبن',
  'دحکوم',
  'ڤلڠکه',
  'باريه',
  'اومتڽ',
  'تاکيق',
  'قمرية',
  'کاڤلن',
  'دوڠون',
  'هارڤڽ',
  'ترلوه',
  'بڠکوت',
  'ڤندوا',
  'فلسفه',
  'الورڽ',
  'ڤادنن',
  'ميدنڽ',
  'ديڠݢي',
  'تکوقن',
  'ارتيڽ',
  'ترندم',
  'دڤاسق',
  'ملاوت',
  'ڤڠمڤو',
  'داليڠ',
  'مڠلڤت',
  'کوکري',
  'بوڠکڠ',
  'وراڠن',
  'برکمم',
  'بقسيس',
  'ڤينجل',
  'تيکيم',
  'برواڠ',
  'ڤڠکود',
  'نوسوس',
  'مورکي',
  'تربڠڠ',
  'دلنتق',
  'ستوڤا',
  'بردڠر',
  'چروتو',
  'چابيت',
  'دجبيق',
  'دادون',
  'مڠمبت',
  'ڤوليڤ',
  'سمبهن',
  'ستياڽ',
  'سجيوا',
  'دچکوه',
  'سوݢوق',
  'مسکوت',
  'فهرست',
  'بيسيڠ',
  'اومبي',
  'ݢمبرڽ',
  'براسڤ',
  'کرنچق',
  'بوروق',
  'تندور',
  'ڤکرتي',
  'توڠوڠ',
  'ڤڤيسق',
  'دساݢت',
  'ڤوڤيل',
  'ڤڠݢيل',
  'دباکر',
  'چاچاو',
  'چاکر٢',
  'ڤمادت',
  'ڤکاکق',
  'برابت',
  'ڤڽجتن',
  'رروکو',
  'دسامن',
  'دتانق',
  'باولو',
  'دڠکيق',
  'اودوت',
  'اڤکير',
  'ڤوسڤس',
  'حياتڽ',
  'ڤوچوت',
  'ڤنبسن',
  'دݢودا',
  'سڠکور',
  'ڤڠبسن',
  'سليبه',
  'کراجت',
  'روميت',
  'دجالن',
  'ڤنݢقن',
  'سومبر',
  'ليݢوڠ',
  'دتاتڤ',
  'دبابت',
  'بربان',
  'بروس٢',
  'انته٢',
  'اولڠي',
  'چاتيق',
  'برمڠݢ',
  'ستاکت',
  'ميريس',
  'بيڠوت',
  'ريڤوت',
  'دبيلا',
  'برنام',
  'ترجبق',
  'ليڽيت',
  'تونتو',
  'هروان',
  'کروقن',
  'سوجين',
  'مرادق',
  'تيڽيه',
  'چاليق',
  'بنارا',
  'ڤوکيت',
  'سݢارڠ',
  'بروڠس',
  'هوتنڽ',
  'ليڠکڠ',
  'وفاةڽ',
  'ساراو',
  'دجوال',
  'کڤلاݢ',
  'باتوق',
  'کدڠرن',
  'دڤڠکو',
  'چمارڠ',
  'برتمن',
  'مڠواق',
  'ترکاي',
  'دڤاڤه',
  'باٴون',
  'نعمتڽ',
  'جاهيت',
  'حکمت٢',
  'بياڠ٢',
  'جوڠکه',
  'ݢوريڠ',
  'دياري',
  'ريمبا',
  'کراچڤ',
  'سمڤنا',
  'سماوي',
  'ڤلاجر',
  'دأسوه',
  'روسوق',
  'سوراي',
  'امبلس',
  'ڤنياو',
  'سجنيس',
  'تيسو٢',
  'کلتيق',
  'بوݢوت',
  'سمبيت',
  'کتيکڽ',
  'سبواه',
  'منچرت',
  'منوجه',
  'ݢوتري',
  'ستوکا',
  'سنوکر',
  'کڤيتن',
  'اوسوت',
  'ڤستري',
  'فوبيا',
  'مڽوڠݢ',
  'سينيس',
  'رڠݢيه',
  'مغربڽ',
  'لتوڤن',
  'کلڤير',
  'سيکڤڽ',
  'ملندس',
  'حروفڽ',
  'ڤرومو',
  'بردسه',
  'برحال',
  'موريڠ',
  'سلاوي',
  'ڤرچا٢',
  'اٴولا',
  'ملاي٢',
  'سمبڠن',
  'ججالا',
  'کمبوه',
  'مندڠڤ',
  'هاور٢',
  'ݢلݢات',
  'منبڠي',
  'سهيبت',
  'ارتي٢',
  'مرديک',
  'منمڤس',
  'کويني',
  'سيݢنل',
  'بلورت',
  'منچکل',
  'ݢنديڠ',
  'سبنجر',
  'ݢادڠ٢',
  'سڤادن',
  'اسيرن',
  'ڤواقڽ',
  'جوابڽ',
  'کليچو',
  'ݢوبرا',
  'کادوت',
  'مهيرت',
  'جرڠکه',
  'ݢاجول',
  'برندل',
  'برچکه',
  'مراڤق',
  'مميتر',
  'رادس٢',
  'براکن',
  'برچيݢ',
  'دکاچن',
  'برتڤي',
  'سيسير',
  'سمواڽ',
  'کوٴيز',
  'ملڠکڠ',
  'چادور',
  'اڠکيت',
  'کلنتڠ',
  'امريس',
  'تورا٢',
  'دسنتڤ',
  'جادين',
  'ککڽلن',
  'لمبرن',
  'سڤنجڠ',
  'مڠاسه',
  'جودوه',
  'چمڤوڠ',
  'اورمس',
  'عبادة',
  'انيمو',
  'کليڤت',
  'کرمڤڠ',
  'برسکي',
  'کوميک',
  'کايوڽ',
  'ڤاندم',
  'بانير',
  'کرتون',
  'لوريڽ',
  'جڠکير',
  'ڤوکاي',
  'مرڤوت',
  'ملچوت',
  'بردوݢ',
  'رنتوڠ',
  'دتنون',
  'بودور',
  'ڤاسرڽ',
  'ڤرڠݢو',
  'مڠڠکڠ',
  'بيادي',
  'اڠݢڤن',
  'ڤادوق',
  'ترلڤس',
  'سمڤول',
  'سيڠست',
  'دليق٢',
  'رواسڽ',
  'سيݢوه',
  'ساٴوه',
  'ڤنتيڠ',
  'ڤيݢوه',
  'دڤڠکس',
  'مڠݢنڠ',
  'هندسة',
  'اسڤيک',
  'طهارة',
  'ڤلوبڠ',
  'دچلور',
  'خيمه٢',
  'مرواق',
  'دهمڤڤ',
  'ترلکت',
  'مفليس',
  'دريڠو',
  'چلبوق',
  'سمسيڠ',
  'چڠݢيه',
  'ڤمڠکه',
  'اوڠکو',
  'دچکوڤ',
  'سليرڠ',
  'سيمقن',
  'ڤرتون',
  'مڽاڽه',
  'ڤنوجو',
  'بومبن',
  'سسندي',
  'چيليق',
  'منچکر',
  'ڤجابت',
  'مڽليا',
  'سليڤڠ',
  'ميسوه',
  'ڤڠولق',
  'حاميل',
  'دسلمي',
  'کلاير',
  'کنچور',
  'ڤوکڠ٢',
  'دݢيلڤ',
  'سمندا',
  'مڠندق',
  'اودول',
  'لڠسيڠ',
  'مڠليه',
  'ملمڤر',
  'ݢلابه',
  'ڤمستن',
  'بيدوق',
  'بيليق',
  'کيربت',
  'کراجڠ',
  'ددادر',
  'چابور',
  'ݢاڤاي',
  'سجارڠ',
  'ريويل',
  'درچيق',
  'الون٢',
  'اوبنڽ',
  'تومبس',
  'مڠاول',
  'مودرت',
  'دتمڤل',
  'چرلوق',
  'ڠنتوق',
  'کوديق',
  'ماچنن',
  'کببلن',
  'منلکڤ',
  'کليڤ٢',
  'دودوق',
  'ولمان',
  'تربسر',
  'برکوق',
  'ݢلوجق',
  'ڤنتون',
  'دتوچڠ',
  'برتاي',
  'منجلڠ',
  'ݢوليڠ',
  'جوتوڠ',
  'ڤيهوڠ',
  'رنتيڠ',
  'برسنڠ',
  'تڠݢوه',
  'کراڤو',
  'جولوق',
  'رمبوڠ',
  'ڤماسڠ',
  'جانين',
  'مڠينڤ',
  'سبڠسا',
  'ترچݢت',
  'باجوڽ',
  'معرفة',
  'ڤيجق٢',
  'ڤالاو',
  'مڠيلي',
  'مريدڤ',
  'رمضان',
  'دسماي',
  'دڤندم',
  'دباجق',
  'ڤڽامر',
  'کوتو٢',
  'لڠکه٢',
  'ڽاريڠ',
  'ديڤوت',
  'براون',
  'کرچوڤ',
  'رڠکهن',
  'بالوق',
  'دݢوبه',
  'دسادڤ',
  'دسودو',
  'ݢالين',
  'جوهور',
  'دتيکن',
  'توروت',
  'تلاعه',
  'ريندڠ',
  'انتوڤ',
  'مڽوبت',
  'تيمبل',
  'دݢوݢت',
  'ڤڽرڤن',
  'ترونا',
  'دوستا',
  'چرڠکن',
  'جاٴول',
  'جاوهر',
  'بولوڠ',
  'مڠاڤم',
  'ڤڽيوا',
  'ݢندلن',
  'دتاره',
  'دهمبت',
  'ݢوروه',
  'تراجم',
  'ڤرياڠ',
  'اوتيت',
  'ايذين',
  'دڠکيڽ',
  'بروفق',
  'تيروس',
  'انق٢ڽ',
  'تبڠاو',
  'مميکا',
  'سيمبت',
  'انعام',
  'ڤلمڤس',
  'لوڤوڠ',
  'برحسد',
  'ݢاريڠ',
  'اݢامڽ',
  'ڤلاين',
  'لوروت',
  'نوکيل',
  'سنيون',
  'ترترا',
  'انتاو',
  'باجو٢',
  'ڤيتيڠ',
  'مورکڽ',
  'ڤمبنڠ',
  'ڤينقڽ',
  'ساعتڽ',
  'هيريڠ',
  'برعام',
  'هداية',
  'فيسکل',
  'وهيکن',
  'مڠرتي',
  'هبوان',
  'سلمبو',
  'سوڠاي',
  'مننتڠ',
  'ڤيڤيس',
  'مسلين',
  'ساکور',
  'ڤواسڤ',
  'ڤرتيڽ',
  'سرانه',
  'مليڠر',
  'بنديت',
  'رمنيا',
  'ڽريڠڽ',
  'مڽونم',
  'تيکوڠ',
  'مومين',
  'برحال',
  'اچومن',
  'سيالن',
  'سکولن',
  'دريبر',
  'سيکشن',
  'ايݢاو',
  'دڤيال',
  'سراون',
  'بوروڽ',
  'دچرچا',
  'ويليس',
  'کاݢوم',
  'درنتڠ',
  'کودوڠ',
  'ۏونيس',
  'ايتوق',
  'دسامق',
  'دسونت',
  'جاٴور',
  'دوکوت',
  'سکمبو',
  'بربچق',
  'فينول',
  'ݢروسن',
  'بڠکيت',
  'امڤوه',
  'ملنتس',
  'برکرڠ',
  'کرامه',
  'دتلکڤ',
  'جمبيه',
  'چوکيق',
  'دأوڤه',
  'بردڠڤ',
  'لوکيه',
  'داتيا',
  'دچلور',
  'هاراو',
  'لويوڠ',
  'برسيا',
  'کمبرا',
  'تحصيل',
  'دتنچڤ',
  'مناتل',
  'حواري',
  'ڤڠچسن',
  'کرنيا',
  'ترمبو',
  'سرکوڤ',
  'ماجلڽ',
  'نيلون',
  'ساٴيڠ',
  'تکاٴن',
  'براݢم',
  'نامکو',
  'منمڤل',
  'ريلبس',
  'ڤريوق',
  'دتاڠس',
  'ڤرنتس',
  'عوان٢',
  'تربوک',
  'ڤنݢهن',
  'چوروت',
  'مڠوبڠ',
  'بولسق',
  'بياقڽ',
  'ڤياق٢',
  'امبلس',
  'ڤڤيسڠ',
  'ملسيت',
  'کيلڠن',
  'لوڤيس',
  'لوکوس',
  'سڤيرق',
  'چيليق',
  'وسيلة',
  'ترلچه',
  'برسام',
  'ساڤاي',
  'جوڠوس',
  'دکڽيت',
  'دايوڠ',
  'سڤياق',
  'دوڠيڠ',
  'منڠهي',
  'دويان',
  'ابڠکو',
  'کيلير',
  'سباسه',
  'براکه',
  'عالمي',
  'موسيم',
  'فيلمڽ',
  'کوکور',
  'بهترا',
  'سبنرڽ',
  'برمبن',
  'ڤلاوس',
  'لامون',
  'اخبار',
  'مماره',
  'لاروت',
  'کوڽوڠ',
  'يوجان',
  'سمانه',
  'دکڤيڠ',
  'دکارڠ',
  'تمييز',
  'ݢازيت',
  'بيريس',
  'کڤدسن',
  'ݢوندو',
  'ديروس',
  'رينچو',
  'چکاتن',
  'چروبه',
  'دأورڤ',
  'بڠکيڠ',
  'مڠاکي',
  'امبه٢',
  'اکسين',
  'ۏولوم',
  'بونتت',
  'مولود',
  'تودوه',
  'درلاي',
  'ݢنتيه',
  'مغلوب',
  'بوڤيت',
  'چيچيه',
  'موروڠ',
  'توݢيل',
  'ابادي',
  'چکيقن',
  'سکديڤ',
  'ڠاچير',
  'کبونڽ',
  'ڤنجي٢',
  'عذابڽ',
  'ݢالقن',
  'ملمبڤ',
  'کڤايت',
  'همڤدو',
  'سماور',
  'مڽاهڤ',
  'جمڤين',
  'سکالا',
  'کتيلا',
  'لوسين',
  'راڤوڠ',
  'توکول',
  'سمڤوا',
  'ڤريوا',
  'سيراي',
  'منچوڠ',
  'سبورو',
  'مڠلان',
  'مڠورس',
  'دهيدو',
  'ممڤرت',
  'تردمم',
  'دومبا',
  'دکروق',
  'رڠکيڠ',
  'کيکيه',
  'دادوق',
  'بونتل',
  'برتاڽ',
  'ڤلاري',
  'ݢوتوڠ',
  'ڤکيقن',
  'باتڠن',
  'ڤڠمبت',
  'کهوا٢',
  'منداڤ',
  'جالرن',
  'کرياڠ',
  'چهايڽ',
  'تمبيڠ',
  'نفيري',
  'توليه',
  'بوراي',
  'کدايڽ',
  'علاقة',
  'کتيره',
  'دسيرڤ',
  'ڤرياڠ',
  'تانور',
  'بيبيل',
  'دسندت',
  'سروال',
  'اوڠݢس',
  'ريسيق',
  'ماجوڠ',
  'سادير',
  'ايبو٢',
  'اوچوس',
  'ݢليتق',
  'ميليو',
  'سسيبر',
  'کرينه',
  'ڤنرفن',
  'امکان',
  'ملاجو',
  'مانيق',
  'راداي',
  'رانوم',
  'مڽمڤه',
  'راڠاي',
  'دسدڠي',
  'ݢرڽوت',
  'ڤڽيرق',
  'سموتن',
  'ڤنولق',
  'کسدقن',
  'لامور',
  'نيسبه',
  'بتاهق',
  'براڤي',
  'بهارو',
  'کليقي',
  'ميديو',
  'رنديه',
  'روموق',
  'بهاي٢',
  'تاتيڠ',
  'کتوڤت',
  'هنتين',
  'سوارڠ',
  'بيجاي',
  'رڽوق٢',
  'منستو',
  'بايڠ٢',
  'انتوي',
  'ڤنلهن',
  'بيڠکڠ',
  'ريساو',
  'اهسان',
  'تندا٢',
  'مڽيدق',
  'مڠاره',
  'ممبلي',
  'ريديڠ',
  'دکتيق',
  'چوکيت',
  'اڠسوک',
  'دڤينڠ',
  'ݢربوس',
  'مماسڠ',
  'بربنه',
  'ميتيل',
  'اوکوق',
  'اويود',
  'ڤڠاول',
  'دفتنه',
  'دلسيڠ',
  'منتله',
  'هنتو٢',
  'دݢاڽڠ',
  'باڽق٢',
  'کليات',
  'ڤڠيدر',
  'دجواڠ',
  'سوتور',
  'بوسيت',
  'سڤادن',
  'اڤرون',
  'چمڤرا',
  'کيمڤا',
  'مڠکول',
  'مڽامن',
  'دبيري',
  'مرڤوي',
  'تسبيح',
  'توڤوڠ',
  'ݢمبرن',
  'چاکڤن',
  'دبڠکن',
  'چاکاو',
  'ميقات',
  'بروتو',
  'شقدوف',
  'ريڠنڽ',
  'ݢويه٢',
  'للابه',
  'لاديه',
  'دݢيلس',
  'جولاي',
  'موجور',
  'تعيين',
  'ݢويڠن',
  'چيبيه',
  'بردکت',
  'ݢروسق',
  'کتواو',
  'داتور',
  'ݢرڽاو',
  'مڠچوڤ',
  'چاکيڠ',
  'ڤيڠين',
  'ݢلتيق',
  'سيتير',
  'ليسوڠ',
  'سڠکوه',
  'لاسوق',
  'برکڤل',
  'اورنا',
  'بريما',
  'منچڤت',
  'ڤچوتي',
  'باتين',
  'داجرڽ',
  'تجربة',
  'چيڤلق',
  'اوڤهن',
  'ويجاي',
  'لنچور',
  'ريلۏن',
  'ايرس٢',
  'واکيل',
  'ڤوچوق',
  'کيڤڠن',
  'برڤچه',
  'تموت٢',
  'ککابو',
  'سجوکر',
  'شيليڠ',
  'ڤڽکڤن',
  'ببارو',
  'لنجوت',
  'برڠکڤ',
  'دسيرت',
  'مڠݢيس',
  'ݢونتق',
  'کوووڠ',
  'مڽاتي',
  'ݢڠݢم٢',
  'اولت٢',
  'اونتل',
  'مڠادر',
  'سلوکي',
  'تنوسو',
  'لاجو٢',
  'دجامه',
  'دسايت',
  'چبيقن',
  'سمياق',
  'ملينڠ',
  'مرلوه',
  'منتري',
  'اوتيه',
  'داکين',
  'برڤچه',
  'موڤهم',
  'دتنتڠ',
  'سمبين',
  'ݢندين',
  'ترتݢه',
  'منالڠ',
  'تڤوقن',
  'موافق',
  'ڤڠݢاو',
  'بوسور',
  'چالوق',
  'اڤاته',
  'اوجان',
  'روتاي',
  'تاڽکن',
  'سياست',
  'بنچوت',
  'سرليڠ',
  'ددندا',
  'ڤميدڠ',
  'تکريم',
  'رسدوڠ',
  'ڤادوڽ',
  'ساجقڽ',
  'ايرسن',
  'اربيي',
  'کواڤڽ',
  'سکواس',
  'ککارا',
  'مڽيمق',
  'دتمڤا',
  'ممبدل',
  'بوککن',
  'ڤنبڠن',
  'ڤڠاوس',
  'کريتا',
  'سياکن',
  'ݢوديݢ',
  'لڤسکن',
  'مݢنيت',
  'سومبي',
  'کريڠڽ',
  'داداڽ',
  'مميجر',
  'ڤولکا',
  'جاتيق',
  'برݢلر',
  'کومڤڠ',
  'بوکو٢',
  'دسيبق',
  'سجواڠ',
  'برچکڤ',
  'مڠاتس',
  'مراکت',
  'برکنن',
  'مالو٢',
  'دڤڤات',
  'مڠمبل',
  'منيرن',
  'جونمن',
  'کات٢ڽ',
  'دݢمکن',
  'ڤماتي',
  'مڠيڤڠ',
  'ڤلاسق',
  'کورا٢',
  'ڤرچيت',
  'ڤنياݢ',
  'چلوڤس',
  'دراته',
  'سلڠکس',
  'جلمڤه',
  'سروݢت',
  'ڤامڤن',
  'بيتي٢',
  'دڤقسا',
  'دورجا',
  'دايوه',
  'هيندر',
  'تلنچڠ',
  'اڠݢيه',
  'چوچور',
  'دبوڠا',
  'دسݢني',
  'تيمه٢',
  'فطانة',
  'ڤمبلا',
  'دڤاچق',
  'ريچوق',
  'الفيه',
  'لنچرن',
  'بنچاو',
  'داٴور',
  'ڤورتر',
  'رڽاي٢',
  'تحيول',
  'بلودر',
  'داجون',
  'ݢرنچڠ',
  'جمبوا',
  'ڤومڤڠ',
  'اووير',
  'کراکڤ',
  'مريان',
  'قولوق',
  'جلاڤق',
  'کوکوه',
  'سيويل',
  'ݢويور',
  'رامبن',
  'باتله',
  'کݢلين',
  'اوڠݢت',
  'چڤياو',
  'دهينا',
  'اٴورا',
  'برتکڤ',
  'ݢوݢوه',
  'ساودڠ',
  'سمسير',
  'مڠڠݢڤ',
  'کوايا',
  'ݢرهان',
  'دنياڽ',
  'دچيتن',
  'ايسيڽ',
  'زوفيت',
  'بيديڠ',
  'جوريڠ',
  'کلاتي',
  'چمارا',
  'نونوڠ',
  'چوڠور',
  'ڤوليڠ',
  'ݢيريڠ',
  'برتيݢ',
  'ستوتر',
  'برڤري',
  'ليچيس',
  'جوٴيه',
  'سوهوڽ',
  'امڤاي',
  'دکڠکڠ',
  'سلندر',
  'مڠولڠ',
  'برسيه',
  'سمباي',
  'ککجڠن',
  'ڤڠارم',
  'ڤاتيو',
  'مليده',
  'ݢلبوڠ',
  'تاروم',
  'رندهڽ',
  'کيچوه',
  'کوايه',
  'لانون',
  'ݢرايڠ',
  'سوسيو',
  'منتاي',
  'مندرڤ',
  'لاسوه',
  'سوکون',
  'ݢادا٢',
  'اورݢن',
  'بڠساڽ',
  'ݢاديق',
  'ريويت',
  'تمڤوه',
  'واسطه',
  'قلقله',
  'ساتو٢',
  'برعقد',
  'برحجه',
  'جڠݢوت',
  'امڤوس',
  'کلمات',
  'کيکيل',
  'بالوي',
  'کݢمسن',
  'ڤرتاڤ',
  'بردڠڤ',
  'همستر',
  'برمبو',
  'کوتيق',
  'بوهوق',
  'ڤاتيس',
  'هنچور',
  'بوتول',
  'دسيرت',
  'ملندا',
  'ديچيه',
  'سيکيه',
  'اولسر',
  'لينتو',
  'جمبڠن',
  'جارمن',
  'بربلس',
  'مڠابر',
  'ݢبوقن',
  'تنداو',
  'کلوڤس',
  'ڤايهڽ',
  'مننتو',
  'بربنڠ',
  'سيارة',
  'مليڤا',
  'سجولي',
  'برچله',
  'جرلاي',
  'هرنيا',
  'ڤلسڤن',
  'رچيقن',
  'هاڤوس',
  'ڤڠتيق',
  'سکاجڠ',
  'ڤرڠݢس',
  'باسوق',
  'بڠکوه',
  'سساوي',
  'اڠݢوڠ',
  'موڠݢو',
  'ايديت',
  'مڽماي',
  'چوکاي',
  'تيڤيق',
  'فرکسي',
  'تورق٢',
  'سرڠݢه',
  'لنداي',
  'دراوت',
  'سامڤت',
  'مڠوڤق',
  'دايکت',
  'ڤرتي٢',
  'توبين',
  'ڤنتڤن',
  'ڤنورس',
  'کربون',
  'منيبن',
  'ديکيت',
  'لايڠ٢',
  'چينتا',
  'سلولڠ',
  'مڠجيک',
  'ڤيڽيق',
  'دتڠݢڤ',
  'سڤوچت',
  'چلڤوق',
  'ݢونتڠ',
  'کدکيق',
  'کارس٢',
  'ڤمجمن',
  'برجين',
  'الغنى',
  'رويوڠ',
  'تيڠکل',
  'ڠيسوڤ',
  'سواڤن',
  'سماجو',
  'کوريڠ',
  'ڤڠروه',
  'دڤارڤ',
  'دبوکڽ',
  'برتاي',
  'کوڤرا',
  'مڠيکت',
  'ممندا',
  'چيچيل',
  'مڠودر',
  'سمايم',
  'ريتيت',
  'دکوچق',
  'بوموه',
  'سنڠله',
  'کڽرين',
  'چادوڠ',
  'ملوتو',
  'ايبون',
  'ساندو',
  'فورمت',
  'ݢنتاڠ',
  'ڤيريق',
  'تاکوق',
  'هوجنڽ',
  'بوۏين',
  'برکلر',
  'ارهن٢',
  'ستريا',
  'اوچوق',
  'بواڤق',
  'بنجير',
  'دترنق',
  'دربوت',
  'ترنده',
  'روليت',
  'دسکتڽ',
  'سڤوڽا',
  'جوراي',
  'بلمبڠ',
  'ملڠݢن',
  'فيليت',
  'مليدڠ',
  'سايو٢',
  'فاتور',
  'چرڠݢه',
  'اڠݢرن',
  'براتڤ',
  'برساب',
  'ماکاو',
  'ججواڠ',
  'ݢنترن',
  'لاتور',
  'ݢاݢاو',
  'ججامو',
  'براݢق',
  'ݢيلا٢',
  'ليندو',
  'ڤملوق',
  'ݢوسوق',
  'بردتر',
  'تڠه٢ڽ',
  'ليڤيت',
  'جاجهن',
  'دمکين',
  'ڤوروس',
  'تيمڤڠ',
  'چوڠکه',
  'مڽيبا',
  'کيسوت',
  'باݢين',
  'ڤانهڽ',
  'ڤمبلس',
  'ڤيسيق',
  'هومبس',
  'بوڽور',
  'بربنر',
  'برمݢه',
  'سومور',
  'مڠجوت',
  'تڠلين',
  'اولهن',
  'بالوڠ',
  'جمواس',
  'ديکسي',
  'دڤنچڠ',
  'برسوک',
  'ڤݢوتا',
  'ابيسي',
  'کرنچڠ',
  'حوالة',
  'بنداڽ',
  'مڽتيم',
  'چادير',
  'سيتو٢',
  'رنيڠ٢',
  'جوکوڠ',
  'باٴوڽ',
  'بليان',
  'ميڽق٢',
  'صلاةڽ',
  'کرکاو',
  'ڤمبده',
  'حرمتڽ',
  'تتڤوڠ',
  'روڠوس',
  'ݢرسول',
  'بوسوڠ',
  'مڠاجر',
  'تمبوس',
  'لاٴيه',
  'رستوڠ',
  'برتتر',
  'ترلکڠ',
  'دبنچي',
  'کلداي',
  'بوڠور',
  'دچنچڠ',
  'بيسين',
  'دڤوجا',
  'کداين',
  'بولن٢',
  'موندر',
  'چڤواه',
  'چيکون',
  'ڤوستا',
  'جيوات',
  'تاوچو',
  'وهدية',
  'داتڠڽ',
  'سييرا',
  'سامکو',
  'اورت٢',
  'تمڤلق',
  'دوڠکق',
  'دکريت',
  'سڤواق',
  'تارق٢',
  'تاهيل',
  'سماچم',
  'سنجاي',
  'دکنجي',
  'ڤنرڠن',
  'مڠايڤ',
  'بڠکو٢',
  'اوردي',
  'اينچڠ',
  'ڤلوڤق',
  'منواس',
  'ممنچر',
  'موکوس',
  'برکلت',
  'تعصوب',
  'راڠک٢',
  'ماموڠ',
  'مڽيمه',
  'اولرن',
  'مروين',
  'مليلا',
  'دڤانه',
  'جمڤان',
  'هاٴوس',
  'اورا٢',
  'مڠݢݢس',
  'کندرا',
  'خطيفه',
  'ريجيم',
  'دچکيق',
  'ݢيݢيڽ',
  'مڠويق',
  'دجارم',
  'دايون',
  'ماݢون',
  'ايريت',
  'لنچيت',
  'ملنچڤ',
  'کلويو',
  'ريمڤڠ',
  'ليڤوه',
  'تݢوقن',
  'ويجين',
  'مرڠݢس',
  'ڽاڽوق',
  'ݢورور',
  'بلوکو',
  'علمو٢',
  'ڤڤاري',
  'سوکوم',
  'کنديد',
  'منمڤق',
  'ارسون',
  'کوماڽ',
  'کمبيڠ',
  'تمڤلن',
  'دجولڠ',
  'تعيين',
  'ڤڽامن',
  'مميدي',
  'دبڠکڠ',
  'بيلوت',
  'سروما',
  'بوڤوڠ',
  'الامن',
  'ݢلودق',
  'برانق',
  'ڤڠاجق',
  'لينتي',
  'خلمبق',
  'امام٢',
  'ڤلکڤن',
  'نمڤق٢',
  'دشمڤو',
  'سبودي',
  'دجارا',
  'جمبوڠ',
  'ݢلاݢر',
  'بردڤن',
  'کوکوڽ',
  'کيجيڠ',
  'بونوت',
  'باکل٢',
  'ݢينچه',
  'منکسي',
  'طريقت',
  'دهينا',
  'ستاݢل',
  'لاوه٢',
  'مراون',
  'ݢليبر',
  'تحميد',
  'کيسير',
  'مڠنڠي',
  'دکرکه',
  'ڤراڠت',
  'تاتاي',
  'برونڠ',
  'دجواب',
  'ݢنچير',
  'کلوۏر',
  'ليکور',
  'تاجوق',
  'اڠݢاي',
  'چاوڠن',
  'اوسوق',
  'بوبون',
  'اڠکور',
  'کرنتا',
  'مندوڠ',
  'دکاچر',
  'چاتتن',
  'لومور',
  'تاسيق',
  'لوݢم٢',
  'مخففة',
  'چماتي',
  'دأيون',
  'بربنق',
  'ايراو',
  'دڤاسڠ',
  'مڠوبه',
  'ترتيب',
  'سݢوق٢',
  'لمڤوق',
  'تابيل',
  'مڽيڤي',
  'لوسيڠ',
  'شينتو',
  'دبنچي',
  'روݢوه',
  'راڤوس',
  'تتمبق',
  'ککجمن',
  'برجڠک',
  'دݢوڤن',
  'ديقسا',
  'بهارا',
  'مڠليس',
  'ميندا',
  'اجوتن',
  'لبوچي',
  'مڠحفظ',
  'ݢمبوڠ',
  'روبيق',
  'هوجوڠ',
  'سلورو',
  'جرامه',
  'مستيڽ',
  'ماڤيل',
  'ڤاته٢',
  'ڤنتيس',
  'جارقڽ',
  'مناتڤ',
  'ݢلوڤر',
  'ددندا',
  'ترسلت',
  'بروچي',
  'بڠکرڤ',
  'دتوجه',
  'ستوڤن',
  'مڠاڽم',
  'جريهڽ',
  'سميلو',
  'کلاکر',
  'ڤارڠ٢',
  'تڠالن',
  'ڤمولا',
  'اڤوڠ٢',
  'لاڤير',
  'کبسرن',
  'اڠلوڠ',
  'ڤڽولڤ',
  'ڤوتوس',
  'اينڠ٢',
  'سسترا',
  'دݢومم',
  'ڤردوس',
  'کوتيڠ',
  'موريه',
  'ڤڠݢلن',
  'ڤنرڤن',
  'چندول',
  'جوجوه',
  'ارودا',
  'دکوڽه',
  'دڤاکو',
  'بوريک',
  'مثالي',
  'کليڤن',
  'ادوان',
  'مڠنتل',
  'ريبيت',
  'کيچوڠ',
  'سکرجا',
  'کالاڽ',
  'مليبت',
  'مڠندل',
  'کاجاو',
  'لڠݢاي',
  'کونڠن',
  'کنتيه',
  'ڤورده',
  'امبلن',
  'موبوت',
  'رينتق',
  'کلبور',
  'دسمڤو',
  'تمڤوس',
  'توڤدل',
  'بيندر',
  'ملوبي',
  'دساجي',
  'ڤساله',
  'ايکتڽ',
  'ݢدهوق',
  'کڠکوڠ',
  'تردکت',
  'کبابل',
  'ترباچ',
  'اوردي',
  'ڤاتهن',
  'برسري',
  'ماسوي',
  'کيليق',
  'اينق٢',
  'باڽقڽ',
  'کولوم',
  'ڤلکوق',
  'ڤنيڤو',
  'سولوڠ',
  'سکواش',
  'کارو٢',
  'کڽوتن',
  'دلوده',
  'ستروم',
  'برندا',
  'مڠريم',
  'برسيڤ',
  'ڤڠندر',
  'لونچو',
  'لاکيڽ',
  'تفصيل',
  'دالوم',
  'ڤناره',
  'ݢراڽم',
  'کابوڠ',
  'براسو',
  'سکيرا',
  'ڤمݢڠ٢',
  'مندڤا',
  'کاساو',
  'اوسيق',
  'لواس٢',
  'دسندي',
  'توتوه',
  'سيڤه٢',
  'سوسه٢',
  'لڤاري',
  'جرمبڤ',
  'سايوڤ',
  'ليواه',
  'سڠکيل',
  'لاديڠ',
  'مڽياڠ',
  'سڤڠسا',
  'دنچيڠ',
  'لومار',
  'منمبه',
  'عاريف',
  'کيجيل',
  'ملنتق',
  'مروکو',
  'چوٴيل',
  'چوڤيل',
  'ݢومبر',
  'ڤنجيڠ',
  'ترادا',
  'بوبول',
  'کلويق',
  'ججمبت',
  'لومڤي',
  'هونوس',
  'دلبور',
  'وايما',
  'قرينه',
  'تيکير',
  'دراڠک',
  'کاليس',
  'دليل٢',
  'بواڠن',
  'کداڠا',
  'ايکيه',
  'دريان',
  'ايڠتن',
  'بونکر',
  'تاٴوت',
  'لوماي',
  'حاکيم',
  'ݢرودا',
  'راچون',
  'سرابي',
  'تومڤي',
  'لوهوق',
  'برامس',
  'هايلم',
  'تريما',
  'ليسين',
  'جابوڠ',
  'کوهول',
  'سمڤه٢',
  'مڠڤيڠ',
  'تيماڽ',
  'ڤونچق',
  'برکات',
  'چلادق',
  'کتاڤي',
  'اوردى',
  'ڤلاڠه',
  'کتاوا',
  'چڠݢاي',
  'ڤونسو',
  'موابق',
  'کماهڠ',
  'مڠوڤي',
  'باتسڽ',
  'هولوڽ',
  'داليه',
  'ڤمچتن',
  'ڤڠتوس',
  'ڤلواڠ',
  'ليڠکس',
  'تمبلڠ',
  'تينوڠ',
  'لاراي',
  'کلاري',
  'سماتي',
  'ترچلق',
  'اينچا',
  'مشهور',
  'هايکو',
  'کتواڽ',
  'سلطان',
  'هاڠت٢',
  'کابوس',
  'کونچي',
  'ريکوت',
  'ايهمو',
  'دسنتق',
  'بلاچق',
  'ليندق',
  'ڤوڤوق',
  'برايه',
  'کوکوت',
  'ݢراڤق',
  'باتري',
  'چيڤره',
  'مرتيل',
  'بسوسو',
  'سماهل',
  'تحسين',
  'برختم',
  'سلنچر',
  'ملاݢڠ',
  'کمبور',
  'برددق',
  'ݢلديڠ',
  'دݢارو',
  'الوسن',
  'کليوت',
  'ليمڤڤ',
  'ڤاتوه',
  'بيوݢس',
  'بردهم',
  'ڤڠݢول',
  'کمامڠ',
  'مراوا',
  'سسودو',
  'دچيتق',
  'ماتي٢',
  'نيراڽ',
  'ڤاکيس',
  'ڤنتقن',
  'تيميه',
  'راهڠڽ',
  'بلاڠا',
  'تاڤاي',
  'کجمعن',
  'ڤڠلوه',
  'دچابر',
  'سيلوق',
  'بوتوق',
  'لوناو',
  'برنتڤ',
  'ڤاتوق',
  'ورنا٢',
  'اکوق٢',
  'چرمتڽ',
  'تاويس',
  'ريڤاي',
  'مڠودر',
  'سمڠکو',
  'کلونق',
  'ݢلوڠن',
  'کنچڠڽ',
  'ڤنوبا',
  'اقامة',
  'بولڠن',
  'رنچان',
  'کترڠن',
  'دبورو',
  'بالاو',
  'ڤمبرت',
  'لوکيس',
  'باکيق',
  'شعبان',
  'سوفلي',
  'ڤتوتو',
  'مڽادڤ',
  'تالاي',
  'جاريڠ',
  'تياڠڽ',
  'ڤڠݢاݢ',
  'ممڤرڠ',
  'اينقڽ',
  'چمبيس',
  'ڤولوڠ',
  'مندير',
  'ايسڠ٢',
  'چوميق',
  'ڤڠيکن',
  'دتندس',
  'سقدرڽ',
  'ݢلوبل',
  'بدارا',
  'مرسوم',
  'ڤلنتق',
  'کينچت',
  'دسيوا',
  'کاڤو٢',
  'بيݢول',
  'ممندو',
  'چمبلڠ',
  'دراجڠ',
  'سيسير',
  'ڤلاذت',
  'براڤت',
  'برݢته',
  'مڽرݢه',
  'ۏيددا',
  'ڤڠاتس',
  'داسور',
  'مڠاسي',
  'صحيحڽ',
  'اودوق',
  'ڤوروق',
  'اجاهن',
  'مغرور',
  'حاصيل',
  'ميمبر',
  'درسان',
  'سرمبب',
  'جڠݢور',
  'بالوه',
  'مرامس',
  'ممباج',
  'چندرا',
  'دکچڤي',
  'دسياه',
  'اوروڠ',
  'ڤڠتوا',
  'ميڤير',
  'ڤواسڽ',
  'لوندي',
  'کتݢقن',
  'ڤاڤن٢',
  'چموهن',
  'مربوڠ',
  'لوچوڽ',
  'بلاير',
  'دبنتڠ',
  'کروان',
  'ڤولدن',
  'دلنتق',
  'بوايا',
  'اٴوبد',
  'ددياڠ',
  'اڠکت٢',
  'ڤڠاره',
  'مرقسي',
  'محريم',
  'چوراي',
  'لوچور',
  'کورما',
  'صحيفة',
  'دروجه',
  'لنتوڠ',
  'تتوهو',
  'چينتن',
  'کوسين',
  'برامه',
  'لوچيڠ',
  'جمهور',
  'جينرل',
  'چمامر',
  'تيکر٢',
  'ربوتن',
  'دسنڠي',
  'سڤيتڽ',
  'مڠاݢق',
  'دبنکن',
  'ڤڠندس',
  'کڤاري',
  'دنتمن',
  'مراته',
  'کرتوق',
  'داتڠن',
  'دحدکن',
  'کسينن',
  'برچکق',
  'ترتبت',
  'کبورو',
  'بتيسڽ',
  'کجݢڠن',
  'قانون',
  'ڤمعاف',
  'لوندڠ',
  'ڤورات',
  'مڠورڤ',
  'کسرين',
  'ليمبق',
  'مڠاݢن',
  'چيواڠ',
  'ريکوه',
  'ابسيس',
  'اندلن',
  'روادة',
  'دمبار',
  'لاڤور',
  'نامون',
  'نادير',
  'دچڠکم',
  'ملوکه',
  'سمارق',
  'مڠوجي',
  'ولاية',
  'ڤڠرسن',
  'ملامر',
  'مڽيتا',
  'ليڠکڤ',
  'سروکن',
  'مراکه',
  'مڽيرت',
  'برسيه',
  'سوروق',
  'مرنتس',
  'ݢݢامن',
  'مڠوڤر',
  'ڽمڤڠ٢',
  'ڤوچيس',
  'مڠاله',
  'ددهکن',
  'مڠمسي',
  'کواي٢',
  'برسيل',
  'دبيوس',
  'دڤوجي',
  'تيمون',
  'عناية',
  'اسيسي',
  'مراڠک',
  'دڤرون',
  'ݢيلي٢',
  'جڠکيڠ',
  'ليݢيه',
  'دڠکور',
  'ليترل',
  'سکوتو',
  'دابوس',
  'دامبق',
  'ڤبيسي',
  'کسنين',
  'دسڤوه',
  'ترالڠ',
  'بيهوش',
  'منوال',
  'مڠاسڤ',
  'جاوتن',
  'ڤنواس',
  'کرڠکم',
  'تيليق',
  'الدين',
  'بوقچا',
  'کدموت',
  'چلاتڠ',
  'چمڤيڠ',
  'دسالڤ',
  'بولوس',
  'تيڤيت',
  'مريهڽ',
  'برندم',
  'اوموت',
  'ڤلمبت',
  'ملاتم',
  'رابوق',
  'ايڠا٢',
  'ليبتن',
  'کومين',
  'ڤاکيج',
  'جاݢله',
  'سممڤڠ',
  'جيچيڠ',
  'برچکه',
  'جيڠکت',
  'سرنتي',
  'چومي٢',
  'مڠاکڤ',
  'کريسڽ',
  'بيايڽ',
  'سڤاتو',
  'سهوجن',
  'رافيا',
  'ڤسيره',
  'کاديت',
  'کجوت٢',
  'ڤمببت',
  'عدالة',
  'بليرا',
  'ڤڽرڠن',
  'ڤلاتو',
  'ستوري',
  'سيکوڽ',
  'بيني٢',
  'ڤرونا',
  'بولير',
  'ترللڤ',
  'رويوڤ',
  'لڠواي',
  'ڤرڠکڤ',
  'مڠياڠ',
  'کمبيه',
  'سيروڠ',
  'دوݢوڠ',
  'توميس',
  'درميس',
  'سلمبت',
  'دسڠݢه',
  'لومت٢',
  'هيݢيه',
  'مڠيلي',
  'مولا٢',
  'منيکن',
  'چمالا',
  'برتبڠ',
  'کوريس',
  'لويوت',
  'منججل',
  'ڤاجوه',
  'ݢاريت',
  'بوکله',
  'دتاته',
  'رقساس',
  'کيڠوا',
  'تانه٢',
  'چاٴيس',
  'لنتوم',
  'مڠلوي',
  'تيتيق',
  'بيبسڽ',
  'سيمبل',
  'بردبر',
  'سکامر',
  'کيرا٢',
  'بديلن',
  'چچوري',
  'ݢمنتم',
  'سيناݢ',
  'دچبيق',
  'مڠارت',
  'ڤلوچو',
  'ڤمڤسن',
  'کوندو',
  'سولوه',
  'امبور',
  'رمڤوس',
  'بدارو',
  'کأرتن',
  'کرسول',
  'دتوݢس',
  'ڤندڠر',
  'بانتر',
  'ڤموکت',
  'ايجوق',
  'ݢوڽيل',
  'مناکق',
  'ممبيو',
  'ݢوݢوس',
  'راديف',
  'بردرڠ',
  'دچبيس',
  'مرڽوق',
  'سکيتر',
  'منياݢ',
  'نذرکو',
  'کرندڠ',
  'مليدي',
  'ريميه',
  'ايبمڽ',
  'امبوت',
  'دڤڤهڽ',
  'مرانڠ',
  'ڤولڠڽ',
  'ملنجن',
  'ڤتوڤن',
  'انداس',
  'ملومس',
  'کمندڠ',
  'کيمڤل',
  'ککاچڠ',
  'ڤڠرکه',
  'ررياڠ',
  'مڠرتڠ',
  'کمچيڠ',
  'برسکت',
  'هيتوڠ',
  'ليمڤا',
  'ددوڠق',
  'جيروس',
  'کولوم',
  'کيراڽ',
  'وارا٢',
  'چوميق',
  'بوکوڤ',
  'مراکه',
  'عادت٢',
  'مڽاݢت',
  'سمبيه',
  'ۏينيل',
  'لادڠڽ',
  'کيمبڠ',
  'دسورت',
  'دوبوق',
  'بوسوق',
  'ڤروسا',
  'ݢيڠکو',
  'هاري٢',
  'دلارڠ',
  'اولمڽ',
  'امڤوڤ',
  'دوکوه',
  'الحکم',
  'کچياق',
  'لاڤوي',
  'سڤورا',
  'برهاب',
  'ملدوق',
  'هولا٢',
  'سراکه',
  'بالاي',
  'ݢوانو',
  'چاباو',
  'دݢومڽ',
  'کوجول',
  'ليسني',
  'سرياڤ',
  'برنتق',
  'دارون',
  'ڤنبوق',
  'کونتن',
  'دباجي',
  'ترلوه',
  'باسيس',
  'ݢوامڽ',
  'ڤنچڠ٢',
  'لاسين',
  'ڤکاسم',
  'ديکير',
  'امراء',
  'ݢوندڠ',
  'دسندل',
  'جماوا',
  'باݢوڤ',
  'ملنجا',
  'ڤرݢوق',
  'مڠکير',
  'ڤڠامت',
  'ايڤيک',
  'کنجيڽ',
  'جواڠ٢',
  'الوصي',
  'ڤمودي',
  'برعمل',
  'ماسقڽ',
  'برسبر',
  'بڠکير',
  'لوچوڤ',
  'داميق',
  'ݢلڠسر',
  'مڠيده',
  'مننچڠ',
  'ملنجا',
  'مڠبوت',
  'ڤلباي',
  'ڤيڠاي',
  'تومڤو',
  'چوچو٢',
  'وايوه',
  'منرسي',
  'جريتن',
  'ايبير',
  'کدمبا',
  'روڽوه',
  'ݢوݢول',
  'مرمبس',
  'چمديق',
  'راهڠ٢',
  'ملکوت',
  'ݢوبهن',
  'ݢابرا',
  'دکتيل',
  'لوݢوت',
  'کينچه',
  'نباته',
  'بربدق',
  'درڤدڽ',
  'سدالم',
  'چمڤور',
  'لنجوق',
  'نيکتر',
  'ݢراي٢',
  'لڠلاي',
  'اجازه',
  'کرڤيس',
  'شرݢکو',
  'بدودق',
  'سڠکيڠ',
  'ڤرلقي',
  'رينتڠ',
  'لڠاي٢',
  'توٴيل',
  'سيبير',
  'باکون',
  'براوا',
  'بايوڠ',
  'ݢمڤور',
  'دݢاڽه',
  'منيکم',
  'تاوچي',
  'سلواس',
  'تاهوي',
  'ݢنتيڠ',
  'منڤيس',
  'کلاره',
  'چمارق',
  'ݢوتيق',
  'موموق',
  'مغلظة',
  'ابون٢',
  'دچاچه',
  'بيليس',
  'ليچيق',
  'برسيک',
  'چاليت',
  'ايکلڽ',
  'منمبل',
  'ڤرکنن',
  'بلهکن',
  'چنواي',
  'برکرت',
  'مراجه',
  'تنزيل',
  'سهايڽ',
  'ردايڽ',
  'ليمبڠ',
  'سڤوتر',
  'اليهن',
  'بويوڠ',
  'برببت',
  'مڽباي',
  'سوده٢',
  'لنسيت',
  'ارتين',
  'ݢابس٢',
  'تتڤکن',
  'توکيل',
  'ملعون',
  'سويتر',
  'بربين',
  'ليڤتڽ',
  'منبرق',
  'تڠکيس',
  'اينتن',
  'جنيس٢',
  'روموڠ',
  'دتوجو',
  'بوهور',
  'بايرن',
  'اتورن',
  'کلويڠ',
  'کاميت',
  'ڤاکوه',
  'دايڠت',
  'ڤڽيمق',
  'دلابل',
  'ڤاناي',
  'منبڠڽ',
  'اوبتن',
  'ݢوروه',
  'کرسيڠ',
  'چاروق',
  'دارتن',
  'اڠسان',
  'قنديل',
  'اوروڠ',
  'دچاچي',
  'کمباي',
  'بودوق',
  'مڠنلي',
  'سورتڽ',
  'کلايق',
  'جيوير',
  'رياضه',
  'ممراي',
  'کاريا',
  'چينوق',
  'نياݢڽ',
  'سستکن',
  'انقکو',
  'داترن',
  'سيتيڠ',
  'حيلهڽ',
  'سمڤوت',
  'مردوم',
  'سالين',
  'لمينا',
  'جنيسڽ',
  'ڤيمڤن',
  'ڤونچو',
  'بردڽر',
  'جلنته',
  'کيڤاي',
  'جامين',
  'ساڤو٢',
  'بکس٢ڽ',
  'بردکه',
  'مميجق',
  'ساڠيه',
  'براوڠ',
  'لوندو',
  'مماسه',
  'بوريت',
  'رانچق',
  'ڤوٴيڠ',
  'ݢويتر',
  'کومن٢',
  'باکول',
  'کلوبو',
  'هضانه',
  'چوڠکي',
  'منجبل',
  'بندوق',
  'ترتکن',
  'تقويم',
  'کرڽيه',
  'جابير',
  'ککجين',
  'ۏاديي',
  'روسبڠ',
  'چاتوق',
  'ڤمندو',
  'سکاکر',
  'امبوق',
  'روڤا٢',
  'حواء٢',
  'مرڽوه',
  'کنبين',
  'لوٴيق',
  'ڤرتقن',
  'چيريت',
  'ريموس',
  'ڤرومن',
  'کيوتن',
  'کوليم',
  'اوڤاو',
  'لالاو',
  'دتندو',
  'ممڠکه',
  'براسق',
  'مرنجه',
  'باٴود',
  'بيدور',
  'برکات',
  'مينيت',
  'ڤيلين',
  'دڤاچو',
  'بولي٢',
  'راڤور',
  'دودوس',
  'ملڠلڠ',
  'لاٴور',
  'ڽاتله',
  'صدرية',
  'مڽيلق',
  'مماکن',
  'کستاڽ',
  'ايبوڽ',
  'ݢاٴول',
  'منڠکل',
  'سراي٢',
  'دکوڽه',
  'کوچوڤ',
  'اوبلق',
  'برکمس',
  'بيبيه',
  'مڠرلڤ',
  'ݢيݢيل',
  'نلاين',
  'تڠکاي',
  'لنجرن',
  'بليکو',
  'توڠکه',
  'ملاته',
  'ليليس',
  'ضروري',
  'تاوهو',
  'دتاور',
  'اخير٢',
  'راکوت',
  'تونيک',
  'موريد',
  'بدݢوڠ',
  'سيڤيت',
  'تريکا',
  'اڤاتي',
  'ڤناور',
  'ڤاکر٢',
  'سلورو',
  'سيساڽ',
  'جامهن',
  'قواتڽ',
  'ڤارون',
  'تيمهڽ',
  'ريچيه',
  'رومين',
  'ملعون',
  'ترحال',
  'مڠالس',
  'کوچوق',
  'سيامڠ',
  'تينتا',
  'ڤڠتمن',
  'بوکيت',
  'دچاتو',
  'کندول',
  'دتوبق',
  'سامله',
  'درمبت',
  'سيرسق',
  'ݢڤورا',
  'کيديق',
  'کيدول',
  'برواس',
  'جڠݢوس',
  'دکڤيت',
  'کرڽاو',
  'ݢيندر',
  'سمرتا',
  'ڤڽامق',
  'دچرنا',
  'ڤوچوڠ',
  'تولوڠ',
  'دالمڽ',
  'دڠکوت',
  'برکاچ',
  'جابرن',
  'برڠوس',
  'کرنتت',
  'ڤچهن٢',
  'دهمڤس',
  'قلمکو',
  'درياس',
  'دباير',
  'امثال',
  'جاهتڽ',
  'ايکون',
  'چڠکوق',
  'سلوبت',
  'کلابت',
  'ايياو',
  'ياٴيت',
  'ݢليبر',
  'کونتل',
  'ڤڠيڠت',
  'ڤڠسنن',
  'منوام',
  'دوکون',
  'فاٴول',
  'داوجا',
  'ممکيڠ',
  'زمانڽ',
  'چايڤو',
  'ݢونجڠ',
  'دليما',
  'تربرق',
  'تناݢڽ',
  'براڠس',
  'دسربو',
  'ستيکر',
  'دڤاته',
  'مقيوڠ',
  'چومبو',
  'چڠکول',
  'کايڠن',
  'مسلمه',
  'مرڠݢو',
  'تيندا',
  'تاڤق٢',
  'برکجر',
  'کأيڤن',
  'دهريه',
  'سمڠݢڠ',
  'نيسبي',
  'ڤدومن',
  'ڤوتري',
  'بوڽوق',
  'ڤڽڠݢه',
  'ريݢاي',
  'ماناي',
  'کبلاي',
  'مڽنتق',
  'موڠيل',
  'واڠيڽ',
  'دهياس',
  'ميديق',
  'لولاي',
  'بردکو',
  'منچچه',
  'مرواڤ',
  'کفارة',
  'رڠکوق',
  'مايڠڽ',
  'تحقيق',
  'سلسما',
  'داسڤي',
  'دسالڠ',
  'سلوار',
  'دونيا',
  'کومڤس',
  'ترڤکت',
  'تومڤق',
  'ممويا',
  'سورة٢',
  'ايروڠ',
  'سيريت',
  'ڤروتڽ',
  'باڽيل',
  'توݢوق',
  'مڠريڤ',
  'ايجاب',
  'بيبير',
  'دبينا',
  'رۏرند',
  'ڤلڠکڤ',
  'دتوݢل',
  'ڤڤارو',
  'درايه',
  'بنتوق',
  'اوبت٢',
  'مڽڠکڠ',
  'زرنيخ',
  'سارنن',
  'دلاين',
  'تمبه٢',
  'تهليل',
  'بباوڠ',
  'ورتيل',
  'دوديت',
  'ڤاسيت',
  'اونتا',
  'مماچق',
  'شرڤيت',
  'جاݢور',
  'مليله',
  'سنتون',
  'مديره',
  'سساهم',
  'ڤڽاڽي',
  'کيڤوق',
  'کوهوڠ',
  'توڠکن',
  'کراکر',
  'ݢليمڠ',
  'بنتون',
  'ماسين',
  'نودول',
  'مسابه',
  'اهکام',
  'ڤڤايا',
  'رهسيا',
  'کوارت',
  'سبسر٢',
  'دڤولس',
  'ڤلنتر',
  'تيندن',
  'دڤيوه',
  'اينله',
  'ديدير',
  'ڤيڤيڽ',
  'کوريق',
  'بورون',
  'کوبقن',
  'مڠيلا',
  'ڤڠچوت',
  'دندني',
  'اورڠ٢',
  'تاليڠ',
  'ڤاچرن',
  'مڠبوم',
  'سکيلس',
  'دسلڤي',
  'ڤناري',
  'ترلدق',
  'دسلوق',
  'ݢملتڤ',
  'شرهنڽ',
  'برسڠک',
  'کيکير',
  'تاونن',
  'ڤلسوڽ',
  'جلابق',
  'لتلاو',
  'لنسکڤ',
  'دورنا',
  'تصريف',
  'داتوق',
  'رڠکڤ٢',
  'حيوان',
  'ݢندڠن',
  'چڠݢوت',
  'ريڤيق',
  'مجازي',
  'کيسيڠ',
  'دتوبا',
  'ڤالا٢',
  'چلوڤق',
  'منضرب',
  'اتور٢',
  'سوبوق',
  'ايسقن',
  'دسڠاج',
  'چيڠيڠ',
  'ڤمادو',
  'ممتيڠ',
  'ڤلوده',
  'تمڠݢڠ',
  'باچوت',
  'ملراي',
  'توڤڠن',
  'دسدري',
  'برݢنڠ',
  'اهوال',
  'تاٴوق',
  'درلاو',
  'ممکور',
  'ممنچڠ',
  'ججاوت',
  'دڤراي',
  'ولوکو',
  'مڽڠݢڤ',
  'کوتا٢',
  'سليرق',
  'مڠارس',
  'برججق',
  'اداکن',
  'دأولس',
  'کتابن',
  'رريبو',
  'ترالي',
  'ڤموجا',
  'سوسوڽ',
  'اوݢام',
  'اوݢوت',
  'سلاتن',
  'سيمبڠ',
  'ڤواق٢',
  'باچوق',
  'برعدس',
  'مماچق',
  'سرديه',
  'دأوڤم',
  'سدروم',
  'تادهن',
  'کينچق',
  'اڠکه٢',
  'جنيتن',
  'دتيکم',
  'براله',
  'اڠݢيل',
  'برسيا',
  'وايڠڽ',
  'لاٴيق',
  'سافيل',
  'کويوق',
  'سمالم',
  'مڽرݢڤ',
  'رومه٢',
  'کيليق',
  'داکين',
  'بنتور',
  'ݢانيه',
  'بلڠکن',
  'لنتيڠ',
  'ݢاروق',
  'تاٴيڤ',
  'ديلمن',
  'مڠريق',
  'برکدل',
  'دبولڠ',
  'تاروڤ',
  'تلاته',
  'رايون',
  'ڤالين',
  'تيريس',
  'کيڤرت',
  'برݢوق',
  'ملوڠو',
  'مماجڠ',
  'ماسقن',
  'چلاڤق',
  'تالي٢',
  'بويوت',
  'ݢڤوق٢',
  'لاڠوت',
  'ݢاميق',
  'بياوق',
  'مڠيلس',
  'ريڤيت',
  'خبولي',
  'ڤارول',
  'برارق',
  'لاٴيف',
  'خيسيت',
  'کونيڠ',
  'ڤکاکي',
  'ڤݢڠنڽ',
  'کوما٢',
  'امڤوق',
  'اصليڽ',
  'سکاڤل',
  'چيدوق',
  'سوٴيت',
  'مڠيدر',
  'برچمس',
  'اليرن',
  'ڤنڠکڤ',
  'ڤمارا',
  'ڤݢوام',
  'توڠݢڠ',
  'لوندڠ',
  'منلوس',
  'ݢونوڠ',
  'سيديق',
  'دألسي',
  'تيمور',
  'ملتوس',
  'ݢيڤيڠ',
  'کتوبن',
  'اجلکو',
  'قاريء',
  'دابير',
  'دڠکلڽ',
  'برچمس',
  'ڤڠوله',
  'ليڠوس',
  'چلڤوه',
  'سلايڠ',
  'چوڠوق',
  'سادوڠ',
  'نصوها',
  'برفهم',
  'ارڤوس',
  'دڤݢڠڽ',
  'ککودا',
  'کواڠن',
  'کوڠفو',
  'ڤڽورق',
  'کڤالڠ',
  'ڽاريق',
  'لينتس',
  'ڠاڠوت',
  'منوݢل',
  'ڤاݢرن',
  'ملالڤ',
  'ڤڠلمن',
  'لايوق',
  'سساوت',
  'مرنچم',
  'سنتاي',
  'مرياڤ',
  'کچيڤق',
  'ݢاجيه',
  'ڤنودا',
  'بدوڠن',
  'ايݢوس',
  'عفريت',
  'مڠوسڤ',
  'دريبا',
  'تاکوه',
  'ساٴوت',
  'ڤاݢاي',
  'اوجول',
  'ڤکولا',
  'مڽامق',
  'مڠوچق',
  'سبورس',
  'دتمڤل',
  'دڤوجي',
  'تارين',
  'محصول',
  'ڤنيرو',
  'ڤستور',
  'کعربن',
  'تعليق',
  'ڤناجق',
  'دڠيڠن',
  'سناسي',
  'ترتيل',
  'کرياق',
  'ڤڠيرن',
  'کولور',
  'ترتلن',
  'برتلڠ',
  'سميثل',
  'سمندن',
  'چيريڽ',
  'دأبوق',
  'ايتيق',
  'توڤيق',
  'کاميل',
  'دموات',
  'بروقف',
  'چلادي',
  'سهيتم',
  'ملوده',
  'کيڤس٢',
  'دچاچت',
  'کليه٢',
  'دتيتي',
  'ݢليتق',
  'منچچڤ',
  'دودول',
  'برتڠݢ',
  'دوسيج',
  'دلڤسي',
  'ݢيلين',
  'کيلير',
  'سالهن',
  'ملبيه',
  'چڤروڤ',
  'هيمتڽ',
  'کرنجڠ',
  'دتنون',
  'سيڠݢن',
  'مڠاچه',
  'دکات٢',
  'بيلݢا',
  'ݢداڤن',
  'ماره٢',
  'شهداء',
  'ڤندمن',
  'همباڽ',
  'سيڤون',
  'کرودق',
  'دتاره',
  'ممسني',
  'تماکڠ',
  'ماݢيڠ',
  'اخبار',
  'براتڤ',
  'مانوق',
  'سڠکوت',
  'جروتن',
  'ڤڠبوم',
  'لايچي',
  'هبلور',
  'بيلين',
  'بلنته',
  'چيتقن',
  'باندر',
  'ليوات',
  'ڤڤريت',
  'دجواب',
  'مارين',
  'سيکڤ٢',
  'تخصيص',
  'نحوان',
  'داندڠ',
  'بردرڠ',
  'اڤوݢي',
  'اولڤ٢',
  'ݢيݢي٢',
  'چوڠور',
  'مابوق',
  'جروجي',
  'اکوله',
  'ڤاݢيڽ',
  'منݢڠي',
  'تابون',
  'تونڠن',
  'کنديل',
  'رݢبول',
  'دباوه',
  'ڤروان',
  'لينتر',
  'انچل٢',
  'دچابر',
  'کملوت',
  'توليڤ',
  'مرلاو',
  'مڠݢرا',
  'دترجڠ',
  'دڤرڠي',
  'ڤنجبق',
  'مڠجاي',
  'برݢما',
  'ريڠن٢',
  'دأچوڠ',
  'بربوک',
  'ليمبو',
  'عذورڽ',
  'مڽلير',
  'برچمر',
  'سولفت',
  'ايسيق',
  'دتولق',
  'مڠتيڤ',
  'کوبوس',
  'مڽارق',
  'راڤي٢',
  'دکنڠي',
  'مليڠس',
  'فوچوق',
  'رضاکن',
  'چکيبر',
  'ترکسن',
  'تنتڠن',
  'کواۏر',
  'رايڤن',
  'اولر٢',
  'لاتيس',
  'رابون',
  'فاجرة',
  'کاتيق',
  'سقسي٢',
  'امبو٢',
  'ڤسيبن',
  'چکريڠ',
  'چلوڤر',
  'دأݢيه',
  'هاليس',
  'بدبوق',
  'کنچيڠ',
  'تماتو',
  'ملاتي',
  'کراوت',
  'کدواڽ',
  'منچيه',
  'کونچه',
  'ديسکو',
  'باٴور',
  'برسسق',
  'سڠارت',
  'بوهوق',
  'نفسوڽ',
  'دادون',
  'رباٴڽ',
  'کليچي',
  'چاکوس',
  'ايڤوق',
  'مڽوبڠ',
  'بلنجا',
  'باتوڠ',
  'ايکنڽ',
  'دسڠکڽ',
  'سباتي',
  'ريناي',
  'کولق٢',
  'چوکوت',
  'ڤوڠݢل',
  'جيتوڠ',
  'بابقن',
  'دڤوتر',
  'ميموس',
  'دايسي',
  'ݢروتو',
  'مندرو',
  'اوڠول',
  'ولروس',
  'الامڠ',
  'دليهت',
  'ملڠبڠ',
  'ترسڠک',
  'دأموق',
  'ݢوريس',
  'ممايڠ',
  'ايات٢',
  'بطريک',
  'ݢدورن',
  'چمومو',
  'دحساب',
  'کاتيل',
  'دتاهن',
  'کولوڠ',
  'جيويت',
  'کاݢوق',
  'ڤماوه',
  'دباتو',
  'ذاتمو',
  'ڤستيڽ',
  'مڠوڤر',
  'ڤويوه',
  'دهندڤ',
  'ليلاو',
  'ککلمن',
  'باڽقڽ',
  'دأنوت',
  'ايلوق',
  'ترججس',
  'منرکم',
  'سوراو',
  'بردݢم',
  'سبوکن',
  'ترنتڠ',
  'سموده',
  'لوڤاي',
  'کرتوڤ',
  'فريݢت',
  'سڠݢره',
  'موالا',
  'کايزن',
  'روڠݢس',
  'برکمو',
  'سليبو',
  'منجرت',
  'مڠاسو',
  'چمڤلو',
  'دڤاسق',
  'ڤواس٢',
  'عاديل',
  'کدلاي',
  'نوندي',
  'بلندڠ',
  'باسوه',
  'ممڠکس',
  'چماڠن',
  'ڤاتوق',
  'کرڽوت',
  'بالم٢',
  'مڽمڤڠ',
  'داٴيڠ',
  'ݢوندي',
  'برسدڤ',
  'تولرن',
  'ݢردوم',
  'موکين',
  'لڠکاي',
  'ݢنتيه',
  'خدمتڽ',
  'ݢاڤيت',
  'عملکن',
  'دالم٢',
  'حوالة',
  'ڠيجون',
  'ليمون',
  'ڤاڠݢل',
  'لينچه',
  'سيڤوا',
  'باوه٢',
  'ڤوڤور',
  'مڠامڠ',
  'لتوسن',
  'کأمتن',
  'کنبوس',
  'دڤاتي',
  'کوما٢',
  'ماڠوت',
  'ڠيکيق',
  'براره',
  'دݢنتي',
  'چاتوت',
  'دکڤوڠ',
  'منڠݢر',
  'جلالة',
  'جدوال',
  'مللاي',
  'انيڠ٢',
  'تقصير',
  'ڤلڠݢي',
  'سکالا',
  'دچکاو',
  'ايليت',
  'لومبر',
  'سالهڽ',
  'راجه٢',
  'رنچمن',
  'چوبيت',
  'سومبڠ',
  'جوجوب',
  'چرڤوه',
  'منمڤه',
  'لمبت٢',
  'سريکي',
  'سورتن',
  'ممبڠڠ',
  'سݢاوت',
  'ببولا',
  'بڠݢکن',
  'دڤنتق',
  'ريديق',
  'باݢول',
  'کدادق',
  'مموݢس',
  'مڽروت',
  'مڠوڽه',
  'ڤڽيلڤ',
  'سمادي',
  'ۏيلوس',
  'اوريڠ',
  'لاڽاو',
  'مرندق',
  'لقسمي',
  'بيڠوڠ',
  'منکوڤ',
  'هاڽله',
  'سرودا',
  'ڤرسوق',
  'تاتڤن',
  'سليرق',
  'ملبون',
  'تمڤلت',
  'روڠݢ٢',
  'سمنجا',
  'کدنوق',
  'جرڠکڤ',
  'لستاک',
  'چوبيق',
  'اوليق',
  'ترڤݢڠ',
  'فردوس',
  'سلورو',
  'چوارن',
  'سواتو',
  'کسݢرن',
  'ݢلوسڠ',
  'ملدقڽ',
  'داڠاي',
  'هاڠوس',
  'ايديل',
  'ڤلولس',
  'چاڤيق',
  'ݢومبا',
  'برقبة',
  'دابيڠ',
  'لرۏاڽ',
  'لوکوس',
  'منچچق',
  'بيلور',
  'ايهکو',
  'سسامڽ',
  'هالير',
  'توندڠ',
  'وجودڽ',
  'ڤيکاو',
  'ترکلڤ',
  'ددميت',
  'ڤاليس',
  'لمبقن',
  'ڤرتوا',
  'ݢلالر',
  'برهرݢ',
  'دڤچوت',
  'کليمت',
  'هريسه',
  'برنفس',
  'ڤيستا',
  'مڽڠسڠ',
  'مڠݢرم',
  'چوٴيق',
  'تاليڽ',
  'داليه',
  'راما٢',
  'ايسين',
  'سلوڤن',
  'کنسرڽ',
  'فاتوم',
  'تنجرن',
  'دومڤق',
  'ڤمبدل',
  'توندا',
  'ڤولوه',
  'مجموع',
  'چندوڠ',
  'ڤراست',
  'تايڠن',
  'کليته',
  'چروتن',
  'منتيݢ',
  'تروقڽ',
  'توندق',
  'سوريس',
  'ڤڽنتل',
  'چينچا',
  'مونيل',
  'موڽيت',
  'دوٴيت',
  'مميات',
  'سرݢوت',
  'کوروه',
  'ڤيڠݢه',
  'توديڠ',
  'ججالا',
  'کوسوڠ',
  'ممرلن',
  'مڠݢبه',
  'دطلاق',
  'سايلن',
  'مڠسني',
  'مناکر',
  'سردين',
  'کوچوه',
  'بولاي',
  'دمڤيت',
  'اقسام',
  'جوڠکڠ',
  'تيروق',
  'سوٴال',
  'هيڠوس',
  'توريس',
  'کتولا',
  'کتݢوت',
  'کوڤوق',
  'بولسق',
  'مڽوسو',
  'ڤلنچر',
  'ݢنڤکن',
  'ڤادين',
  'حاشية',
  'چڠکيس',
  'لڤوقن',
  'ݢبلوق',
  'کتموي',
  'ڤنندا',
  'تلادن',
  'مڠتيس',
  'کينجڠ',
  'ايندو',
  'ممقسا',
  'ݢريڤي',
  'مڠيوق',
  'تعليم',
  'تنداي',
  'سيمبت',
  'ݢوممن',
  'کمبڠڽ',
  'ريۏيت',
  'دسيرڤ',
  'ايمسي',
  'رومڤق',
  'بيمبڠ',
  'دهارڤ',
  'اومتڽ',
  'دانوت',
  'کمويڠ',
  'نستاڤ',
  'منجاج',
  'کأکنن',
  'ڤراني',
  'درداي',
  'تالنن',
  'ترسنق',
  'دعايه',
  'ملاوق',
  'چمبوق',
  'تاڤوڠ',
  'مکرمة',
  'بليکو',
  'تينجق',
  'بنتوس',
  'بوديق',
  'رانتڠ',
  'کولوق',
  'ݢاݢهڽ',
  'ڤاٴوه',
  'دکمڤا',
  'دتيمڠ',
  'تتارڤ',
  'ترکنا',
  'مڽرتو',
  'کتوا٢',
  'کومبا',
  'انصاف',
  'چرچوڤ',
  'بيجان',
  'داهن٢',
  'ريديڤ',
  'ڤتالا',
  'ڤوروڠ',
  'موڠݢه',
  'دتانق',
  'ڤارڠڽ',
  'مهيسا',
  'سردوڤ',
  'ڤانوس',
  'سيبوق',
  'لڠکيڠ',
  'کوموس',
  'تروڤا',
  'ݢابوق',
  'اونجڤ',
  'راݢڠن',
  'سوجين',
  'کيميا',
  'دڤندڠ',
  'کومبه',
  'دجڠکل',
  'ڤمنجت',
  'دݢوبل',
  'تامنڽ',
  'جمروڠ',
  'برلکت',
  'لاريس',
  'بليڠس',
  'چيکوق',
  'کاسيه',
  'رنتوق',
  'سبيدڠ',
  'بيچان',
  'راوتن',
  'مرجوق',
  'سکوکو',
  'دايڠت',
  'ڤورنو',
  'ڤڽاکت',
  'بيديق',
  'سربنڽ',
  'چابوق',
  'دنمڤق',
  'چوٴيت',
  'ببولو',
  'ݢالوق',
  'منچلڠ',
  'منجاو',
  'جولوڠ',
  'کسيق٢',
  'بربنه',
  'تاوق٢',
  'برسرو',
  'ڤبريک',
  'دهولو',
  'منڤتي',
  'دسبوت',
  'انيه٢',
  'مڠڤيت',
  'مڠڽيت',
  'ڤريما',
  'سنديڠ',
  'دݢاسق',
  'رسدوڠ',
  'رينتڠ',
  'ڤتارڠ',
  'سهاري',
  'برعمل',
  'تصديق',
  'مندکت',
  'چوڠݢڠ',
  'لاماڽ',
  'هلوان',
  'ملجيت',
  'بنجرن',
  'ݢدبوڠ',
  'تلڤوق',
  'دلامق',
  'هويوڠ',
  'دݢالي',
  'تانوق',
  'بنچان',
  'ڠوميل',
  'لبورڽ',
  'ترڤچت',
  'ملايو',
  'ڤميجق',
  'ڤڠلين',
  'کوسين',
  'حديث٢',
  'ڤوتيا',
  'ڤارسن',
  'ملواڤ',
  'ڤستاک',
  'چاچيل',
  'ڤاچوڽ',
  'بکاکق',
  'اماي٢',
  'هليڤن',
  'وريتا',
  'کوناو',
  'امڤول',
  'ارتيس',
  'کالاو',
  'دسبوت',
  'مڠابه',
  'تيتيق',
  'سکندل',
  'واجرڽ',
  'لوڠݢر',
  'برسنم',
  'دڤروق',
  'فوتوڽ',
  'نقليه',
  'باتيق',
  'سڤوهن',
  'ڤميدڠ',
  'ڤالاي',
  'هيدوڠ',
  'دچاتت',
  'سبابت',
  'روبق٢',
  'چنياݢ',
  'تقوىڽ',
  'دسڠکڠ',
  'دسيکت',
  'سترون',
  'دکارن',
  'دڽوت٢',
  'جيجير',
  'اڠݢوت',
  'باڠکه',
  'برکجڠ',
  'دودوي',
  'مولى٢',
  'لاڤيه',
  'ترکبل',
  'ساٴيز',
  'منڠݢت',
  'ڤيڠݢن',
  'کلمبق',
  'دتواس',
  'چرمين',
  'جيسيم',
  'اکر٢ڽ',
  'بڠاون',
  'کرناي',
  'راجوق',
  'هابيس',
  'ڤاٴوق',
  'ۏکسين',
  'کربوق',
  'فاسقڽ',
  'همباڽ',
  'جراهڤ',
  'ميڠݢو',
  'ڤڠتوت',
  'دتاݢق',
  'هوجني',
  'ليريس',
  'رييوت',
  'دستير',
  'ݢيلاڽ',
  'درنتڤ',
  'هايکو',
  'ڤراهڤ',
  'اٴييا',
  'کنڠله',
  'منچمت',
  'مندسر',
  'درڠݢه',
  'کومبڠ',
  'ايڤيل',
  'سرومه',
  'بردݢڤ',
  'منتوا',
  'سعالم',
  'دنتيڠ',
  'اسيتت',
  'چاٴيڠ',
  'داراي',
  'هيريق',
  'جڠکاو',
  'ايبيق',
  'ملارڠ',
  'ڤرتسن',
  'سريدڠ',
  'دچوري',
  'مڠودڤ',
  'ڤجابت',
  'دأڠݢڤ',
  'تفحوس',
  'ترباج',
  'درهاک',
  'ناڠوي',
  'سبروت',
  'ملڤسي',
  'سبحان',
  'راوا٢',
  'ڤمالق',
  'بردرو',
  'ڤندوݢ',
  'تمن٢ڽ',
  'مرسيق',
  'ترڽهڽ',
  'دسڤره',
  'سوريا',
  'هيکتر',
  'برسوک',
  'جنجيڽ',
  'نلوري',
  'تراسق',
  'ککلتن',
  'ادوقن',
  'سرنده',
  'ݢمنتر',
  'ترداي',
  'مرايه',
  'سرنتڠ',
  'ببولن',
  'مڠايق',
  'ڤروسق',
  'روبيق',
  'کتوات',
  'بودوق',
  'ڤونتو',
  'ݢوچوق',
  'منريق',
  'چڠکرن',
  'مورتر',
  'کيڤوه',
  'منددق',
  'کريول',
  'کاٴوڤ',
  'کبورو',
  'اومنة',
  'مواده',
  'ککابو',
  'نوراݢ',
  'کاسي٢',
  'ݢاڤيل',
  'کلمبن',
  'لولون',
  'تنچڤن',
  'ڤنمبق',
  'سيواه',
  'ݢلياڠ',
  'ڤاسيت',
  'انتوق',
  'ضلالة',
  'دتمڤي',
  'دتيرو',
  'سيسيق',
  'ڤرامل',
  'ڤنچڤت',
  'بوتا٢',
  'کمابل',
  'ممبيا',
  'بوروت',
  'اڽلير',
  'مشرکة',
  'ڤڠکتڽ',
  'داٴوڤ',
  'بلواس',
  'سوجان',
  'بهالا',
  'ستاݢي',
  'کوڤون',
  'کرسهن',
  'مڽنڠي',
  'ڤاݢر٢',
  'ملاکر',
  'دماکي',
  'ترڤرڤ',
  'برکتم',
  'دبنڠي',
  'سجاݢت',
  'روسيه',
  'سرواه',
  'درندم',
  'کلاهي',
  'تڠکوي',
  'روسا٢',
  'ڤوناي',
  'مڽاکل',
  'دڤچوت',
  'امباي',
  'اقرار',
  'مورلڽ',
  'بادن٢',
  'سيکيق',
  'خبرکن',
  'کولاو',
  'چمڤاي',
  'اروما',
  'چاڠوت',
  'چوکلت',
  'کوچير',
  'رابطة',
  'ڤوسرن',
  'ڤاموڠ',
  'چوڤيڠ',
  'چيمني',
  'ڤمبکل',
  'چوليت',
  'موندم',
  'چتراڽ',
  'دنوبت',
  'ترݢنڠ',
  'کڤارت',
  'فومين',
  'رڤتاي',
  'جلابس',
  'ݢرنيت',
  'بالغة',
  'اوڠݢڠ',
  'رانتس',
  'براره',
  'بايڠڽ',
  'ڤڠاتو',
  'ليکوه',
  'مرچون',
  'لمبهن',
  'سوسيج',
  'ساله٢',
  'تاٴوڠ',
  'ملمبڠ',
  'تتوبن',
  'اجوان',
  'سدنيا',
  'سوسول',
  'تردسق',
  'جامبا',
  'لنتقن',
  'ڤرسيس',
  'سکوڤو',
  'زرباد',
  'کاسر٢',
  'دڤوکت',
  'ݢرڠاو',
  'بيدڠن',
  'رسولڽ',
  'چوليق',
  'برکنل',
  'دوالم',
  'تيبوق',
  'برصبر',
  'فتالت',
  'چارا٢',
  'ميثلڽ',
  'دتاکر',
  'اتليت',
  'سجڠکڠ',
  'ڤراون',
  'ݢليچق',
  'قاموس',
  'مڽندق',
  'برکود',
  'دڤاهت',
  'چاکاي',
  'رومڤڠ',
  'ݢايوڠ',
  'سلالة',
  'ويليڠ',
  'ڤياݢم',
  'سودوق',
  'ارݢون',
  'مڠالق',
  'ترچمس',
  'سابيت',
  'ڤيروق',
  'روميو',
  'انتيه',
  'اڠکوڠ',
  'السنڽ',
  'مڠيڠت',
  'ڤميکت',
  'دڤلوق',
  'سموتر',
  'هودوه',
  'دبيدق',
  'تبليت',
  'ڤوڤوت',
  'ڤمڠݢڠ',
  'توٴيت',
  'امبون',
  'سريڠن',
  'چکتيڠ',
  'تاکاو',
  'سسموم',
  'کريچل',
  'ڤوسيڠ',
  'ڤهجره',
  'ڤروڠو',
  'ڤڠعام',
  'ايڤوق',
  'رسولڽ',
  'ڤاريا',
  'باريد',
  'منتيڤ',
  'سناوت',
  'ݢاروت',
  'دسڠوق',
  'ݢميلڤ',
  'تحبيس',
  'ڤکداي',
  'جاٴيس',
  'ڤڽندق',
  'سکارڠ',
  'مڠياق',
  'حواٴڽ',
  'ڤلاير',
  'سڤيلن',
  'چڠکرو',
  'انتوي',
  'مرمبت',
  'تلنتر',
  'مولود',
  'ويسکي',
  'دبالس',
  'کتنون',
  'مراݢي',
  'لونقڽ',
  'سلڤور',
  'تتامو',
  'جرڠکق',
  'جلودو',
  'ريڤيت',
  'ليڤلڤ',
  'سيسي٢',
  'لاݢون',
  'دتورڤ',
  'ايمڤس',
  'بيݢوم',
  'تيفوس',
  'مرتيه',
  'سوݢيه',
  'ملاسه',
  'اڠسنا',
  'ڤلمڤڠ',
  'کديرا',
  'کاکيڽ',
  'برابو',
  'کراکه',
  'جلانق',
  'باچير',
  'اوليت',
  'سومبل',
  'کلنجر',
  'ڤوچوڠ',
  'دتوبا',
  'مڽاکت',
  'ريکود',
  'حوصلة',
  'برڤرم',
  'سرايو',
  'تاکرن',
  'ريلاي',
  'سڠليڠ',
  'ݢرڠنڽ',
  'ايڠکر',
  'دڤمڤڠ',
  'ڤيلون',
  'مڠاڠن',
  'مڠݢݢر',
  'حرارة',
  'ݢليده',
  'تنتڠڽ',
  'ڤنتاو',
  'ادونن',
  'کڤدمو',
  'ممالي',
  'ݢولوڠ',
  'کنيڠڽ',
  'مکروه',
  'براسق',
  'ڤلادڠ',
  'چورمن',
  'لاݢوڽ',
  'چلوڠڤ',
  'برلمه',
  'منسوخ',
  'ݢڠسير',
  'سمبسي',
  'ممرون',
  'ڤيسيق',
  'هلامن',
  'کبيري',
  'جنابة',
  'ببراڤ',
  'دالمڽ',
  'برالت',
  'جنامڽ',
  'کاٴول',
  'ساتوڠ',
  'مرووق',
  'دسامر',
  'ݢلتوق',
  'سرومت',
  'باندا',
  'برکوت',
  'چابير',
  'کوسير',
  'کيهيل',
  'دݢودم',
  'سنجاڽ',
  'ݢونچڠ',
  'تقرير',
  'تربرت',
  'بردلن',
  'لاݢيڽ',
  'کسقتي',
  'دربوس',
  'تجليڽ',
  'مڠومن',
  'ڤڽيتا',
  'چاݢرن',
  'کسلڤن',
  'ڤڽلمن',
  'کنتين',
  'النور',
  'سکاڤو',
  'اڠݢن٢',
  'ڤڽکتن',
  'مڽلڠي',
  'ليڤاي',
  'ڤڠارڠ',
  'ملڠکن',
  'ملاچق',
  'مڠکلن',
  'ڤڽڤوه',
  'برزهد',
  'مندهم',
  'برادب',
  'مناکي',
  'کليما',
  'کلچوس',
  'ترسست',
  'بلانق',
  'ڤينسي',
  'چرديق',
  'لشکرڽ',
  'مڽرهي',
  'مرڤيه',
  'مڠݢسا',
  'چنتيل',
  'کابيت',
  'داهية',
  'رسيڤي',
  'ڤلواڠ',
  'کيريق',
  'برايا',
  'دتمبق',
  'تودوڠ',
  'کباجي',
  'ڤيڽيت',
  'ستلاݢ',
  'کوله٢',
  'چوالي',
  'انجيل',
  'جمڤيڽ',
  'مندڠر',
  'ڤرسين',
  'اڠکوت',
  'ليݢرن',
  'مڠريق',
  'لمڤاو',
  'ڤمبله',
  'سراته',
  'بروڤا',
  'مڽتيل',
  'کاچير',
  'ليڤيد',
  'چڠݢوق',
  'کوسيل',
  'شعار٢',
  'ڤمرمن',
  'برودا',
  'منچکق',
  'دلندا',
  'لوکيت',
  'برخبر',
  'کأدبن',
  'توکوق',
  'ڤنالڠ',
  'يايسن',
  'سبتول',
  'سيسوا',
  'سابن٢',
  'اولقن',
  'تيدور',
  'تمڤوا',
  'روسيت',
  'اوچول',
  'ڤوديڠ',
  'ساندڠ',
  'ناروڠ',
  'هاشور',
  'انجوڠ',
  'کرليڤ',
  'توڠݢل',
  'رڠکاي',
  'ڤجاݢل',
  'ݢدوݢن',
  'توݢسن',
  'جڠکيت',
  'سرنجڠ',
  'ݢيسيل',
  'هاسوت',
  'مڽولڠ',
  'ابعاض',
  'مندوا',
  'هنتوق',
  'دچوري',
  'سلنچق',
  'نجومن',
  'سليري',
  'مخالف',
  'ݢلوڠ٢',
  'بناتو',
  'اوسکر',
  'دتمڤن',
  'ججامو',
  'بمبرڠ',
  'لاري٢',
  'دڤسني',
  'تاجاو',
  'اينۏر',
  'تايرڽ',
  'ريسين',
  'رنتاک',
  'مڠينڠ',
  'حاجتي',
  'واليت',
  'دبلاي',
  'عبادة',
  'ڤڤڤوي',
  'دلاوا',
  'اومڤت',
  'چاݢول',
  'بيادب',
  'بلنتن',
  'دبسمي',
  'مڽريڠ',
  'هادڤن',
  'سوندڠ',
  'ڤرنݢس',
  'تمڤيل',
  'راڠکڽ',
  'ݢݢندڠ',
  'چلومو',
  'کونون',
  'اݢونن',
  'باندڠ',
  'لوروه',
  'دباهن',
  'باڤمو',
  'ايجون',
  'ڤڤيجت',
  'بلهکن',
  'حکومڽ',
  'کتيڠڽ',
  'کوالا',
  'هادوق',
  'ترجلس',
  'کلوره',
  'راکيت',
  'مڽيوا',
  'بوريڠ',
  'قياسي',
  'سومڤو',
  'مڠيلڤ',
  'روڽيه',
  'کمڤيس',
  'ديکيڠ',
  'مروڠݢ',
  'ڤارم٢',
  'چوقمر',
  'کودوت',
  'مڽورت',
  'ڤتيقن',
  'سيبار',
  'ککسلن',
  'مايرت',
  'ڤکبون',
  'کمڤاي',
  'جويتا',
  'بندوق',
  'برلکت',
  'دادوق',
  'سرونا',
  'بربدن',
  'کرساي',
  'ڤڽديا',
  'ليمفا',
  'مرواه',
  'دسياڠ',
  'کبون٢',
  'راٴون',
  'ڤوندق',
  'تيمبڠ',
  'سالقن',
  'چميدو',
  'ڤنتول',
  'دڤرون',
  'مماڤه',
  'سوندر',
  'دچڠکم',
  'لاواي',
  'ݢاميت',
  'سايوڠ',
  'ݢاور٢',
  'بردبو',
  'ممادن',
  'فضولي',
  'ڤوسڤا',
  'سرمبي',
  'کيسيڤ',
  'والدن',
  'دسولم',
  'دجارس',
  'مڠکرت',
  'اوريق',
  'کاڤيت',
  'مڠموت',
  'ݢرتوق',
  'چوچوق',
  'کروتن',
  'رونده',
  'تانيه',
  'مڠݢتو',
  'لابو٢',
  'سيومن',
  'کليبڠ',
  'لاما٢',
  'چالون',
  'ڤمقيم',
  'تنجوڠ',
  'دڠوسن',
  'رسول٢',
  'مراقم',
  'دسڠکر',
  'اويوق',
  'ولابي',
  'ريمبت',
  'سناوي',
  'ڤڠيلر',
  'منچله',
  'چاٴير',
  'رزقيڽ',
  'ملولو',
  'تڠکيل',
  'مڽالا',
  'تربوت',
  'بوسان',
  'کدونق',
  'ڤريݢل',
  'ککلکن',
  'ساواي',
  'مودول',
  'ماتڠ٢',
  'واتي٢',
  'دترڠي',
  'تاڠنن',
  'منانم',
  'روندا',
  'مڽيڤه',
  'حالمو',
  'ممکيق',
  'ساندق',
  'اثقال',
  'اويوڠ',
  'دڤارڠ',
  'ڤنجنڠ',
  'مستجب',
  'اسيڠ٢',
  'ڤينقڽ',
  'حاسيد',
  'نيݢوس',
  'دکاجي',
  'کأمسن',
  'منمڤن',
  'سلارا',
  'بابيت',
  'مندول',
  'کليدو',
  'ايمبڠ',
  'فورتي',
  'ستانه',
  'فقهاء',
  'ليڠسن',
  'کوتير',
  'بلڠکو',
  'سکويق',
  'راقمن',
  'بيهون',
  'سوسوت',
  'کنداݢ',
  'مهجوڠ',
  'سليڤر',
  'ݢاربق',
  'دچاچي',
  'ساکيت',
  'بربيس',
  'راويت',
  'برچچه',
  'دوکوه',
  'کيريڤ',
  'دنصکن',
  'چماڠي',
  'کسناي',
  'بونتو',
  'کوراو',
  'سبتله',
  'سندوق',
  'چوميڠ',
  'توامو',
  'مندور',
  'کونيڠ',
  'موهور',
  'بالحق',
  'ڤاير٢',
  'کوبين',
  'راجوت',
  'کوچوڠ',
  'بربکل',
  'دراݢو',
  'جراوت',
  'ڤاڤهن',
  'اوڤمن',
  'باسهن',
  'منجان',
  'ترتبڠ',
  'تاريف',
  'ممالم',
  'کننتن',
  'مرياق',
  'کاسوس',
  'کسيسڠ',
  'جالور',
  'باڠکر',
  'اوردر',
  'اينجڤ',
  'ددڠرڽ',
  'دراٴن',
  'ارادة',
  'سلمبق',
  'مرموت',
  'مرنچڤ',
  'بونوس',
  'بربله',
  'اٴوري',
  'مڠڤول',
  'درنڠي',
  'دکالي',
  'کلدوت',
  'ݢومبڠ',
  'قوممو',
  'کدوتن',
  'ڤيلوس',
  'ويسما',
  'چروڽس',
  'سمياڠ',
  'شعير٢',
  'تذکرة',
  'ترکچم',
  'راچيق',
  'برتلڠ',
  'لڠکهڽ',
  'ݢولوت',
  'فورود',
  'سکينة',
  'مڽرله',
  'ڤولوت',
  'دتوجه',
  'چيڠلي',
  'ترصبر',
  'ڤڠݢمر',
  'ڤڠحدن',
  'ممتيا',
  'داٴون',
  'بکاهق',
  'دبليو',
  'سيوچا',
  'هاريب',
  'کتارڤ',
  'براهي',
  'مودلڽ',
  'ݢيبيڠ',
  'دتمبه',
  'ݢايوه',
  'سيمڤڠ',
  'تونتس',
  'ڤاڠور',
  'توڠاو',
  'همڤاڽ',
  'اتاسي',
  'مرڠکڠ',
  'ڤنروک',
  'برکدل',
  'کلنتڠ',
  'سارير',
  'مننتي',
  'برکچق',
  'برددق',
  'ڤيتيس',
  'سرتاي',
  'دڤڠݢڠ',
  'ݢاوتن',
  'کڤکتن',
  'سمريت',
  'بوڠݢو',
  'برکلر',
  'ڤمودل',
  'باريق',
  'ڤيڤيت',
  'بولور',
  'روچوه',
  'مڠادر',
  'ملالي',
  'مسياڠ',
  'ڤڠکين',
  'نويتو',
  'کنايه',
  'دريمن',
  'دببني',
  'بودق٢',
  'درهاک',
  'سوروه',
  'سنوڽا',
  'رڠسڠن',
  'تراول',
  'تاڠني',
  'مماچڠ',
  'اوبل٢',
  'ليهاي',
  'کولور',
  'کدابو',
  'لوليڠ',
  'لاهير',
  'ليڤتن',
  'جوريڠ',
  'جاديڽ',
  'بيليڠ',
  'کوشنڽ',
  'مڠرتس',
  'مرنچا',
  'برمݢه',
  'مڠتوڠ',
  'جرمبڠ',
  'بالق٢',
  'ڤڽابر',
  'مڠاجي',
  'تڠݢول',
  'ممڤرق',
  'مريوت',
  'هاتيف',
  'سکواس',
  'تلوکي',
  'ددالو',
  'شمسية',
  'کرينن',
  'تتابو',
  'برابي',
  'واݢون',
  'سلاڠت',
  'دڤڠݢل',
  'ڤورتو',
  'جيوا٢',
  'چوڤو٢',
  'ݢلوسڠ',
  'اجماع',
  'کاباو',
  'جاٴيل',
  'سوڠکه',
  'فيودل',
  'تولڠڽ',
  'مڠتيل',
  'بستير',
  'برچيݢ',
  'ريݢين',
  'ݢوندل',
  'سيليت',
  'ڤکرڠن',
  'ڽيڽيه',
  'سسيݢق',
  'راراي',
  'مينوس',
  'بکوان',
  'تصحيح',
  'ڤمتيق',
  'اکتور',
  'ڤياده',
  'داڤرس',
  'ارتري',
  'ممرڠي',
  'مصيبة',
  'ترواه',
  'ڤلواس',
  'ڤيراي',
  'تيبيا',
  'ڤاڤنڽ',
  'سميڽق',
  'ماليه',
  'ݢلاکق',
  'منوکق',
  'ميليق',
  'بنتاو',
  'مڠبور',
  'لوروڠ',
  'سسيرا',
  'ليڠسڠ',
  'مڽودو',
  'کلمڤي',
  'ممڤوس',
  'سياڠڽ',
  'ڤوروق',
  'هونجم',
  'دتيبر',
  'کرندا',
  'ڤنچير',
  'سبادن',
  'ملاين',
  'باڤيت',
  'ملوچو',
  'سڤارق',
  'بلڠکس',
  'کويوه',
  'لادين',
  'برسکي',
  'ملانڠ',
  'دهمڤس',
  'ڤينچڠ',
  'برقول',
  'اولا٢',
  'الدين',
  'ݢلادق',
  'کليبت',
  'عملن٢',
  'ڤرڠوس',
  'برچچه',
  'چراچو',
  'دأوبر',
  'برواڠ',
  'رندمن',
  'لوڠسي',
  'ݢومول',
  'تناهق',
  'ولايه',
  'ڤنتسن',
  'تيتين',
  'کرتوڠ',
  'تعمير',
  'کنيسه',
  'ديوال',
  'صيفتڽ',
  'سيلير',
  'ايونن',
  'بيتيڠ',
  'تاکوڤ',
  'اينيل',
  'مشائخ',
  'کنلکن',
  'توهوت',
  'کوبيس',
  'ماورا',
  'چڠکيه',
  'ݢونجق',
  'بکرجا',
  'کاسوت',
  'کندان',
  'لينتڤ',
  'درسيق',
  'کببڠن',
  'مڠالڠ',
  'دتيکم',
  'کوسا٢',
  'مڽڠکڤ',
  'سومير',
  'ترسکت',
  'ملولي',
  'دلاڤه',
  'چاڤاي',
  'کلڤوق',
  'ترڤکق',
  'سومبت',
  'برکلت',
  'تامبڠ',
  'ليچيق',
  'دأبور',
  'سرواس',
  'چاݢوق',
  'جانيق',
  'دايوق',
  'تاهوڽ',
  'هيروڤ',
  'برٴوڠ',
  'ڤکارڠ',
  'کوڤور',
  'ݢوني٢',
  'بونتڠ',
  'دومول',
  'ماٴون',
  'منمڤڠ',
  'ريمبت',
  'سومڤل',
  'تيتيڤ',
  'تکسيس',
  'ڤتاتر',
  'مالوت',
  'توکون',
  'مموکل',
  'ݢڤوه٢',
  'مرهلة',
  'چراچه',
  'تمتام',
  'سمڤار',
  'للوري',
  'کأنمڽ',
  'دسيرق',
  'مصيبة',
  'تيڠکو',
  'بواري',
  'ڤڠݢهي',
  'سبوتا',
  'فاونا',
  'چنتيل',
  'چوليق',
  'مضيغة',
  'مرمبق',
  'شيريک',
  'مکاچڠ',
  'اوتوس',
  'برڤرم',
  'براݢم',
  'ککورا',
  'مڠجمي',
  'سڤرݢي',
  'ݢنتوڠ',
  'دچڤوق',
  'سمڤوق',
  'تراقم',
  'دسيرا',
  'ڤاسڠن',
  'ابرار',
  'کاتود',
  'کويقڽ',
  'داوجي',
  'ڤرديه',
  'تمبله',
  'منچکو',
  'مياتو',
  'زاڤين',
  'منچيڠ',
  'دساکل',
  'دأمڤڤ',
  'اسفلت',
  'چراني',
  'مليسق',
  'مرچوڤ',
  'ڤولاي',
  'نونول',
  'کومڤا',
  'چرابه',
  'سولوت',
  'مرندڠ',
  'مندڤڠ',
  'منمڤڤ',
  'دأڠون',
  'ڤوراي',
  'باتاي',
  'ملاور',
  'ممبنم',
  'ستنجق',
  'چوليا',
  'تقدوم',
  'ملاتس',
  'رنچيس',
  'بوريه',
  'سيڠکق',
  'ترڠکن',
  'ݢلوڤس',
  'ڤايون',
  'چراکن',
  'اوويق',
  'چوکوق',
  'اوسر٢',
  'البوم',
  'کيروه',
  'ستيول',
  'سيکو٢',
  'برانم',
  'سکمبڠ',
  'بردتق',
  'ترببل',
  'سيکين',
  'ڤنجتن',
  'سنجات',
  'ڤيتوت',
  'سلوکڤ',
  'ݢدابه',
  'لايرڽ',
  'کتاب٢',
  'سماڠت',
  'دتالا',
  'ايچق٢',
  'سڤڠکت',
  'برسمي',
  'کوٴير',
  'مليبر',
  'کيسرن',
  'ماليم',
  'داݢوڽ',
  'ترسدر',
  'دلڤور',
  'بوتول',
  'اچارم',
  'سابوڠ',
  'داݢلن',
  'سڠݢتن',
  'منانه',
  'بويوڠ',
  'اندمن',
  'مڠوسل',
  'سأينق',
  'باٴوق',
  'ڤوٴين',
  'بريسن',
  'حقيقي',
  'دبدوڠ',
  'ڤرمبڠ',
  'رينجر',
  'چابڠڽ',
  'تاريس',
  'ڤوروڠ',
  'کستيڠ',
  'ڤيڤيه',
  'تاريق',
  'اوڠکه',
  'ڤماتڠ',
  'سراݢم',
  'منجلر',
  'دسيݢي',
  'ڤمرڤن',
  'تندڠن',
  'دوݢيل',
  'چنتوم',
  'اوجوڠ',
  'دنتور',
  'بوتقڽ',
  'ݢاٴوق',
  'دوندڠ',
  'تيڤوس',
  'سيکتا',
  'ايسين',
  'چولاڽ',
  'ديديس',
  'اوسوڠ',
  'ڤرڠݢڠ',
  'دجولڠ',
  'اداکه',
  'سينرڽ',
  'لاندڤ',
  'دڤاهت',
  'سوڽات',
  'ݢالڠ٢',
  'اوويس',
  'ݢراهق',
  'چيوت٢',
  'ڤرسيل',
  'بينچڠ',
  'مناݢق',
  'امڤام',
  'دجالا',
  'ݢليسه',
  'ڤڠيسي',
  'سيريڤ',
  'تولور',
  'برسنم',
  'هوتن٢',
  'ڤيلڠڽ',
  'مواتن',
  'سکوچي',
  'ممبسي',
  'ممبله',
  'لنچيڤ',
  'سڤيجق',
  'السنة',
  'ڤنتسڽ',
  'چهاري',
  'لوريڠ',
  'ترکنڠ',
  'برسدو',
  'ݢاياو',
  'موسوه',
  'اوريڤ',
  'سبراڤ',
  'رڠکوه',
  'بلارم',
  'شيريف',
  'الوکن',
  'کتابڽ',
  'تحيول',
  'براکو',
  'داسر٢',
  'ڤوتوڠ',
  'توڽوق',
  'سيمبه',
  'دويوڠ',
  'دسايڠ',
  'ستندڠ',
  'دچادڠ',
  'ڤرچيس',
  'بردوس',
  'سکتيک',
  'داهيڽ',
  'ڤسرتا',
  'لوچوت',
  'براصل',
  'براݢه',
  'امباو',
  'دجمڤي',
  'کڤتن٢',
  'بلاسن',
  'دسمبه',
  'ڤمبسر',
  'اوتق٢',
  'کارڠڽ',
  'ميره٢',
  'کندري',
  'اڤيون',
  'اولوڤ',
  'کوبو٢',
  'ڤڠليڠ',
  'براوق',
  'سوالا',
  'کوڤو٢',
  'مڠولق',
  'رادوڤ',
  'اولوڠ',
  'کرڤوه',
  'رياق٢',
  'باتيل',
  'ڤراڠک',
  'ڤنبوس',
  'بيلوق',
  'برسوت',
  'برسعي',
  'بيروق',
  'تفضيل',
  'کاڤن٢',
  'رڠݢول',
  'مڽمڤڠ',
  'تراتق',
  'حاضير',
  'باروا',
  'بوميڽ',
  'نيلاي',
  'لنتري',
  'ڤرڠسڠ',
  'سوکن٢',
  'تاروڠ',
  'اديݢن',
  'باسون',
  'ݢونده',
  'ايمڤر',
  'ݢينچو',
  'راڤتن',
  'دسولا',
  'ݢالڠن',
  'ڤنوڤڠ',
  'دݢسا٢',
  'مڽندت',
  'مڠتوس',
  'چربيس',
  'بردتڤ',
  'ڤاٴون',
  'ڤورين',
  'چليچڤ',
  'برزهد',
  'ڤڠمڤڠ',
  'سروڤا',
  'سيتکن',
  'ريݢيو',
  'سامرن',
  'باکري',
  'دبيدس',
  'دداڤت',
  'برياس',
  'کارڠݢ',
  'کاسوک',
  'فڠسوي',
  'سمينت',
  'دباکر',
  'انفاق',
  'حاشية',
  'برتکق',
  'سولوڤ',
  'دڤوڠه',
  'مڠاون',
  'کاجيڽ',
  'بيندل',
  'چلکوڠ',
  'بردوا',
  'ݢݢتون',
  'اوبيڠ',
  'کوبلت',
  'دکلير',
  'مڽاله',
  'فوتسل',
  'ميرهڽ',
  'بربسر',
  'جاݢوه',
  'ڤنڠکل',
  'توالا',
  'جاڠکڠ',
  'ليميق',
  'چلينڤ',
  'کابور',
  'ايتيق',
  'دچاتت',
  'بيندي',
  'بلاٴن',
  'بوڠاڽ',
  'بروتل',
  'ڤڤايڠ',
  'ڤڽبوت',
  'دسمير',
  'سناون',
  'بهاݢي',
  'دچوڠق',
  'دسيوق',
  'ليماو',
  'چڠکيڠ',
  'مڠنجل',
  'کمڤول',
  'سرڠن٢',
  'لاٴوت',
  'چڠݢوڠ',
  'ميتيو',
  'تڠکل٢',
  'سيوال',
  'کيريق',
  'مڠيڤس',
  'بوتور',
  'هجرية',
  'ملورو',
  'درويس',
  'ددندن',
  'ممڤتن',
  'باسلت',
  'لودوڠ',
  'چيبوڠ',
  'سيلوق',
  'تبروک',
  'سموت٢',
  'تعظيم',
  'واتق٢',
  'مڠاون',
  'زمرود',
  'جالنڽ',
  'سامڤڠ',
  'سمبوق',
  'سايڠڽ',
  'کويوڠ',
  'ڤاسرا',
  'کجورا',
  'ماڠاو',
  'ديدوڠ',
  'دايڠ٢',
  'تيديڠ',
  'رومبڠ',
  'دتمڤر',
  'ڤنچيت',
  'رمڤنڠ',
  'ڤلابي',
  'مڠڤوڠ',
  'ايمبل',
  'سلوره',
  'موتوڽ',
  'ستمڤت',
  'کوتيل',
  'ممڤرڤ',
  'چوچول',
  'توکرن',
  'توتوڤ',
  'کاسيڤ',
  'ترابر',
  'طاهير',
  'سوسوڤ',
  'تونسڽ',
  'ڤنونو',
  'منياݢ',
  'سرڠيت',
  'ارکان',
  'سباجو',
  'برادب',
  'جوليق',
  'ڤڠاچر',
  'شريعة',
  'چنداي',
  'لاريه',
  'کلياس',
  'ڤينڠن',
  'بڠکوق',
  'برتڠه',
  'چمڤرس',
  'ساديڠ',
  'ڤڤراي',
  'ڤمايڠ',
  'تيروق',
  'کينتل',
  'ريڤاو',
  'ڤڽولا',
  'سمارا',
  'نناسي',
  'لمبوق',
  'سودوت',
  'نانيڠ',
  'چاکيل',
  'ۏيروس',
  'بوڠرن',
  'سيروي',
  'دأدوق',
  'منچيݢ',
  'دتانم',
  'توهن٢',
  'ڤونتي',
  'ڤلاݢس',
  'دتاهن',
  'دنداڽ',
  'چنتون',
  'سامبه',
  'ڤاسق٢',
  'ڤوتيڠ',
  'داوبه',
  'برتمو',
  'کنڠن٢',
  'سڠکلڠ',
  'منندا',
  'رندوه',
  'چيچيت',
  'چومور',
  'ڤڠيرن',
  'تيمبل',
  'تأويل',
  'ݢراوت',
  'ڤمالم',
  'وطنيه',
  'ريݢول',
  'ملمبڠ',
  'بردهم',
  'چينچڠ',
  'دڤنجت',
  'کيتيل',
  'بنديڠ',
  'منبوس',
  'سڤيهق',
  'ليات٢',
  'درما٢',
  'دموات',
  'تانمن',
  'نينيق',
  'ترچکڤ',
  'رڠکوڤ',
  'باڤاي',
  'سوکوڠ',
  'سلودڠ',
  'بڠکڠن',
  'لنچوڠ',
  'کمنڠن',
  'رادوم',
  'اوفيس',
  'ڤوڤوڠ',
  'برڤري',
  'براصل',
  'ملابو',
  'بندوت',
  'برکاچ',
  'چونور',
  'اوموڠ',
  'ڤڽليا',
  'دۏيتو',
  'ڤسيار',
  'رويوڠ',
  'ڤنچاک',
  'مراݢس',
  'ماتمو',
  'ݢيديق',
  'تمبوس',
  'برݢاي',
  'لمڤيت',
  'مڠنچڠ',
  'بليدڠ',
  'تابوق',
  'ݢميلڠ',
  'چيتا٢',
  'چرامه',
  'چوميل',
  'سڠݢهن',
  'سمجان',
  'کوميس',
  'دکرسي',
  'چوندق',
  'ملوکو',
  'کومله',
  'مضرتڽ',
  'ملاڠق',
  'مليلا',
  'بلهنڽ',
  'سجاڠت',
  'مراوت',
  'کاياو',
  'سرامن',
  'لوچو٢',
  'کلودق',
  'ياقوت',
  'معمور',
  'ريچيس',
  'سوسوق',
  'برۏيت',
  'ڤمقسا',
  'ڤاسوق',
  'مڠمبڠ',
  'لوزيڠ',
  'هولو٢',
  'توکو٢',
  'مماڤر',
  'ماليڠ',
  'رومبق',
  'تاورن',
  'کاڤس٢',
  'دسواڤ',
  'بردتڤ',
  'ليمون',
  'لينڠن',
  'ممڠکل',
  'مڠياه',
  'تيڠاو',
  'چمبول',
  'سيݢما',
  'سمڤون',
  'سوروڠ',
  'ݢليات',
  'مردود',
  'سمڤاي',
  'چلوڤ٢',
  'مڽودي',
  'ليدور',
  'چالير',
  'مڠوجا',
  'مڠتيق',
  'مڠاݢس',
  'براني',
  'جوڠور',
  'ݢوستن',
  'تورڤن',
  'سممبو',
  'ݢيلاو',
  'لکتات',
  'دݢاڽه',
  'سلياڠ',
  'روموس',
  'دراسڽ',
  'اوڠکڠ',
  'منواي',
  'سکجمن',
  'سروان',
  'دهياس',
  'توڤيڠ',
  'تمڤتن',
  'تومبل',
  'برسڠݢ',
  'بوکاو',
  'براسن',
  'رداني',
  'دڤتري',
  'مڠالڠ',
  'ارکين',
  'بيقسا',
  'اچارا',
  'روتوق',
  'کنڠنڽ',
  'سببمو',
  'لاتين',
  'بوانم',
  'دچيلا',
  'کتݢرن',
  'ڤامور',
  'ساٴوق',
  'ليڠکر',
  'تياڤ٢',
  'ڤواسا',
  'سڤروت',
  'ساتوڽ',
  'کيبسن',
  'تليڠا',
  'زمان٢',
  'ڤڽݢرن',
  'دابيه',
  'ڤاري٢',
  'عقيدة',
  'رمڤه٢',
  'ستاڠن',
  'توقچن',
  'دڠرله',
  'مقبول',
  'بردمي',
  'دبرتي',
  'کرتس٢',
  'رڠکيت',
  'جنابة',
  'السن٢',
  'مرودق',
  'لالوي',
  'برواڤ',
  'کلݢڤن',
  'کنيون',
  'سرنتن',
  'دهنتم',
  'کريت٢',
  'تربيه',
  'دکيسر',
  'دتربڠ',
  'برهيض',
  'باݢوڠ',
  'ڤورا٢',
  'راڤتڽ',
  'دأوݢه',
  'خراجة',
  'ديسا٢',
  'ݢنجا٢',
  'سيريق',
  'بوکرم',
  'چڠکوت',
  'سوٴيق',
  'موکور',
  'سيينا',
  'بانيڠ',
  'مموسر',
  'سوروت',
  'مڠزيڤ',
  'فيدرل',
  'سوکتن',
  'لومڤڠ',
  'ڤسارا',
  'ڤڠيلڤ',
  'مرمبو',
  'راڠوڤ',
  'درمڤس',
  'رنتيق',
  'سيسيڽ',
  'مناجق',
  'اونتڠ',
  'اوڠڤ٢',
  'دکتکن',
  'تمڤهن',
  'دکوڤس',
  'ݢندين',
  'اندول',
  'مڠحقي',
  'ݢوچوه',
  'دريمن',
  'مڠندم',
  'تادوڠ',
  'دأوله',
  'بيڽوت',
  'سڤاته',
  'تردده',
  'سبوڽي',
  'ڤموڽڽ',
  'ڤاڠوس',
  'امالة',
  'کوڤوه',
  'مربوت',
  'مودهڽ',
  'جݢيلن',
  'توبول',
  'بيديت',
  'هالڠي',
  'تݢوه٢',
  'بونوه',
  'بتارا',
  'ڤلاڤڠ',
  'الرهن',
  'ناظير',
  'هاڠيت',
  'لنچان',
  'رماصن',
  'کوستي',
  'ليچوڠ',
  'ترسسق',
  'مندڤق',
  'مناتو',
  'ڤنکڤن',
  'لوقمن',
  'دتمبل',
  'کرتقن',
  'تومرڠ',
  'تومتم',
  'اڠکاس',
  'مڽاجي',
  'تومڤه',
  'سراسه',
  'جوکو٢',
  'اندوڠ',
  'امڤيق',
  'ݢيمبر',
  'ڤاکول',
  'کريمر',
  'سيڠکڤ',
  'عاشيق',
  'بيروت',
  'ڤانتس',
  'کيلاو',
  'بربلي',
  'ݢاداي',
  'سلتوس',
  'چيکيل',
  'سڤينا',
  'اوڤيه',
  'اوبل٢',
  'ݢاٴيڠ',
  'ݢلورت',
  'امريل',
  'کردوم',
  'لينسر',
  'دمڤيڠ',
  'دتڠݢت',
  'کاڤڠن',
  'هيروت',
  'مڠيلق',
  'ݢاديڠ',
  'سباهت',
  'سچبيس',
  'تمبوق',
  'تيڠݢي',
  'کاچاو',
  'دسوجي',
  'اݢيه٢',
  'لاراو',
  'تربڠڽ',
  'ݢورون',
  'مرلاي',
  'برکوق',
  'کتاڤق',
  'دليڤا',
  'داوسڤ',
  'کڤيرن',
  'تيتيه',
  'براقم',
  'لوکوت',
  'خزانة',
  'چنورم',
  'کتوق٢',
  'توڠکو',
  'کونيق',
  'باڽون',
  'فيتور',
  'ڤتيمن',
  'بارڠن',
  'تاجور',
  'نيهيل',
  'باڠاو',
  'کتوٴي',
  'ݢودوت',
  'امساک',
  'تݢاري',
  'نتيجه',
  'مڽهاج',
  'ميسوي',
  'بولاڽ',
  'ڤاليڠ',
  'خزانة',
  'ترجهن',
  'رمڤيڠ',
  'مڠمڤڠ',
  'چاسيس',
  'ݢولڠ٢',
  'جودول',
  'ڤيڠکر',
  'بالوڠ',
  'منجنڠ',
  'تاڤيه',
  'چيڠکت',
  'بواڠ٢',
  'وادون',
  'اڠݢر٢',
  'ممبکو',
  'ديري٢',
  'کيتوت',
  'دجاجه',
  'چاروي',
  'تاته٢',
  'چڤاتي',
  'دسيݢي',
  'دڤلوق',
  'ڽيڽين',
  'کنچيه',
  'منورس',
  'ڤاݢي٢',
  'درڠکه',
  'بلدان',
  'دسندق',
  'ڤيچيڠ',
  'اڠکيل',
  'کموجا',
  'برݢوق',
  'چڠکوڤ',
  'روندو',
  'کلاسق',
  'کريڠت',
  'انورک',
  'ايڠݢڠ',
  'کندوت',
  'کسوما',
  'ککندي',
  'رمندا',
  'بنواڽ',
  'ݢيسيق',
  'ماݢيس',
  'ڤناور',
  'شريفة',
  'لقسان',
  'دبابت',
  'کلوچق',
  'جلسکن',
  'تاجوڠ',
  'دݢالس',
  'خلاشي',
  'افطار',
  'مممڤت',
  'محامي',
  'ݢلاتق',
  'مڽنتڤ',
  'برڤنت',
  'دننتي',
  'ڤڠاڤق',
  'مڠربت',
  'چبيس٢',
  'کيڤوڠ',
  'فوستا',
  'تتارق',
  'سارون',
  'ڤوهون',
  'نوۏل٢',
  'برادق',
  'ݢيليق',
  'لاجور',
  'دفتنه',
  'ممڤير',
  'سلساي',
  'کوميت',
  'سناور',
  'ولارو',
  'مراين',
  'راڤيل',
  'سبوکو',
  'ڤوليو',
  'براسه',
  'کرڽهن',
  'ريکيت',
  'سيڠکي',
  'کيمري',
  'کدوت٢',
  'سليمڠ',
  'برليم',
  'لمڤور',
  'کارتن',
  'کلاڤن',
  'برلمق',
  'داٴوق',
  'بيديس',
  'مڠجرڽ',
  'سبيچا',
  'تأخير',
  'کورسج',
  'بڠݢيل',
  'کتيقن',
  'دتاده',
  'بردسه',
  'مرياس',
  'ڤنݢسن',
  'دتيوڤ',
  'اوليڠ',
  'ڤايوق',
  'هتريک',
  'ددالي',
  'سترڤن',
  'سيرنا',
  'چرونڽ',
  'ممبوک',
  'ملدوڠ',
  'ساروت',
  'سݢيلا',
  'ريزوم',
  'توکيق',
  'سڤتير',
  'ليبرڽ',
  'باڽاي',
  'برقوم',
  'تيمبق',
  'دکيڤس',
  'ݢاٴوت',
  'ريڽيق',
  'اورڠڽ',
  'توتوق',
  'ڤڠيکت',
  'براڠن',
  'دتڠکل',
  'کهندق',
  'سومڤه',
  'ڤلڽڤن',
  'تتݢوق',
  'تمڤرس',
  'ڤڠاسه',
  'بوتوت',
  'مملوق',
  'اووق٢',
  'لمبتڽ',
  'نونوق',
  'ترانس',
  'داݢڠن',
  'رانچه',
  'اوڤوس',
  'برڤتي',
  'ڤمرکه',
  'مڠووڤ',
  'دوسون',
  'چلوڤر',
  'دچاري',
  'ترڤڤق',
  'ريڠين',
  'سرڤاي',
  'ڤکوجن',
  'دچمري',
  'ملواڠ',
  'بربرت',
  'ستينو',
  'دوݢول',
  'کلڠڠن',
  'کبريه',
  'ڤڠنچم',
  'روايت',
  'ڤنجان',
  'سارڠڽ',
  'الورن',
  'ݢمرچق',
  'اييون',
  'جالن٢',
  'بنجور',
  'دهيسڤ',
  'دتاڠس',
  'ڤرسيه',
  'کديڤ٢',
  'رمباي',
  'کاڤيل',
  'دوري٢',
  'کباسي',
  'چارين',
  'مشهدة',
  'ديبوت',
  'للابو',
  'کيلاي',
  'ݢمڤول',
  'کليسه',
  'حوصلة',
  'هندوق',
  'لمڤري',
  'ݢرلوه',
  'ريمڤق',
  'بهلول',
  'ڤاريه',
  'برݢلڤ',
  'اوکوڤ',
  'برسلا',
  'اوجرن',
  'دݢونا',
  'ڤقيهي',
  'ملارم',
  'چوڤيڠ',
  'تعقول',
  'ڤاسوڠ',
  'سڠکلت',
  'راݢوق',
  'جوالا',
  'مڽندل',
  'ڤلتوڤ',
  'ليلين',
  'ملڠسي',
  'ملتيڠ',
  'ترݢݢر',
  'دراݢي',
  'ملابن',
  'سريوه',
  'ديکور',
  'بڠکيل',
  'ڤمولس',
  'ݢنتيڽ',
  'ککندا',
  'سنتري',
  'سحاره',
  'دمڤير',
  'ݢنديق',
  'دسايت',
  'کناهڠ',
  'ڤڠوچق',
  'دندنن',
  'ڤنچچق',
  'چلوتق',
  'سڤسما',
  'اڠکو٢',
  'مڠمڤو',
  'مڽولا',
  'برسلم',
  'دسرݢه',
  'بلابس',
  'سباوه',
  'ڠليور',
  'کوتيج',
  'بريڠ٢',
  'جمرتس',
  'ڤندبو',
  'درمڤا',
  'چقماو',
  'ملڠݢه',
  'کوڠکي',
  'اولين',
  'ڤدهال',
  'توڠکت',
  'مڠڤوه',
  'بالوس',
  'کوتيس',
  'ڤاتيڽ',
  'ڤيڠيت',
  'سچارا',
  'تراوڠ',
  'ڤوندر',
  'ڤورسي',
  'ترسڽڤ',
  'باٴوت',
  'ستوما',
  'براڤ٢',
  'مڽبرڠ',
  'ڤڠيري',
  'منولق',
  'سننتن',
  'مرموت',
  'ترادڠ',
  'دسمبت',
  'دسرݢڤ',
  'ساٴيد',
  'سريڠ٢',
  'اماره',
  'سرنتي',
  'نفقهڽ',
  'کݢمرن',
  'دستور',
  'مڽبوت',
  'چندان',
  'مينتق',
  'ڤنکيک',
  'دورسل',
  'عنصور',
  'روبا٢',
  'چمڤاݢ',
  'کأيهن',
  'ڤنجڠڽ',
  'کسسقن',
  'مايڠڽ',
  'امڤيس',
  'بديلڽ',
  'چاليڠ',
  'منليق',
  'قمرکو',
  'شاولم',
  'چنديق',
  'اومبق',
  'ملمڤم',
  'کنجور',
  'مڠيري',
  'دجامو',
  'جيڠکڠ',
  'رمبو٢',
  'بيوتا',
  'برباڤ',
  'ساونا',
  'ݢناهر',
  'کنچوڠ',
  'دسيکت',
  'ککاکي',
  'دوٴڠن',
  'باچين',
  'بومبو',
  'ستڠهڽ',
  'تاڤوه',
  'داݢيڠ',
  'مربوق',
  'برايه',
  'ݢوٴيت',
  'ايجيق',
  'منجڠک',
  'کرچيس',
  'کراڤق',
  'ريکيس',
  'چيليم',
  'ليسيت',
  'ݢيديڠ',
  'امبول',
  'دلڠکه',
  'لاريق',
  'مرديه',
  'لومير',
  'برڤڠڤ',
  'ݢݢريت',
  'کاتيل',
  'دسهاج',
  'دڽوتن',
  'بوکوڠ',
  'بلولڠ',
  'چبور٢',
  'بروبه',
  'زامين',
  'اينسڠ',
  'سݢڤوق',
  'بيتي٢',
  'کرجا٢',
  'سربيه',
  'لارسڽ',
  'ڤرقسي',
  'دتڠݢم',
  'سنتڤن',
  'سابوت',
  'ڤنبلن',
  'کابوي',
  'کاکوق',
  'کومرد',
  'ڤرکاس',
  'لوبير',
  'ڤنندق',
  'لالوڽ',
  'ڤلاوت',
  'ۏکبون',
  'تريچا',
  'ممڤره',
  'برتݢه',
  'توهنڽ',
  'کونڠ٢',
  'لبيه٢',
  'بردبو',
  'سجمبا',
  'ليسيق',
  'جرلوس',
  'ڤروسه',
  'دراجي',
  'تندنڽ',
  'مڠروت',
  'ديسيل',
  'سروݢي',
  'ريڠکس',
  'مڽيرڤ',
  'ݢيݢاو',
  'جالاڽ',
  'ݢويڠ٢',
  'برڠوت',
  'عناية',
  'ڤڤاته',
  'ڤرمات',
  'ڤستور',
  'بينتل',
  'ساکو٢',
  'مڠݢول',
  'برججل',
  'تولين',
  'ݢتيقن',
  'تاٴوڤ',
  'مڠݢما',
  'ميراي',
  'دألون',
  'وسيلة',
  'چلينڠ',
  'چراچت',
  'هيدڤن',
  'ڤيجت٢',
  'بوڠکڠ',
  'چڠراي',
  'املاء',
  'تاجمڽ',
  'تومبڠ',
  'سڤربڠ',
  'لمڤوم',
  'کوکول',
  'ڤنچوق',
  'ممنجن',
  'ممنچا',
  'ݢوديق',
  'تربيت',
  'کودوق',
  'مڠساڽ',
  'بومرڠ',
  'سيريه',
  'ڤوريڠ',
  'ڤاچيڠ',
  'ݢندوم',
  'سݢيرڠ',
  'قراءه',
  'ڤنتاي',
  'ايروب',
  'ڤلوقن',
  'دراجي',
  'ڤنيتي',
  'ڤنچره',
  'لوکوه',
  'کلولي',
  'کوموه',
  'داوبر',
  'جرݢون',
  'ترولي',
  'ممواي',
  'رامڤق',
  'لنچوت',
  'ݢونده',
  'مڠاکن',
  'چمبهن',
  'برݢݢس',
  'ڤنانق',
  'مڠوسڠ',
  'برذات',
  'سنتوق',
  'کميق٢',
  'ڤاچاي',
  'سونتق',
  'راڽاو',
  'برسيت',
  'ڤيڠکل',
  'کامڤس',
  'تفکرن',
  'ريبيه',
  'تيڤين',
  'دتکوڤ',
  'لمبو٢',
  'ݢلڤاي',
  'ڤلاݢق',
  'مڠاکق',
  'تاکور',
  'ڤڤارم',
  'چنتسن',
  'بنيه٢',
  'دراهن',
  'وکالة',
  'ڤڤينا',
  'ابدية',
  'بولوڽ',
  'مڠالڤ',
  'کسبقن',
  'تاموق',
  'دسويق',
  'ايڠݢو',
  'روبوه',
  'دليلڽ',
  'داتڠ٢',
  'تراکو',
  'ڤواڠي',
  'سوسوڠ',
  'ايبيڠ',
  'ڤاتيل',
  'مراڠق',
  'بنتڠن',
  'سمالو',
  'بلالڠ',
  'الوکن',
  'کريات',
  'ݢونچق',
  'ڤنالو',
  'بيڠکس',
  'ترنتڤ',
  'عقدکن',
  'اجرنڽ',
  'سجاهت',
  'کڤچهن',
  'ددعوا',
  'برکرس',
  'سلمبر',
  'ڤيچوڠ',
  'بهاس٢',
  'ڤرچيق',
  'دچراي',
  'ݢليڽا',
  'وليمة',
  'روبيڠ',
  'مڽلوق',
  'ڤماته',
  'ݢاميت',
  'دتکڤي',
  'دچلوڤ',
  'ترچيس',
  'برليق',
  'بالور',
  'بيراي',
  'سڠساي',
  'ڤماکن',
  'واسير',
  'ݢوݢوق',
  'مولوت',
  'مڽولم',
  'ماسق٢',
  'همباڽ',
  'تويول',
  'إسلام',
  'ليڠݢق',
  'سڠراي',
  'سکتيڤ',
  'مڠارم',
  'توليق',
  'راساو',
  'ڤدندا',
  'دمبرڠ',
  'دچوبا',
  'ڤچهکن',
  'منڠکڤ',
  'مروبو',
  'جرونڠ',
  'اناي٢',
  'تورکس',
  'کلتوڠ',
  'جولوڠ',
  'دسمبه',
  'بوهول',
  'لينجق',
  'منچڠم',
  'هوباي',
  'ڤيڠکل',
  'ڤتوݢس',
  'براڠک',
  'روسقڽ',
  'ممتيق',
  'کمبرن',
  'منوکم',
  'ݢوݢوق',
  'سوسوه',
  'ملاڽه',
  'بوليڠ',
  'ڤننون',
  'لوچوت',
  'ݢڠرين',
  'ڤبيلا',
  'دڠکلن',
  'مليوا',
  'سوڤيت',
  'سوڤاي',
  'مڠورس',
  'کوتق٢',
  'ريڠيق',
  'کچرهن',
  'برسسه',
  'سولوق',
  'ممبول',
  'لڠکوڤ',
  'دڤندڤ',
  'تميلر',
  'ڤموڽا',
  'شهدان',
  'کلمهن',
  'مڠکيس',
  'کتيلڠ',
  'هيتمڽ',
  'کمفور',
  'جامو٢',
  'ارتيک',
  'جريجي',
  'چيڤير',
  'تربنم',
  'بربرس',
  'ڤربݢو',
  'سنيمن',
  'سرڽوق',
  'ليهتن',
  'منروڠ',
  'تيقتي',
  'کميقڽ',
  'داڤت٢',
  'تکبور',
  'تيمڤي',
  'ݢميرڤ',
  'ارچيس',
  'روروه',
  'دڤاسڠ',
  'باراي',
  'کسيسي',
  'داولت',
  'تولي٢',
  'موٴمن',
  'ممݢڠڽ',
  'اليجا',
  'هومور',
  'سوروڤ',
  'چوڠݢڠ',
  'سليبر',
  'سيليا',
  'ڤڽقسي',
  'سوڠݢي',
  'سالون',
  'فريون',
  'کيبير',
  'چندرو',
  'اندوت',
  'ڤواتڠ',
  'کيکيق',
  'هيمتن',
  'چوڤول',
  'ڤنانم',
  'برکةڽ',
  'لينين',
  'ڤويسي',
  'دتمڤي',
  'بيري٢',
  'سمڤيل',
  'مرلين',
  'ڤلارس',
  'ݢايوق',
  'سوتور',
  'ڤساوت',
  'بردمه',
  'کايوه',
  'شهادة',
  'مڠاچن',
  'ديکيت',
  'ترکات',
  'ڤوستن',
  'منوکر',
  'ڤممڤت',
  'صبرله',
  'ڤدوتا',
  'دسيني',
  'تمڤيڠ',
  'ترسوا',
  'ناڠيس',
  'مرݢوق',
  'تکاوڠ',
  'تڠکوي',
  'چنتيت',
  'دأيسي',
  'مريلا',
  'بکاسم',
  'سيلاو',
  'کوليق',
  'چڤلين',
  'رندوڠ',
  'چنتيل',
  'دکرکه',
  'مندوه',
  'الت٢ڽ',
  'ڤالوه',
  'بلاهق',
  'ڤمنتڤ',
  'منواڠ',
  'همبور',
  'مولور',
  'مندتر',
  'دتاون',
  'مرڽاي',
  'بيچوق',
  'برکسم',
  'دجامه',
  'باليڠ',
  'تشديد',
  'داوڤم',
  'مرݢول',
  'کاون٢',
  'داسرڽ',
  'ڤنجاج',
  'کوچقن',
  'ڤوروت',
  'دتوال',
  'کلڤيت',
  'چولوت',
  'سلنچر',
  'ڤيليس',
  'دسيلق',
  'تراهڤ',
  'سبيچق',
  'دترکم',
  'ممبکم',
  'ممڤري',
  'موزيک',
  'کيڤسن',
  'ستمڤڠ',
  'هلامڠ',
  'سبوت٢',
  'مموݢر',
  'هاريڽ',
  'ڤينتڠ',
  'کوداي',
  'دتوتل',
  'چندرا',
  'ڠيڠيق',
  'برݢما',
  'ڽهکود',
  'تونڠڽ',
  'منطوق',
  'نايوب',
  'اليڠ٢',
  'جينق٢',
  'سيمهن',
  'ايديس',
  'ݢوسوڠ',
  'تراتس',
  'جوڠيق',
  'بندوت',
  'هيبوه',
  'دبکلي',
  'اودوه',
  'مروسق',
  'سدودو',
  'دتڠه٢',
  'ڤلفظن',
  'تڠکول',
  'ڤردان',
  'چچياه',
  'چلاڤه',
  'لينݢو',
  'تاڽله',
  'کوٴيق',
  'برکره',
  'لادا٢',
  'رندان',
  'بوردو',
  'سورتي',
  'اولين',
  'تراچق',
  'بلورب',
  'ڤلوهڽ',
  'ڤارو٢',
  'کوڽول',
  'کاڤاي',
  'بيبنڽ',
  'بوڽيڽ',
  'چرايڽ',
  'سڠݢوڤ',
  'ميهون',
  'تيليڠ',
  'اعلان',
  'رجعيي',
  'برترس',
  'اوتقڽ',
  'قومکو',
  'دلمڤڠ',
  'ليکيه',
  'کوبوي',
  'برݢرق',
  'ڤماهل',
  'چاݢوت',
  'اخيرة',
  'مليار',
  'دولوق',
  'ڤڠودڤ',
  'کلورق',
  'سليڤن',
  'ميسيل',
  'دتتقڽ',
  'رودين',
  'کورڠ٢',
  'ڤمݢڠن',
  'رنداي',
  'سلمبه',
  'مڠوبن',
  'کتاڤڠ',
  'کامڤا',
  'لمڤاي',
  'ڤريمت',
  'وودوق',
  'بايڠن',
  'کوبڠن',
  'تکليف',
  'ڽامور',
  'تڠکوڠ',
  'اوݢق٢',
  'کتوال',
  'لاڤيس',
  'لاڤور',
  'ڤيريت',
  'برتکڤ',
  'ݢمالا',
  'دمبون',
  'ڤڠواس',
  'ساسرن',
  'دماسق',
  'ليورڽ',
  'مڽواڤ',
  'باڽقن',
  'مميکت',
  'کوندي',
  'رانچڠ',
  'ڤمهمن',
  'تمبون',
  'ترلمق',
  'دڤاتي',
  'سلاجو',
  'بيلوت',
  'مرواڠ',
  'واجهڽ',
  'جابون',
  'کلينڠ',
  'بومبا',
  'برتݢق',
  'اوندق',
  'ڤمايڠ',
  'رايرت',
  'کلڤوڠ',
  'چامڤق',
  'مڠچوڠ',
  'رمبيݢ',
  'ݢوبيق',
  'برقوم',
  'منمبت',
  'ݢدابق',
  'کليسا',
  'سيکوي',
  'ستالي',
  'سلوݢن',
  'دأجري',
  'دکسني',
  'ڤيندڠ',
  'ݢاديس',
  'سماسق',
  'اوارد',
  'اوجور',
  'تکتيک',
  'ڤڠتتن',
  'مرنوڠ',
  'ݢيڽوت',
  'اوڤيق',
  'تلکوڠ',
  'سياسه',
  'ساسول',
  'بالڠ٢',
  'ڤاواک',
  'دجمور',
  'کچيل٢',
  'دباجق',
  'جولور',
  'مڠادڤ',
  'ستنتڠ',
  'دکافن',
  'هارڤ٢',
  'ڤروجق',
  'ملتيق',
  'موڠکر',
  'چندور',
  'مندرڠ',
  'اروان',
  'عمورڽ',
  'کارول',
  'اوڠکت',
  'کڤلوق',
  'روسوق',
  'ڤنمتن',
  'ڤمبکو',
  'کتول٢',
  'سوݢول',
  'دڤمڤس',
  'برجسد',
  'تيقڤي',
  'ݢهاري',
  'سرومن',
  'سرماي',
  'مڠمڤا',
  'هيريت',
  'ݢاموت',
  'کدڠکڠ',
  'ڤناتر',
  'رمبتن',
  'کڠرين',
  'صيفتڽ',
  'مرامو',
  'سينرن',
  'ڤنضرب',
  'مڠشکي',
  'اومڤق',
  'لولوس',
  'کرچيت',
  'ننريڠ',
  'منمڤر',
  'تاٴور',
  'چماهڠ',
  'برکيک',
  'راڠيت',
  'بادني',
  'اوواي',
  'لاتيه',
  'مبارک',
  'سأيڠت',
  'باݢور',
  'ݢودوق',
  'هنسوڤ',
  'مونچه',
  'ڤساوت',
  'ڤنواي',
  'روسوه',
  'کڤروق',
  'مموکت',
  'کونتا',
  'ککيسي',
  'لکوق٢',
  'ترلݢم',
  'بڠکاس',
  'ڤيلوت',
  'برکسن',
  'رونيو',
  'بلياو',
  'دحفظڽ',
  'اوڠجي',
  'ددولڠ',
  'وداني',
  'سݢندا',
  'الرهن',
  'سأرتي',
  'جوبين',
  'توکوڠ',
  'ڤمنجا',
  'ارهام',
  'ناٴوڠ',
  'ساويت',
  'برجين',
  'اولهڽ',
  'اورݢل',
  'ممبنر',
  'اينده',
  'سلمڤر',
  'انجڠ٢',
  'اخروي',
  'بيدڠ٢',
  'کرانڽ',
  'کڤوق٢',
  'منارا',
  'مرلوڠ',
  'دتنجق',
  'کرتوق',
  'بدايا',
  'دساڤو',
  'نيبوڠ',
  'ڤرڠاو',
  'باتون',
  'فرعون',
  'ڤانهن',
  'بيجقڽ',
  'برکول',
  'ترککڠ',
  'چوکيت',
  'مويوق',
  'تيݢ٢ڽ',
  'خطبهڽ',
  'ارکوس',
  'ستودن',
  'باکت٢',
  'سوسهڽ',
  'دسڠݢم',
  'راسمي',
  'منوڠڽ',
  'کداري',
  'دسمڤل',
  'امڤڠن',
  'سݢڠݢم',
  'لاوتن',
  'ديديه',
  'لنتيق',
  'ليليت',
  'ترنية',
  'ملوسو',
  'محکمه',
  'لمبڠن',
  'ݢيلير',
  'کسيور',
  'بيروڽ',
  'روڠکس',
  'تريلر',
  'ڤڠولڠ',
  'برونا',
  'ساوير',
  'بندوس',
  'منيلا',
  'مهاجر',
  'تنونن',
  'بايوه',
  'موتيا',
  'ملايق',
  'دمڤوق',
  'جارهن',
  'سڤارا',
  'بينچا',
  'راٴيت',
  'سيجيل',
  'سوکيت',
  'ڤڠيلت',
  'مليکس',
  'دندرم',
  'ڤناکي',
  'ترومل',
  'اچوکن',
  'ڤيجر٢',
  'سوتيل',
  'اوليق',
  'ساداو',
  'ڤيوتر',
  'چولوق',
  'فکتور',
  'کرڤيل',
  'ڤوندن',
  'کلايو',
  'مدرسة',
  'ݢيݢير',
  'دسنتل',
  'تندوق',
  'برامه',
  'بربنڠ',
  'ڤنيات',
  'بدوکڠ',
  'چابيق',
  'ميترو',
  'ممانس',
  'طاعون',
  'دصليب',
  'دکچڤي',
  'کاڤوق',
  'ابنوس',
  'مرايڤ',
  'باڤوق',
  'مرچيق',
  'اموڠ٢',
  'مڠمبق',
  'رينتى',
  'چالڠ٢',
  'اوليه',
  'لوريق',
  'بتاري',
  'بيروڠ',
  'چبلوس',
  'کچيبق',
  'دتمڤس',
  'کفاية',
  'سلالة',
  'دسيڠن',
  'مرنتي',
  'مالم٢',
  'کنجوس',
  'کسمبي',
  'دسونو',
  'ملاڽق',
  'سمنتڠ',
  'ڤڽيار',
  'درامل',
  'ݢيليڠ',
  'ݢارڤن',
  'بنجيڠ',
  'سيرسي',
  'ميسوا',
  'ڤيسيس',
  'رابوت',
  'دݢيلڽ',
  'داڠݢڤ',
  'کيچوت',
  'اوڠکه',
  'کرتوس',
  'تنوين',
  'ڤڠريه',
  'کرسڤن',
  'ملاڠڤ',
  'کراڽه',
  'مڠيلڠ',
  'سمبول',
  'ڤوڤيا',
  'لندوق',
  'ارکيد',
  'توڤيس',
  'کچکڤن',
  'منڠݢق',
  'براڠݢ',
  'ڤليهت',
  'کونير',
  'وادوڠ',
  'دالي٢',
  'مڠارم',
  'بربله',
  'بنتوق',
  'بردوا',
  'موݢوق',
  'مسيحي',
  'براسو',
  'ممڤلم',
  'کلتيڠ',
  'مجليس',
  'بوبوڠ',
  'مموجي',
  'برسست',
  'ملوݢس',
  'سحاره',
  'کجامس',
  'ايلاي',
  'برکنا',
  'بلوري',
  'ڤڠحفظ',
  'بڠسا٢',
  'چلارو',
  'ݢرقنڽ',
  'سروني',
  'ڤتيسي',
  'باداي',
  'برسسه',
  'ايڠت٢',
  'برسره',
  'مڽکتي',
  'انجاي',
  'دجݢيل',
  'متراي',
  'درومن',
  'ڤڠيڤس',
  'دوهوق',
  'اباين',
  'تمثيل',
  'برالس',
  'منمبق',
  'کهانة',
  'ڤسارا',
  'بردسق',
  'کيجيڤ',
  'ڤتايا',
  'کسترد',
  'بردڤڠ',
  'مموجا',
  'ترکوڤ',
  'فرمان',
  'چمڤاي',
  'چروتڽ',
  'ڤدندڠ',
  'کروبق',
  'ݢرواق',
  'چابول',
  'برکود',
  'سياڤا',
  'سچڤت٢',
  'سکيم٢',
  'باتير',
  'نينيس',
  'جاوي٢',
  'دروان',
  'منججق',
  'کچڤتن',
  'کلوات',
  'راݢوڠ',
  'مڽاڤو',
  'کاليه',
  'کواچي',
  'مندوݢ',
  'برتاڽ',
  'هابور',
  'ممنجت',
  'للاوي',
  'برسݢي',
  'لمواس',
  'ݢونچي',
  'دڠنکو',
  'بهاٴي',
  'ميڽقڽ',
  'مڠوسا',
  'کسبلس',
  'سأڠين',
  'رودوس',
  'سياکڤ',
  'لنتوڠ',
  'لسوڠ٢',
  'مراوي',
  'ملوکن',
  'کرباو',
  'ڤڽستن',
  'توجوه',
  'چکالڠ',
  'برساڤ',
  'بڠيسڽ',
  'انومي',
  'ݢنجور',
  'ليناڽ',
  'ڤرسلي',
  'چلنتڠ',
  'خنثىڽ',
  'مکيدي',
  'دتبوس',
  'عاقبة',
  'چماني',
  'ݢسکيت',
  'ڤنلنن',
  'روکيت',
  'چروچه',
  'کلابڠ',
  'بسليت',
  'ڤليتا',
  'لوڠکه',
  'بولا٢',
  'ڤولمک',
  'ماجون',
  'جلادن',
  'کججلن',
  'چوکير',
  'ملارا',
  'مڠليت',
  'رينچڠ',
  'ليڠݢم',
  'مينتڽ',
  'ڠاٴوڠ',
  'اوباي',
  'ايميڠ',
  'کالور',
  'منجڠه',
  'سچکوت',
  'جينتن',
  'سکوتق',
  'ڤرايو',
  'ڤلاسه',
  'اوڤتي',
  'برکوت',
  'دريتا',
  'بيلڠن',
  'قياسن',
  'تونکو',
  'چوليم',
  'کمڤيت',
  'ڤرکير',
  'دچليق',
  'ديڠکڠ',
  'کلوان',
  'ڤياتو',
  'عزوجل',
  'سکوڤڠ',
  'داسين',
  'بوهجن',
  'اساسي',
  'ممڤرس',
  'اسهنڽ',
  'منومڠ',
  'ايديا',
  'ݢلدور',
  'رينيه',
  'فيتيش',
  'چڤلوق',
  'دڤڠکو',
  'لمباݢ',
  'ستياڤ',
  'کياق٢',
  'دتوکس',
  'ربوسن',
  'بباري',
  'لاٴيڤ',
  'سلامڽ',
  'بيجيه',
  'برکتق',
  'ترموس',
  'دأجوق',
  'دتکوق',
  'ڤتاٴن',
  'رمڤوڠ',
  'القمر',
  'جوڠول',
  'تراڤڠ',
  'مالهن',
  'برالت',
  'ساندر',
  'راکيت',
  'جوڠکر',
  'ݢڤوقن',
  'سابوق',
  'اصول٢',
  'کاچڠڽ',
  'مڠاڤي',
  'کڤيڠن',
  'بوجڠن',
  'درڤيه',
  'باچوق',
  'بوهوڠ',
  'ستنزا',
  'چابڠ٢',
  'تمبور',
  'منبوق',
  'منچق٢',
  'برتݢر',
  'کويلو',
  'لوديڠ',
  'ڤڠولس',
  'جوبوڠ',
  'سيݢاي',
  'ڤوروه',
  'ݢوليݢ',
  'چيري٢',
  'کرنجي',
  'مڠعقد',
  'ڤڤاسڠ',
  'مروتن',
  'مراݢو',
  'کيسيت',
  'کاکوس',
  'منيجق',
  'مليبس',
  'تاتڤڽ',
  'ݢݢالا',
  'ڤوجوت',
  'بليبن',
  'ڤکاتو',
  'امبيه',
  'ستريک',
  'سينود',
  'لوڤوس',
  'ݢملتق',
  'ڤمادن',
  'ترحفظ',
  'تختا٢',
  'ڤمندي',
  'ڤڤرڠن',
  'دتمبل',
  'بربڠݢ',
  'ليبوت',
  'برڤسن',
  'چيڤيڠ',
  'کانون',
  'ڤيجين',
  'تيمڤو',
  'للالت',
  'ڤڠݢلي',
  'ريميس',
  'مڽاڽو',
  'داساو',
  'امبوق',
  'لمڤوڠ',
  'مڠيلت',
  'سلمبا',
  'لونتر',
  'جومله',
  'جومڤق',
  'بردتر',
  'ڤموتر',
  'ڤلڠݢن',
  'موريڠ',
  'دبريک',
  'توکقن',
  'دسيمق',
  'دأمڤو',
  'ميزان',
  'منابق',
  'مماکل',
  'باکاي',
  'منوݢن',
  'اينچي',
  'داڤتي',
  'ککاڤس',
  'فوروم',
  'اوتيق',
  'کاوا٢',
  'ڤنوهڽ',
  'بردرق',
  'جاٴوه',
  'چوکول',
  'درمبس',
  'توجوق',
  'کرجاڽ',
  'مرمڤس',
  'ريبوت',
  'ۏيولا',
  'بيقسن',
  'کليق٢',
  'دتورڤ',
  'کوتور',
  'دڠنمو',
  'ڤمالي',
  'چوموت',
  'کوليڠ',
  'سکوبڠ',
  'لاݢاو',
  'ݢيترڽ',
  'دباير',
  'منتڠ٢',
  'دتلنڽ',
  'ممبسر',
  'معقول',
  'بريحت',
  'اجريه',
  'ايڤوڠ',
  'ڤلارم',
  'ݢلنتڠ',
  'کاٴوس',
  'کيسيڤ',
  'اوڠکق',
  'سوهون',
  'دأجون',
  'توري٢',
  'مڠاسق',
  'چميمر',
  'ليݢوم',
  'ڤيالڠ',
  'ميڠوت',
  'کوموي',
  'تندن٢',
  'ککيلي',
  'مناکه',
  'ڤڠوبه',
  'اويوي',
  'مڠولس',
  'جورڠڽ',
  'ڤروسا',
  'ڤنبتن',
  'هيلوم',
  'ڤنالي',
  'منچکم',
  'ميلير',
  'تڠݢوڠ',
  'مرسيق',
  'سيلتڽ',
  'منولڠ',
  'ملياڠ',
  'کرابو',
  'سيلڤڽ',
  'دتاتڤ',
  'سڠکلا',
  'بوبوه',
  'دبليڽ',
  'کريکل',
  'لمبڤن',
  'لارڠڽ',
  'سرڠکق',
  'بوݢي٢',
  'مڠوڠم',
  'دأندم',
  'کيناڽ',
  'بردکت',
  'سسوݢي',
  'لنچوڠ',
  'سيݢوڤ',
  'برطبل',
  'برسيک',
  'فوسفت',
  'کيڤيت',
  'تڠݢڤن',
  'ݢوردن',
  'ملوات',
  'سڠݢره',
  'سلفية',
  'کرهله',
  'ترلتق',
  'ريمه٢',
  'حسرتڽ',
  'مڽيلڤ',
  'دتݢور',
  'ڤوان٢',
  'کخاصن',
  'بطلکن',
  'رابيق',
  'ݢرامن',
  'چيترا',
  'اوبرق',
  'مڠردم',
  'ترنچه',
  'دڤاݢر',
  'کاٴيس',
  'لمبير',
  'ريتاو',
  'سکوته',
  'ديريڽ',
  'سريبو',
  'کوديس',
  'سياق٢',
  'ساڤير',
  'بردسس',
  'موتيل',
  'ستريک',
  'کراهي',
  'تڠکڤن',
  'کمبوس',
  'روداڽ',
  'مروبه',
  'ممرلي',
  'منينڠ',
  'چورقڽ',
  'ݢرتقن',
  'هوستي',
  'ݢمرتڤ',
  'سرمبا',
  'صحراء',
  'سانتڤ',
  'جابيڠ',
  'سمستا',
  'مالاي',
  'کيسيق',
  'لوڤوه',
  'تمڤور',
  'بردرم',
  'ݢيندق',
  'الوڠن',
  'کينچڠ',
  'هروبي',
  'مڠنجي',
  'اداله',
  'بيتيت',
  'مشرفة',
  'کالوي',
  'ظاهير',
  'تقليد',
  'نيترت',
  'دلارڠ',
  'دريڠن',
  'زرافه',
  'سمنجق',
  'ايتنو',
  'ترتݢق',
  'بناره',
  'چکواق',
  'دکتوق',
  'سسياق',
  'دترڤا',
  'ڤوکول',
  'دوبرق',
  'برکيق',
  'ڤروت٢',
  'ڤڠيال',
  'مڠاکر',
  'ايديک',
  'تجويد',
  'ڤيلوڠ',
  'منيوڤ',
  'المعز',
  'فيتوس',
  'ڤمالي',
  'سيمه٢',
  'ددفتر',
  'تيلدى',
  'بيلهڽ',
  'جليرا',
  'جالير',
  'ڤيلبک',
  'چيڤوا',
  'کاتوڤ',
  'مڠنلڽ',
  'ناسوت',
  'سيانڠ',
  'عاقبة',
  'مداره',
  'ليريڠ',
  'فاٴيل',
  'ليتقڽ',
  'کلاڤڽ',
  'دساجي',
  'سکينة',
  'بکوکو',
  'ݢلندڠ',
  'اڤيلن',
  'تيوبن',
  'سوڠسڠ',
  'روڠوق',
  'سمندل',
  'تيکيت',
  'سوام٢',
  'برداي',
  'چاچتڽ',
  'ڤاٴوت',
  'ڤڽرهن',
  'ڤاهيت',
  'ڤيانو',
  'ضرورة',
  'ڤولور',
  'اندوس',
  'اوڤور',
  'براڠک',
  'بڠݢلي',
  'بردبق',
  'سيۏيل',
  'شفاعة',
  'دودوت',
  'ممامه',
  'ڤڤواه',
  'قيامة',
  'ڤننتي',
  'مڠادڠ',
  'ديديق',
  'کساسر',
  'ݢورتن',
  'مرمبه',
  'مڠيسي',
  'مورهق',
  'ڤيڤيت',
  'کلوبق',
  'دأنچم',
  'کرتيڤ',
  'ليچين',
  'اوڤاو',
  'مرواه',
  'سأيڤن',
  'دتواڠ',
  'سنتري',
  'برالڠ',
  'جومڤا',
  'منجوڠ',
  'روڠوه',
  'بادوت',
  'جمو٢ڽ',
  'دکجرڽ',
  'ماليق',
  'اوتام',
  'لنچرڽ',
  'تمبوڠ',
  'تاڠنڽ',
  'سکرجا',
  'تڠکيڠ',
  'ڤلونس',
  'دليڤت',
  'ݢاٴيل',
  'مڠولي',
  'بڠکوڠ',
  'کادڠ٢',
  'سلڠکڠ',
  'کوتي٢',
  'ملوکت',
  'ڤماکل',
  'مفهوم',
  'تمڤين',
  'تمورس',
  'دسيڤق',
  'محسنة',
  'لوڽاي',
  'جمورڽ',
  'ڤلاسي',
  'ريفري',
  'انيون',
  'جاوين',
  'بنتوت',
  'سوڠکڤ',
  'برحدث',
  'کوتيق',
  'تامڤه',
  'چرليس',
  'بينجت',
  'دتورس',
  'ايهکو',
  'کنديس',
  'برتکڠ',
  'باٴيت',
  'برزقي',
  'سوکرڽ',
  'مڠيچي',
  'کمڤيل',
  'لنتوه',
  'ڤنججس',
  'مندمه',
  'برصبر',
  'راجوق',
  'ݢوردي',
  'سهنيڠ',
  'راٴيه',
  'مندرا',
  'کودڤن',
  'داهنڽ',
  'دکڤيت',
  'ترجون',
  'سڤادو',
  'مڠيکل',
  'دتوکر',
  'لومڤت',
  'چاويس',
  'بابور',
  'تلاوة',
  'کريقن',
  'سوروت',
  'برکجڤ',
  'سبب٢ڽ',
  'دچيتا',
  'تريند',
  'سونتن',
  'بهاوا',
  'ݢاماي',
  'جومبق',
  'هلوبا',
  'تسلسل',
  'ڤنچڠن',
  'تتامو',
  'رڠسوم',
  'ڤينتو',
  'هوتيل',
  'ککاچڠ',
  'ساتير',
  'ݢلدوه',
  'استاݢ',
  'چله٢ڽ',
  'ڤلاݢو',
  'ايريق',
  'بوتوه',
  'ميويق',
  'درادق',
  'چمڤان',
  'ڤماچق',
  'کديڤن',
  'رنچيڠ',
  'ملاجڠ',
  'مبذير',
  'اڠکاو',
  'کواق٢',
  'کامبن',
  'ميميس',
  'اللهم',
  'ݢلوتن',
  'تلابڠ',
  'ݢندير',
  'چکياق',
  'انجور',
  'کيلوق',
  'بوڠکل',
  'کڤتڠن',
  'مليڽڠ',
  'وقتوڽ',
  'اجيدن',
  'انقکو',
  'ترڽات',
  'بوسوت',
  'راݢوس',
  'ݢاسير',
  'ڤنمڤل',
  'درمبس',
  'مڠوڤس',
  'تيبرن',
  'سمراي',
  'دڤنچڠ',
  'تاڤين',
  'کوليو',
  'سبله٢',
  'اڠݢور',
  'تمبرا',
  'لومي٢',
  'اخبية',
  'مولوڠ',
  'ببارن',
  'مڠيلن',
  'رنجان',
  'سرواق',
  'ڤجواڠ',
  'مڠيسا',
  'موتيف',
  'دسرکڤ',
  'دڤاچق',
  'چامين',
  'سيڤسن',
  'اوسيا',
  'ڤکيقڽ',
  'مڽيوت',
  'دعذاب',
  'دڤالم',
  'باليت',
  'منانق',
  'تݢوهڽ',
  'رواه٢',
  'ڤيکيت',
  'واجبڽ',
  'چنتير',
  'چمبور',
  'دڤالق',
  'درڽوق',
  'ڤسنن٢',
  'مڽݢني',
  'کنچڠ٢',
  'رونتا',
  'اولون',
  'سرمبب',
  'يقينڽ',
  'راج٢ڽ',
  'جوبلي',
  'ڤڠليم',
  'مرنجس',
  'ڤڠمبر',
  'کيڤقڽ',
  'حافلن',
  'سيدنڽ',
  'ملواق',
  'دڤمکن',
  'سڠيهن',
  'دجمڤي',
  'ايچيڠ',
  'ڤڠاسر',
  'کڤوڠن',
  'کلڽوق',
  'ناظيم',
  'الولى',
  'دݢڠݢو',
  'کوجاي',
  'تاريڠ',
  'برکرت',
  'برموک',
  'مرواس',
  'ميسيج',
  'ترڤرم',
  'سراهي',
  'درمڤس',
  'اسبوت',
  'فرائض',
  'ديديل',
  'مومبڠ',
  'ترلوه',
  'بوڠا٢',
  'باروه',
  'سيلاڽ',
  'چاݢاو',
  'چڤلوس',
  'ڤرنچت',
  'قارئه',
  'بستيل',
  'دڤريس',
  'چتراي',
  'ارادة',
  'دݢويڠ',
  'کتياڤ',
  'ساريڠ',
  'ݢوديق',
  'مأموم',
  'توهيد',
  'مواصل',
  'ککارس',
  'کڤويو',
  'کونچو',
  'بنداي',
  'ليتنن',
  'دليبس',
  'ڤلاکر',
  'لاتوه',
  'کمودي',
  'ريجا٢',
  'عاليم',
  'بوڠوق',
  'دبدقي',
  'لاونڽ',
  'رابوڠ',
  'بردڽر',
  'موروس',
  'سڠݢام',
  'ݢوناڽ',
  'بوبوت',
  'کاجين',
  'اوبيت',
  'دسيڤه',
  'رواتب',
  'چونيق',
  'بلاو٢',
  'چکراو',
  'چيڠکي',
  'ڤرسله',
  'مڽاݢر',
  'فوتون',
  'دهاتي',
  'سياکن',
  'بلاجر',
  'تيلور',
  'هيمت٢',
  'تونجل',
  'ملاره',
  'برلڠن',
  'تمڤقڽ',
  'ڤمبکم',
  'هابوق',
  'تيڠݢڠ',
  'کالوت',
  'سيڠکڠ',
  'چيڤيه',
  'لولوس',
  'دݢريد',
  'الکان',
  'اومبر',
  'سيما٢',
  'نڽوار',
  'بيرڠڽ',
  'چيقݢو',
  'ڤادت٢',
  'لونتڠ',
  'مڽمير',
  'ناٴيب',
  'کاليڠ',
  'توهمه',
  'سکمبڠ',
  'نعمت٢',
  'ڤڽيمه',
  'متاري',
  'ارکيب',
  'دکيلس',
  'دسڤوق',
  'سليسا',
  'تمڤن٢',
  'تانين',
  'ڤرڽوڤ',
  'سوبيق',
  'حلالڽ',
  'ڤوڤور',
  'بربرت',
  'ککمسن',
  'ککنين',
  'سودوق',
  'امڤين',
  'مڽيرق',
  'دتبوق',
  'بلديڽ',
  'بولو٢',
  'دکنجه',
  'اڠڤاو',
  'برشرط',
  'تمڤوق',
  'دکڤوڠ',
  'دروجق',
  'منراو',
  'تورني',
  'هاراڽ',
  'توبن٢',
  'سيکيت',
  'انديک',
  'چاکوڠ',
  'ساراي',
  'دݢودا',
  'عملکو',
  'کوٴيڠ',
  'سڤانس',
  'اولمن',
  'رومي٢',
  'ترالا',
  'دسورق',
  'ڤموجي',
  'ݢوماي',
  'لبيهڽ',
  'چراڤو',
  'جزيرة',
  'بلوکڠ',
  'ممهمي',
  'اميبا',
  'ڤلويا',
  'کارتڽ',
  'چوڤيت',
  'ڤمچهن',
  'ݢرليس',
  'دصدقه',
  'باڠوس',
  'تابوه',
  'تصوير',
  'اڠݢوق',
  'مڠتوس',
  'نينجا',
  'برتبت',
  'تويوه',
  'ݢارمڽ',
  'جمڤوڠ',
  'کميلڤ',
  'برانت',
  'دتقما',
  'مڽڠيت',
  'کوچوڠ',
  'چڠکور',
  'دبيلس',
  'مڠسيڤ',
  'اولم٢',
  'دتندڠ',
  'ريݢيس',
  'بنواڠ',
  'تعبير',
  'بيرق٢',
  'لڠݢنن',
  'سڠݢيڠ',
  'ݢرموت',
  'باجيڠ',
  'بنديڠ',
  'ڤاسير',
  'کماڠي',
  'ملڠݢر',
  'چيميه',
  'ۏولتن',
  'کبرتن',
  'افعال',
  'باکرن',
  'بوبوس',
  'جڠݢوق',
  'دبوتڠ',
  'کاتيق',
  'ݢݢلوڠ',
  'تمبوه',
  'شخصية',
  'اسلوب',
  'ممکاو',
  'مڽورم',
  'مليهت',
  'منرڠي',
  'اصولڽ',
  'ݢلڤوق',
  'راميس',
  'دهيلا',
  'ڤناهن',
  'ديواس',
  'باݢيس',
  'کاڤرن',
  'ترڤݢه',
  'رابسڽ',
  'اوبرل',
  'اوتر٢',
  'دکندر',
  'چڤلوق',
  'مڽڠݢه',
  'دچوچي',
  'چراچڠ',
  'تيڤاي',
  'چوچوه',
  'مڠلقڽ',
  'برکرس',
  'ورناڽ',
  'رمياق',
  'دسيمق',
  'سمبير',
  'ڤادم٢',
  'انتري',
  'همڤرن',
  'ڤتولا',
  'رسواه',
  'کداين',
  'دکوکڠ',
  'بابيڽ',
  'مرياڠ',
  'برشرط',
  'ڤيران',
  'ڠوڠوي',
  'انوتي',
  'چيبول',
  'تلبية',
  'الهية',
  'ڤالوت',
  'ڤوڤوق',
  'تيڤيس',
  'چيومن',
  'ڤرسڤن',
  'ڤوݢوت',
  'سجزوء',
  'سکايو',
  'ملڤوق',
  'چورين',
  'عفريت',
  'قصيده',
  'برکمم',
  'سينتق',
  'تبليغ',
  'برشره',
  'ماميق',
  'لوموت',
  'ايڤرڽ',
  'موراي',
  'مراکن',
  'دابوڠ',
  'کلونا',
  'جنتوڠ',
  'تنتوڽ',
  'ڤتاني',
  'جاݢوڠ',
  'چوکوه',
  'لبيهن',
  'ڤدشاه',
  'ستينا',
  'موچوق',
  'منکنڽ',
  'مڠمبي',
  'کورير',
  'ساݢيڠ',
  'تاکاي',
  'اوبور',
  'بربنر',
  'ابوق٢',
  'ݢنتين',
  'برݢݢر',
  'برماي',
  'توبيڠ',
  'ترچݢه',
  'منيبر',
  'اولمن',
  'ڤسيلن',
  'سسواڤ',
  'بنديا',
  'سڤوڤو',
  'برمات',
  'مننجق',
  'دديمن',
  'سکڤوق',
  'ايموڠ',
  'دترجه',
  'اوڠتي',
  'اندڤن',
  'بيردي',
  'اڠکول',
  'بلدوه',
  'خزانه',
  'ڤرمڤه',
  'بوکيق',
  'جنايه',
  'دتايڠ',
  'چلاݢي',
  'چوکين',
  'تمڤون',
  'بيريه',
  'ايندڠ',
  'مڠاچق',
  'سمبير',
  'ݢومبڠ',
  'دکنلي',
  'ݢاروڠ',
  'دݢوکن',
  'سونجم',
  'برکجر',
  'بودوه',
  'اهلي٢',
  'ڤمرين',
  'برتاڤ',
  'ممالق',
  'تفسير',
  'سايور',
  'کوسيق',
  'مرادف',
  'ملواط',
  'لوٴيه',
  'سمڤور',
  'لاٴين',
  'مليور',
  'تاساي',
  'سجاوت',
  'مڠاڤق',
  'امڤڠ٢',
  'ديݢيت',
  'ڤنچبق',
  'کلاسي',
  'مروڽس',
  'دچورق',
  'سأولڠ',
  'سرورو',
  'رڠکڤن',
  'سيڠيت',
  'اوسيل',
  'ڤڠرجا',
  'همڤدل',
  'ڤلسيت',
  'لانچڠ',
  'محراب',
  'مونته',
  'راڤت٢',
  'کنچيل',
  'سخيمه',
  'سسوچا',
  'مرابن',
  'مينوم',
  'مندڠو',
  'سالير',
  'العلى',
  'ڤنمڤر',
  'مروات',
  'رڤوه٢',
  'کلتوق',
  'مکارا',
  'لندور',
  'مݢلوب',
  'کتابي',
  'ڤنڠݢڤ',
  'ملواه',
  'تشريق',
  'ايکن٢',
  'سرۏيس',
  'ريتول',
  'لومبن',
  'سلايه',
  'ݢريڤ٢',
  'برلفظ',
  'مالوڠ',
  'کندرڤ',
  'دوݢما',
  'ديلݢت',
  'تاناو',
  'امڤدا',
  'مارهڽ',
  'حاريس',
  'برونڠ',
  'ستروڤ',
  'سلسلة',
  'هانتر',
  'اخفاء',
  'سيمين',
  'راموس',
  'رامبه',
  'باکوڤ',
  'بلادو',
  'ساوهڽ',
  'لاماي',
  'تايچي',
  'دچنتس',
  'مڠاڠه',
  'بيسير',
  'ڤمبيو',
  'مڠيمق',
  'ترونا',
  'کيتڠ٢',
  'دتواي',
  'مولور',
  'کڤنتن',
  'کڤولن',
  'کاڤوڠ',
  'بلودق',
  'ترجلق',
  'عسکر٢',
  'چاريڤ',
  'ساد٢ڽ',
  'لونتي',
  'برزنا',
  'ديريق',
  'ڤرنيس',
  'دڤيال',
  'ليريه',
  'دلاتم',
  'رابيت',
  'کوڤيل',
  'برتين',
  'مفارق',
  'حياية',
  'تاڠيه',
  'ڤمامه',
  'بلازر',
  'ڤلستر',
  'سارڠ٢',
  'ناديڽ',
  'ڤلڤهڽ',
  'مرنيڠ',
  'ملڠسر',
  'موريس',
  'دجلوم',
  'ترچچه',
  'ڤڠݢرا',
  'اهرام',
  'ناولي',
  'کڤستن',
  'براڠک',
  'دتمڤر',
  'ممبوڠ',
  'سلا٢ڽ',
  'دجاڠت',
  'هيروق',
  'دأولي',
  'مڠرمي',
  'مامون',
  'ݢريلا',
  'بربکم',
  'برجاج',
  'چنراي',
  'موٴيه',
  'سبلوم',
  'شمسية',
  'چاميڠ',
  'ݢرودق',
  'سأݢوڠ',
  'ۏولکن',
  'تنجول',
  'دسيرن',
  'هوتڠڽ',
  'مڽرنق',
  'جاهيل',
  'العين',
  'ترامل',
  'نرستو',
  'اظهار',
  'چيويق',
  'برتڤي',
  'منورا',
  'وبعده',
  'مڠولق',
  'منکڤي',
  'لݢوجو',
  'حقيقت',
  'بوسور',
  'بروکر',
  'دوݢيڠ',
  'ڤانين',
  'رهيمڽ',
  'دچاکر',
  'کمݢهن',
  'ڽوڽوت',
  'کچمرن',
  'مڠاجق',
  'ليتير',
  'سروسه',
  'ݢينڠ٢',
  'بانوق',
  'ماسوق',
  'امبوي',
  'جاڠنن',
  'بالون',
  'رڠکوم',
  'مومبو',
  'مڠبور',
  'بيسيق',
  'سلابر',
  'ڤمبلي',
  'ترسره',
  'ݢيݢيه',
  'جيريم',
  'ماناو',
  'ممکيس',
  'منزيڤ',
  'ڤلينو',
  'ڤڠوبت',
  'ايچيڤ',
  'تريقڽ',
  'مستحق',
  'کراسن',
  'ملولس',
  'کنچان',
  'تربکم',
  'ملاهڤ',
  'رودوڠ',
  'ڤموده',
  'برببر',
  'ملاسي',
  'کاڤوق',
  'باٴوڠ',
  'انچق٢',
  'ڤلاچق',
  'تلکوڤ',
  'باتيس',
  'کومبو',
  'سجڤوت',
  'مرنچه',
  'رافضي',
  'جوارا',
  'ويريت',
  'بيريت',
  'کچاين',
  'کرتسڽ',
  'ڤڠادو',
  'برطبل',
  'دجڠوق',
  'سوليق',
  'جيرنڽ',
  'ݢهارا',
  'رولوس',
  'لاير٢',
  'تمڤوي',
  'چنچير',
  'ڤوندي',
  'چڠکوم',
  'ستوکو',
  'دتانڠ',
  'ڤاروت',
  'سالوق',
  'ڤلمڤن',
  'ݢمبوج',
  'ستيکر',
  'ۏوٴيل',
  'ݢنتيس',
  'ڤڠبور',
  'ڤچهنڽ',
  'سانوق',
  'دحساب',
  'تياتر',
  'ݢوندق',
  'ککارس',
  'ترساڤ',
  'ملويا',
  'بوندل',
  'تلاݢه',
  'برباج',
  'دباچڽ',
  'سوٴير',
  'برقطب',
  'هندوه',
  'دداکي',
  'موکير',
  'دريسق',
  'درݢام',
  'ݢينوس',
  'سقسام',
  'تاجون',
  'اقوال',
  'سنتان',
  'سأݢام',
  'جلاتڠ',
  'لنتون',
  'امبلن',
  'برتڠݢ',
  'ڤڠاکڤ',
  'بوروه',
  'کاتوت',
  'ايريڠ',
  'دڤوجا',
  'ڤرادا',
  'سودور',
  'ککسنن',
  'ݢريسي',
  'سروسق',
  'بيبيق',
  'ݢراتق',
  'بوديڽ',
  'دسولا',
  'همبتن',
  'کومول',
  'بانيت',
  'واليڽ',
  'همڤاي',
  'سکوفو',
  'چوڠيه',
  'کلڽڤن',
  'مڽيڤق',
  'بونتر',
  'کناري',
  'چيبير',
  'بردڤن',
  'دالوڠ',
  'کراوق',
  'اتومڽ',
  'بسوتا',
  'لوبوس',
  'دسندل',
  'ڤرلوڤ',
  'چراچق',
  'چالون',
  'تولور',
  'اݢوݢو',
  'چاڤيل',
  'امبيڠ',
  'حاميق',
  'چنديت',
  'لالوت',
  'تيڤوس',
  'دانور',
  'جاٴيڠ',
  'ايستر',
  'ڤڠوڤس',
  'واهون',
  'موروڤ',
  'ايرم٢',
  'سوککن',
  'ڤڠيبر',
  'برڠسڠ',
  'ڤڠݢݢر',
  'لواسا',
  'سيڤيل',
  'تابيا',
  'زينون',
  'درامس',
  'سمڤيت',
  'سمارق',
  'دريک٢',
  'ککالو',
  'بيلاي',
  'ݢينجل',
  'دبديل',
  'کوکوس',
  'منالي',
  'جرجقڽ',
  'نريان',
  'راس٢ڽ',
  'براهي',
  'بندا٢',
  'جندول',
  'ڤرميل',
  'دماکن',
  'منتهى',
  'ڤمبري',
  'کوکوت',
  'بولڠ٢',
  'دلفين',
  'ڤوتيه',
  'ڤرڠکت',
  'کيوق٢',
  'چمڤدق',
  'ڤکوکو',
  'کلنوت',
  'کولاي',
  'کاتوق',
  'دسيسي',
  'کودوڤ',
  'برکجڠ',
  'ڤوراو',
  'دسڠکر',
  'ڤڠنتل',
  'سليسا',
  'کوبيڠ',
  'سوليه',
  'لبورن',
  'دکيرا',
  'دبدلڽ',
  'العدل',
  'ڤيارا',
  'بوکن٢',
  'انچر٢',
  'لڠکوڠ',
  'جرامق',
  'کتوتو',
  'لابي٢',
  'دتبسي',
  'ڤتارم',
  'سݢاتل',
  'منچيت',
  'ايفيک',
  'بلڠݢو',
  'کڤروق',
  'کتايا',
  'ڤاچوق',
  'ساروڠ',
  'جلاوت',
  'ججاري',
  'سيرتن',
  'دکتيڤ',
  'ڤيتق٢',
  'لمڤو٢',
  'ببولي',
  'مريتم',
  'ساريق',
  'ايسيي',
  'کمبيه',
  'مڠورت',
  'ججمبڠ',
  'برلله',
  'ستمڤڤ',
  'دڤڠکه',
  'تلوکي',
  'راتيب',
  'چوروڠ',
  'مرايو',
  'سوالق',
  'کدبود',
  'ڤڠالي',
  'ݢوامن',
  'اولوه',
  'بقتاو',
  'سيلۏا',
  'ڤمبوک',
  'ݢالير',
  'سولوي',
  'ممادو',
  'مڠيات',
  'تمباݢ',
  'دلکتي',
  'ناڤلم',
  'مڽاسر',
  'چچياق',
  'ايڠاو',
  'هوروڠ',
  'ڤڠوات',
  'ستينڠ',
  'بينيت',
  'منمين',
  'ݢدوڠ٢',
  'جمڤوت',
  'کوکوڤ',
  'دديبت',
  'مناده',
  'خنزير',
  'مممڤس',
  'کرليڠ',
  'دهوني',
  'تيباي',
  'کريان',
  'ڤمنڠڽ',
  'دتاتڠ',
  'ملالا',
  'هلايڽ',
  'رمبون',
  'مڽياه',
  'ۏرنيس',
  'کودين',
  'مناتر',
  'فراسا',
  'ݢليبت',
  'ڤڠلسن',
  'بندول',
  'مڠرڤي',
  'مجاهد',
  'دولڠ٢',
  'دعقلي',
  'ڤنداڤ',
  'تيندق',
  'جامعة',
  'لماري',
  'ڤڠارت',
  'کايو٢',
  'چاڤيڠ',
  'کتڤيڠ',
  'ڤيڤيس',
  'کوريق',
  'دݢالڠ',
  'ناٴيق',
  'چکيق٢',
  'براڤي',
  'لينته',
  'دچچڤڽ',
  'مڽايو',
  'کورڠي',
  'سوݢي٢',
  'چوکوڤ',
  'مڽڠکق',
  'ڤرنيق',
  'سدياڽ',
  'سفهمن',
  'ستولڠ',
  'سکالڠ',
  'ڤلاڤه',
  'توموي',
  'شرعية',
  'موديق',
  'چوڠکر',
  'سالاي',
  'اونجم',
  'رساله',
  'دتڤيس',
  'رياڠ٢',
  'سوويق',
  'سرميت',
  'ݢارڠڽ',
  'فلوئم',
  'دکوڤق',
  'ڤلاکو',
  'چوريق',
  'زاوية',
  'اوکتف',
  'سبوکو',
  'کيبرن',
  'جواڤن',
  'بربرس',
  'بيجيڽ',
  'چهاي٢',
  'ڤيلور',
  'ڤچاچل',
  'ڤتلسن',
  'تندوڤ',
  'سونتڠ',
  'ڤوڠݢه',
  'ايسوق',
  'عقيقه',
  'مڽبوت',
  'مليڤت',
  'مردوڤ',
  'وادوق',
  'سامبت',
  'ممبتس',
  'ڤوچيق',
  'ڤنجرت',
  'ڤاداو',
  'مماسق',
  'سݢلوق',
  'دتکنڽ',
  'چوڤوڠ',
  'دوروڠ',
  'چقديق',
  'چاريڠ',
  'کسدرن',
  'روماڽ',
  'کافير',
  'براکن',
  'سلينت',
  'لوتري',
  'سدرله',
  'ڤلاوس',
  'برنية',
  'هنتقن',
  'برنصڽ',
  'لڠکوا',
  'سرهيم',
  'ۏاريا',
  'سهندل',
  'ميمڤي',
  'ليدهڽ',
  'بوريق',
  'اوجوق',
  'کڤايڠ',
  'لرسني',
  'هيندو',
  'جودوڠ',
  'مڠنجل',
  'ديريق',
  'رومنڽ',
  'چلمڤق',
  'اصلکن',
  'مخلوق',
  'اوتوت',
  'سکتن٢',
  'ايڠين',
  'اڤيتن',
  'باوچر',
  'دسدوت',
  'تمڤرن',
  'رمڤلس',
  'کوميڠ',
  'ڤوݢاو',
  'سوسيه',
  'کووتا',
  'برکرق',
  'چنتاک',
  'دڤندو',
  'دتمبه',
  'چبادق',
  'لينسو',
  'سجالن',
  'کوراي',
  'کابوي',
  'چڠݢيق',
  'بليکت',
  'دداکڤ',
  'تڠکوق',
  'ميکرو',
  'اتفاق',
  'اخوان',
  'شهادة',
  'سلويق',
  'ݢلڤوڠ',
  'بوروڠ',
  'مينتل',
  'ستورن',
  'لڠکاو',
  'ڤونچا',
  'تلاکن',
  'داݢق٢',
  'ريچاو',
  'عقيقه',
  'سݢالا',
  'ايبر٢',
  'سلياڤ',
  'اندوق',
  'ڤاسيک',
  'مڽابق',
  'طاعةي',
  'برمڤق',
  'بردسس',
  'داسوه',
  'راليڤ',
  'کاساي',
  'بلوام',
  'کيمخا',
  'بستان',
  'مماسم',
  'مرچوڤ',
  'رانيه',
  'چيچير',
  'برلاب',
  'مڠيرس',
  'ݢيليق',
  'کاليو',
  'اهرام',
  'ڤڽسرن',
  'سݢيات',
  'سروتو',
  'سيدوݢ',
  'سرمڤڠ',
  'مناره',
  'ڤلچوت',
  'مڠاوت',
  'تموان',
  'ملتور',
  'ڤويو٢',
  'ݢلڤيق',
  'چڠکيل',
  'کراڤن',
  'ملونق',
  'راتهن',
  'هومبن',
  'تاڠکو',
  'راڤيق',
  'سيمڤق',
  'کوکوس',
  'اوݢوق',
  'مليوق',
  'کونتک',
  'دسيرم',
  'نيستا',
  'رونچي',
  'دݢاݢه',
  'ملچيت',
  'دوکوڠ',
  'سيارة',
  'مڠرکه',
  'ميچيس',
  'لومبا',
  'ڤدارت',
  'ليده٢',
  'ڤوقتا',
  'موڤيت',
  'لايقڽ',
  'ڤمبحث',
  'سڤکوق',
  'دکجري',
  'دليهت',
  'جرياو',
  'کلڤور',
  'دودور',
  'بروقف',
  'برجوس',
  'دتاتر',
  'ݢروڠ٢',
  'جيڠکي',
  'کمڤوس',
  'تيتهڽ',
  'ڤڠوجي',
  'لوهوڠ',
  'داناڽ',
  'ڤيکتن',
  'رنداو',
  'زيڠکڽ',
  'منچلا',
  'ڤڠلوڠ',
  'سمليت',
  'سياڠي',
  'چتريا',
  'دکلين',
  'داڤور',
  'باچول',
  'سکونر',
  'کاننڽ',
  'جڠݢاي',
  'راکنن',
  'سوکر٢',
  'مڠکرت',
  'کيملو',
  'اڠکيل',
  'تيمبڠ',
  'ڤڠکوڠ',
  'ڤرڠوت',
  'سرميت',
  'برڤرڠ',
  'لامبو',
  'دچکيق',
  'کاڤوه',
  'چانچڠ',
  'دتومڠ',
  'دڠاڠا',
  'ديام٢',
  'ناسي٢',
  'مريڽو',
  'ڤميسه',
  'کلنيڠ',
  'جنتور',
  'ڤماسر',
  'سسوهو',
  'مڽمڤل',
  'سيندا',
  'دلعنة',
  'چابوت',
  'ڤونيس',
  'ساليڤ',
  'مرڠوه',
  'ڤيراو',
  'ڤردمن',
  'کراني',
  'ڤينڠ٢',
  'انانس',
  'ملکوق',
  'تربيس',
  'سڠساي',
  'مونچي',
  'دويون',
  'جوهري',
  'دألين',
  'مجدوب',
  'کليچڤ',
  'تمڤنڽ',
  'بابي٢',
  'برڤتا',
  'ݢلوکق',
  'ڤيريه',
  'سلڠکن',
  'ليسيس',
  'هوتڠن',
  'تلقين',
  'سکداي',
  'دڤرڠي',
  'کديتي',
  'ڤڠمسن',
  'ݢلڠݢڠ',
  'بيبيت',
  'سساجق',
  'بولنن',
  'تتمڤه',
  'بنجوت',
  'داݢيه',
  'جينجڠ',
  'ليبرو',
  'ليهتي',
  'ترالق',
  'برݢتر',
  'اڤورن',
  'علميه',
  'موڠوت',
  'رنتاک',
  'چوديت',
  'ڤاسر٢',
  'ممبنڠ',
  'ککڤوق',
  'ڠومڤت',
  'کبايق',
  'سوڤير',
  'ماوين',
  'دصليب',
  'ݢادو٢',
  'دسيات',
  'مڽارڤ',
  'قارون',
  'کلوکق',
  'تاڠيس',
  'تيتيل',
  'مراڠو',
  'ڤاکوق',
  'ليلير',
  'دموسڠ',
  'توامن',
  'کيريت',
  'ددکتي',
  'دکيبر',
  'ديکري',
  'دچيتق',
  'بدوان',
  'نمبيت',
  'منجاݢ',
  'ڤرسڠک',
  'سوترا',
  'برنام',
  'چيويه',
  'چندوق',
  'ݢوݢتن',
  'امبيت',
  'معروف',
  'ممينڠ',
  'کواسي',
  'ڤڠنڤن',
  'ڤرچيق',
  'برتبس',
  'مڽموت',
  'مڽايت',
  'دريکو',
  'کرونس',
  'همڤسن',
  'رمڤوه',
  'خنجرڽ',
  'دتواڠ',
  'تندون',
  'توبير',
  'مريدڤ',
  'باسير',
  'کروتو',
  'ملايم',
  'بورݢر',
  'ݢلوچق',
  'ملادا',
  'کتمڤي',
  'کبولي',
  'برتمو',
  'مرچون',
  'چڠکرا',
  'منفعت',
  'ريماو',
  'تبيڠڽ',
  'چونيت',
  'برلکڠ',
  'سلادا',
  'افاضة',
  'تماهي',
  'اومون',
  'تاروه',
  'تربلي',
  'اڠݢول',
  'بيارا',
  'راسوق',
  'ڤرنتي',
  'ليمڤه',
  'لينتڠ',
  'ڤراݢت',
  'ڤڠاسق',
  'ساممو',
  'ساندا',
  'برجاج',
  'لوووڠ',
  'ڤولوس',
  'مڠاسي',
  'بڠکاو',
  'برحدث',
  'ڤاٴوس',
  'اودلي',
  'لاݢوه',
  'تاناي',
  'برتبڠ',
  'اينڤن',
  'الياس',
  'سڤادا',
  'سلارو',
  'واچان',
  'فورمل',
  'ݢديق٢',
  'دݢوم٢',
  'ترکرت',
  'ݢولما',
  'سکيکي',
  'چنتيس',
  'بينيڽ',
  'سرياو',
  'چونيا',
  'لڠکوق',
  'سلارق',
  'دوٴيت',
  'دراته',
  'کروتق',
  'ڤکاسي',
  'چندوق',
  'ݢابير',
  'ڤالس٢',
  'اوبيس',
  'دتانم',
  'بنتيل',
  'ڤڽاڤو',
  'لايوت',
  'ميجو٢',
  'کاسيم',
  'چاکوڤ',
  'ابيله',
  'دبنتڠ',
  'باهوڽ',
  'ݢينجق',
  'سمڤله',
  'ترتوا',
  'انقمو',
  'تشريح',
  'مڠيوڠ',
  'باکوڠ',
  'ايکوت',
  'منونو',
  'شمسير',
  'منڠسل',
  'تواڠن',
  'ڤيالو',
  'تيواس',
  'ملڠوه',
  'منوبت',
  'چبيسن',
  'دسماي',
  'روڽوت',
  'سيلوم',
  'کلالڠ',
  'کريکم',
  'امامڽ',
  'مڽارت',
  'فاسيس',
  'چلاري',
  'رادوڠ',
  'بيسون',
  'سجاري',
  'لوبي٢',
  'زکاةڽ',
  'اومڤه',
  'برتکت',
  'برکوڤ',
  'چاريق',
  'ڤڽڤيت',
  'رومبو',
  'سامر٢',
  'جولير',
  'سويوق',
  'برساڤ',
  'چوݢوق',
  'بوڠکم',
  'جنجي٢',
  'رواق٢',
  'ڤڠمبڠ',
  'ڤرومن',
  'سداوا',
  'ببواس',
  'تيکوڠ',
  'دهاݢي',
  'ايبيد',
  'جوزاء',
  'ادۏيس',
  'ڤنچمر',
  'لاݢو٢',
  'دهالڠ',
  'دورمن',
  'دݢوار',
  'تروڠ٢',
  'کنجيڠ',
  'ڤچينن',
  'سمينر',
  'تڠاري',
  'بربنت',
  'نعمتڽ',
  'ݢاٴوڠ',
  'سباتي',
  'اٴوڠن',
  'رنجوق',
  'دبلوک',
  'براول',
  'ملوڤس',
  'سادين',
  'سيله٢',
  'هامود',
  'منازل',
  'تيدقڽ',
  'فيتور',
  'چچادو',
  'چوچوڤ',
  'درنتس',
  'سسواي',
  'چمڤوا',
  'جنازه',
  'دوساڽ',
  'سامون',
  'کرواي',
  'چنيچه',
  'ڤوربا',
  'توڤيڽ',
  'ڤوروس',
  'برڤنت',
  'هندلن',
  'ترازو',
  'ڤتندا',
  'ڤمسنن',
  'بلاڠه',
  'برمڠݢ',
  'ݢاترا',
  'مشغول',
  'جوجول',
  'ڤاچول',
  'دتولق',
  'بودور',
  'ترججل',
  'مولاڽ',
  'بيڠݢو',
  'کوروڠ',
  'اݢام٢',
  'افيون',
  'ڤڽمتن',
  'سيدن٢',
  'سفرون',
  'مڽربق',
  'دڤقسا',
  'تيستا',
  'انجير',
  'چلاݢه',
  'ݢليڤر',
  'ݢاٴون',
  'ڤڽدرن',
  'سرجان',
  'بتينا',
  'مولوس',
  'مڽلمي',
  'اوسيس',
  'سکوڽر',
  'تمياڠ',
  'اتليت',
  'ڤوکاو',
  'سلاکو',
  'سعمور',
  'بوجڠݢ',
  'سينتر',
  'تيکيق',
  'برچڠڠ',
  'انتوس',
  'فبريک',
  'ڤڤايا',
  'روڠکڤ',
  'مليݢت',
  'اڠونن',
  'دسيتا',
  'ڤلالي',
  'چچيوي',
  'تاڠن٢',
  'منداو',
  'سڽاوا',
  'ڤرليڠ',
  'بيدڠڽ',
  'ڤينتر',
  'بوروه',
  'دأوسڤ',
  'ݢلاتڠ',
  'برݢمر',
  'ݢاليي',
  'ڤڽايڠ',
  'ڤڠعجم',
  'کوبوڽ',
  'راڤيه',
  'دݢوبل',
  'زهراء',
  'کرڤيق',
  'ڤڠورڠ',
  'سسارڠ',
  'ݢينيڠ',
  'ليڤيق',
  'همڤير',
  'ݢوݢوڤ',
  'سلڠوت',
  'مدالي',
  'دسقن٢',
  'ݢوڽوه',
  'اورڤن',
  'مماݢر',
  'دأمبق',
  'ڤمنچڠ',
  'برساي',
  'سيسا٢',
  'جورنل',
  'ݢرادي',
  'ملمڤس',
  'کاواي',
  'کوجاو',
  'ڤوڤوه',
  'مودول',
  'ڤڠچمن',
  'ترجاݢ',
  'براݢي',
  'راديس',
  'سوناي',
  'براجر',
  'کرومو',
  'کتولن',
  'کدڤوڠ',
  'کلمبو',
  'بڠکو٢',
  'سڠݢول',
  'لميدي',
  'بلالق',
  'مڠوره',
  'دلڠݢر',
  'دأمتي',
  'راوون',
  'دالو٢',
  'تجارة',
  'بلياق',
  'ديوا٢',
  'سجينق',
  'سکوله',
  'دبرکس',
  'کڤدکو',
  'بتولڽ',
  'تامن٢',
  'خيرات',
  'مڠوجا',
  'ڤڽجوق',
  'سينتر',
  'باريت',
  'سيڤوت',
  'دڤندو',
  'برختن',
  'کيدوڠ',
  'جيجيق',
  'برجين',
  'سليتن',
  'کداده',
  'تحفيظ',
  'ݢوروت',
  'بلکون',
  'ترنتڠ',
  'امڤيت',
  'هضاره',
  'لايون',
  'ايتوس',
  'واليت',
  'ريݢي٢',
  'چبياق',
  'رڠکوڠ',
  'انتون',
  'ݢولاڽ',
  'ايبرو',
  'کابير',
  'مريبا',
  'سلاري',
  'اوروڤ',
  'سلامت',
  'بربکل',
  'سرمڤو',
  'ڤارا٢',
  'ماڠير',
  'سرتا٢',
  'راڤوت',
  'مڠکوس',
  'اݢين٢',
  'تمالڠ',
  'لاماڽ',
  'دتڠکڤ',
  'کونتڠ',
  'اوداي',
  'چمبوت',
  'مسببڽ',
  'ڤوليڠ',
  'دچچهي',
  'باڤکو',
  'کلواک',
  'ترکتم',
  'راٴوت',
  'وايشا',
  'اورتڽ',
  'ڤڠروق',
  'باݢو٢',
  'دتمڤا',
  'سکڠکڠ',
  'کولرڽ',
  'دبالڠ',
  'اياتڽ',
  'اڠکتڽ',
  'دصحکن',
  'ڤنچمس',
  'جومبو',
  'مولوق',
  'دابيق',
  'تريڠن',
  'اوچيه',
  'ليچوق',
  'ريڠسا',
  'تيکوه',
  'کلکور',
  'بخشيش',
  'رباعي',
  'منالا',
  'مڠاهق',
  'ڤليڤت',
  'ݢمبوت',
  'اومڠ٢',
  'ماکين',
  'ڤاسو٢',
  'سسوکت',
  'مواڠي',
  'ݢريجا',
  'نوۏلڽ',
  'مڠوري',
  'ڤسيلت',
  'سڤولڠ',
  'جروجو',
  'امبوق',
  'لومبر',
  'برنتڠ',
  'تومڤس',
  'سمتيق',
  'ڤوڠسو',
  'ستارا',
  'ملاڤه',
  'دبروس',
  'سڠکيت',
  'مودين',
  'تمبقن',
  'سونيت',
  'سوندق',
  'ݢيلسن',
  'اومڤڠ',
  'دتونو',
  'واندو',
  'سبيله',
  'سماوا',
  'ڤاکوڽ',
  'اوڠکو',
  'برججس',
  'ريبين',
  'ڤنداݢ',
  'اڠݢون',
  'لانوس',
  'جواده',
  'رمبوت',
  'ڽوڽوڠ',
  'دمبون',
  'چمڤيڠ',
  'مروبن',
  'ميدلي',
  'دتڤوق',
  'چلاتو',
  'ترليک',
  'کلوڤق',
  'عقيده',
]

if (CONFIG.normalization) {
  WORDS.forEach((val, i) => (WORDS[i] = val.normalize(CONFIG.normalization)))
}

function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

if (CONFIG.shuffle) {
  shuffle(WORDS)
}
